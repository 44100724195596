import ListType from '../enums/ListTypes';
import { performPost } from './apiUtils';

export interface GetClientListExcelDTO {
  projectId: string;
  listType: ListType;
}

export function getClientListExcel(body: GetClientListExcelDTO) {
  return performPost('/getClientListExcel', body, {responseType: 'blob'});
}
