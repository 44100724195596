import React, { useCallback, useMemo, useState } from 'react';

import { Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Close, CloudDownload } from '@mui/icons-material';

import DocumentViewer from '../DocumentViewer';

interface UseDocumentDialogReturn {
  closeDialog: () => void;
  openDialog: (fileToOpen: string | undefined, appBarTitle?: string, showDownloadButton?: boolean) => void;
  open: boolean;
  DocumentDialog: JSX.Element;
}

const useStyles = makeStyles({
  documentViewer: {
    alignSelf: 'center',
    marginTop: '5vh',
  },
  toolbar: {
    backgroundColor: '#000', 
    color: '#fff',
    position: 'sticky',
    top:0,
    zIndex:100,
    width:'100%',
    
  },
  icons:{
    justifySelf:"end",
    marginLeft:"auto"
  }
});

const useDocumentDialog = (): UseDocumentDialogReturn => {
  const [open, setOpen] = useState(false);
  const [downloadButtonVisible, setDownloadButtonVisible] = useState(false);
  const [file, setFile] = useState<undefined | string>(undefined);
  const [title, setTitle] = useState('');

  const handleClose = () => {
    setOpen(false);
    setFile(undefined);
  };

  const handleOpen = (fileToOpen: string | undefined, appBarTitle?: string, showDownloadButton = false) => {
    if (fileToOpen) {
      setDownloadButtonVisible(showDownloadButton);
      setFile(fileToOpen);
      setTitle(appBarTitle || '');
      setOpen(true);
    }
  };

  const handleDownload = useCallback(
    () => {
      if (file) {
        const downloadElement = document.createElement('a');
        downloadElement.href = file;
        downloadElement.download = title;
        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      }
      
      setOpen(false);
    },[file, title]
  );

  const classes = useStyles();
  return {
    closeDialog: handleClose,
    openDialog: handleOpen,
    open,
    DocumentDialog: useMemo(
      () => (
        <Dialog fullScreen onClose={() => setOpen(false)} open={open} PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" >
              {title}
            </Typography>
           <div  className={classes.icons}>
             {downloadButtonVisible && ( <IconButton  color="inherit" onClick={handleDownload} aria-label="download">
              <CloudDownload />
            </IconButton>) }
            <IconButton  color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton> 
            </div>
          </Toolbar>
          <div className={classes.documentViewer}>
            <DocumentViewer file={file} />
          </div>
        </Dialog>
      ),
      [open, classes.toolbar, classes.icons, classes.documentViewer, title, downloadButtonVisible, handleDownload, file]
    ),
  };
};

export default useDocumentDialog;
