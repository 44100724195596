
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppAbility } from '../../casl';
import { EDIT } from '../../casl/actions';
import { JOB_AD } from '../../casl/subjects';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { TextField } from '../../components/ReadOnlySection';
import HTMLField from '../../components/ReadOnlySection/HTMLField';
import ReadOnlySection from '../../components/ReadOnlySection/ReadOnlySection';
import { dateTimeFormatter } from '../../utils/helperFunctions';
import useJobPosting from './hooks';

const useStyles = makeStyles({
    label: {
        fontWeight: 300
    },
    jobPostingTextBox: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '0px 0px 20px 40px',
        padding: '20px',
        border: '1px solid lightgrey',
        borderRadius: '20px'
    },
    wrapper: {
        width: '100%'
    },
    loadingDiv: {
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    loadingCircle: {
        margin: '0 auto'
    },
    authorizeButton: {
        textAlign: 'right'
    }
});

const JobPosting = (): JSX.Element => {

    const {jobPosting, loading, isReleasedByClientButton, changeIsReleased} = useJobPosting();
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('jobPosting');

    const ability = useAppAbility();

    const classes = useStyles();

    const handleReleasedButtonClick = () => {
        setOpen(true);
    };

    const handleClose = (accepted: boolean) => {
        if (accepted) {
            changeIsReleased();
        }
        setOpen(false);
    };

    if (loading) {
        return <div className={classes.loadingDiv}><CircularProgress className={classes.loadingCircle} size="80px" /></div>;
    }

    return (
        <>
            <ConfirmDialog 
                onClose={handleClose}
                title={t('confirmTitle')}
                text={t('confirmText')}
                cancelText={t('confirmCancel')}
                okText={t('confirmRelease')}
                open={open}
            />
            <Box pl={7} pr={7} py={4}>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <span className={classes.label}>{t('datePosted')}:</span><br />
                            <span>{dateTimeFormatter(jobPosting?.datePosted ? jobPosting?.datePosted : '', 'dd.LL.yyyy')}</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span className={classes.label}>{t('lastRefreshedDate')}:</span><br />
                            <span>{dateTimeFormatter(jobPosting?.lastRefreshedDate ?
                                 jobPosting?.lastRefreshedDate : '',
                                 'dd.LL.yyyy')}
                            </span>
                        </Grid>
                        <Grid item xs={3}>
                            <span className={classes.label}>{t('validThroughDate')}:</span><br />
                            <span>
                                {dateTimeFormatter(jobPosting?.validThroughDate ?
                                     jobPosting?.validThroughDate : '',
                                     'dd.LL.yyyy')}
                            </span>
                        </Grid>
                        {ability.can(EDIT, JOB_AD) && 
                            <Grid item xs={3} className={classes.authorizeButton}>
                                <Button
                                    onClick={handleReleasedButtonClick}
                                    variant="contained"
                                    disabled={isReleasedByClientButton}
                                    color='primary'
                                >{!isReleasedByClientButton ? t('authorizeJobPosting') : t('jobPostingAuthorized')}</Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
                <ReadOnlySection title={t('generalInformation')}>
                    <>
                        <TextField
                            label={t('city')}
                            value={jobPosting?.city}
                        />
                        <TextField
                            label={t('postalCode')}
                            value={jobPosting?.postalCode}
                        />
                        <TextField
                            label={t('country')}
                            value={jobPosting?.country}
                        />
                        <TextField
                            label={t('location')}
                            value={jobPosting?.location}
                        />
                        <TextField
                            label={t('title')}
                            value={jobPosting?.title}
                        />
                        <TextField
                            label={t('companyNameInPosting')}
                            value={jobPosting?.companyNameInPosting}
                        />
                    </>
                </ReadOnlySection>
                <ReadOnlySection title={t('jobPostingText')}>
                    <>
                    <div className={classes.jobPostingTextBox}>
                        <div className={classes.wrapper}>
                            <Typography variant='h6'>{t('description')}</Typography>
                            <hr color='lightgray'/>
                            <HTMLField
                                fullWidth
                                value={jobPosting?.description}
                            />
                        </div>
                    </div>
                  {jobPosting?.tasks &&  (
                  <div className={classes.jobPostingTextBox}>
                        <div className={classes.wrapper}>
                            <Typography variant='h6'>{t('tasks')}</Typography>
                            <hr color='lightgray'/>
                            <HTMLField
                                fullWidth
                                value={jobPosting?.tasks}
                            />
                        </div>
                    </div>)}
                  {jobPosting?.profile && ( 
                  <div className={classes.jobPostingTextBox}>
                        <div className={classes.wrapper}>
                            <Typography variant='h6'>{t('profile')}</Typography>
                            <hr color='lightgray'/>
                            <HTMLField
                                fullWidth
                                value={jobPosting?.profile}
                            />
                        </div>
                    </div>)}
                  {jobPosting?.offer &&  (
                  <div className={classes.jobPostingTextBox}>
                        <div className={classes.wrapper}>
                            <Typography variant='h6'>{t('offer')}</Typography>
                            <hr color='lightgray'/>
                            <HTMLField
                                fullWidth
                                value={jobPosting?.offer}
                            />
                        </div>
                    </div>)}
                    {jobPosting?.contactinfo && ( 
                    <div className={classes.jobPostingTextBox}>
                        <div className={classes.wrapper}>
                            <Typography variant='h6'>{t('contactInfo')}</Typography>
                            <hr color='lightgray'/>
                            <HTMLField
                                fullWidth
                                value={jobPosting?.contactinfo}
                            />
                        </div>
                    </div>)}
                    </>
                </ReadOnlySection>
            </Box>
        </>
    );
};

export default JobPosting;