import { DateTime } from 'luxon';

export const dateTimeFormatter = (dateValue: string, format: string): string => {
    try {
        if (DateTime.fromISO(dateValue).isValid) {
            return DateTime.fromISO(dateValue).setLocale('de').toFormat(format);
        }
        return '';
    } catch (e) {
        return '';
    }
};