export default {
    datePosted: 'Anzeige geschaltet am',
    lastRefreshedDate: 'Letztes Update',
    validThroughDate: 'Gültig bis',
    generalInformation: 'Allgemeine Informationen',
    workingLocation: 'Arbeitsort',
    postalCode: 'Postleitzahl',
    country: 'Land',
    city: 'Standort',
    location: 'Standort',
    title: 'Anzeigentitel',
    companyNameInPosting: 'Angezeigter Unternehmensname',
    partTimeFrom: 'Pensum von',
    partTimeTill: 'Pensum bis',
    jobPostingText: 'Texte der Stellenanzeige',
    authorizeJobPosting: 'Anzeige freigeben',
    jobPostingAuthorized: 'Anzeige ist freigegeben',
    confirmTitle: 'Anzeige freigeben',
    confirmText: 'Wollen Sie den Anzeige wirklich freigeben?',
    confirmCancel: 'Abbrechen',
    confirmRelease: 'Freigeben',

    description: 'Beschreibung: ',
    tasks: 'Aufgaben: ',
    profile: 'Profil: ',
    offer: 'Wir bieten: ',
    contactInfo: 'Kontaktangaben: ',
};