import { MUIDataTableColumnDef } from 'mui-datatables';

import { Icon, IconButton, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import CandidateRating from '../../../../../components/Rating/Rating';
import Rating from '../../../../../enums/Rating';
import TableType from '../../../../../enums/TableType';
import TEXT from '../../../../../text';
import { dateTimeFormatter } from '../../../../../utils/helperFunctions';
import { LienertDossierType } from '../../hooks';
import AdditionalDocuments from './Renderers/AdditionalDocuments';
import { ContractProposalSwitch } from './Renderers/ContractProposalSwitch';
import Dossier from './Renderers/Dossier';
import DossierViewedIcon from './Renderers/DossierViewedIcon';
import Interview from './Renderers/Interview';
import LienertDossier from './Renderers/LienertDossier';


const id: MUIDataTableColumnDef = {
  name: 'id',
  options: { display: 'excluded' },
};
const candidateId: MUIDataTableColumnDef = {
  name: 'candidateId',
  options: { display: 'excluded' },
};

const firstName: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.firstName,
  name: 'firstName',
  options: {
    filter: true,
    sort: false,
  },
};

const lastName: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.lastName,
  name: 'lastName',
  options: {
    filter: true,
    sort: false,
  },
};

const dateOfBirth: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.dateOfBirth,
  name: 'dateOfBirth',
  options: {
    filter: true,
    sort: false,
    customBodyRender: (value: string) => dateTimeFormatter(value, 'dd.LL.yyyy'),
  },
};
const city: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.city,
  name: 'city',
  options: {
    filter: true,
    sort: false,
  },
};
const duration: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.duration,
  name: 'duration',
  options: {
    filter: true,
    sort: false,
  },
};
const currentCompany: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.currentCompany,
  name: 'currentCompany',
  options: {
    filter: true,
    sort: false,
  },
};
const position: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.position,
  name: 'position',
  options: {
    filter: true,
    sort: false,
  },
};
const rating: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.rating,
  name: 'rating',
  options: {
    filter: true,
    sort: false,
    customBodyRender: (value: Rating) => <CandidateRating value={value} />,
  },
};

const dossierLienertLongList: MUIDataTableColumnDef = {
  label: `${TEXT.projectDetailPage.projectDetailTable.columns.documentName}`,
  name: 'dossierLienertLongList',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value, { rowData }) => (
      // TODO: types are not correct for some reason
      <LienertDossier
        key={rowData[0]}
        potentialCandidateId={rowData[0]}
        dossierType={LienertDossierType.longList}
        isAccessible={value}
      />
    ),
  },
};

const dossierLienertLatecomerList: MUIDataTableColumnDef = {
  label: `${TEXT.projectDetailPage.projectDetailTable.columns.documentName}`,
  name: 'dossierLienertLongList',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value, { rowData }) => (
      // TODO: types are not correct for some reason
      <LienertDossier
        key={rowData[0]}
        potentialCandidateId={rowData[0]}
        dossierType={LienertDossierType.latecomerList}
        isAccessible={value}
      />
    ),
  },
};

const dossierLienertShortList: MUIDataTableColumnDef = {
  label: `${TEXT.projectDetailPage.projectDetailTable.columns.documentName}`,
  name: 'dossierLienertShortList',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value, { rowData }) => (
      // TODO: types are not correct for some reason
      <LienertDossier
        key={rowData[0]}
        potentialCandidateId={rowData[0]}
        dossierType={LienertDossierType.shortList}
        isAccessible={value}
      />
    ),
  },
};

const showAdditionalDocuments: MUIDataTableColumnDef = {
  name: 'showAdditionalDocuments',
  options: { display: 'excluded' },
};

const additionalDocuments: MUIDataTableColumnDef = {
  label: TEXT.projectDetailPage.projectDetailTable.columns.additionalDocuments,
  name: 'additionalDocuments',
  options: {
    filter: true,
    sort: false,
    customBodyRender: (value, { rowData }) => (
      <AdditionalDocuments
        key={rowData[0]}
        potentialCandidateRowData={rowData}
        additionalDocumentsArray={value}
      />
    ),
  },
};

const firstInterview: MUIDataTableColumnDef = {
  label: 'Erstgespräch',
  name: 'clientAcceptsFirstInterview',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value: any, { rowData }) => (
      <Interview key={rowData[0]}
        id={rowData[1]} isSecondInterview={false} />
    ),
  },
};

const secondInterview: MUIDataTableColumnDef = {
  label: 'Zweitgespräch',
  name: 'clientAcceptsSecondInterview',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value: any, { rowData }) => (
      <Interview key={rowData[0]}
        id={rowData[1]} isSecondInterview />
    ),
  },
};


const senatorDossier: MUIDataTableColumnDef = {
  label: 'Dossier herunterladen',
  name: 'dossier',
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value: any, { rowData }) =>
      rowData[2] ? <Dossier  key={rowData[0]} candidateId={rowData[1]} /> : null

  }
}

const dossierIsVisibleForClient: MUIDataTableColumnDef = {
  name: 'dossierVisibleForClient',
  options: {
    display: 'excluded',
  }

}

const dossierIsViewedByClient: MUIDataTableColumnDef = {
  label: 'Dossier gelesen',
  name: 'dossierIsViewedByClient',
  options: {
    filter: true,
    sort: true,
    customBodyRender: (value: boolean, {rowData}) => (
      <DossierViewedIcon  key={rowData[0]} dossierViewed={value} />
    ),
  },
};

const dossierCreationDate: MUIDataTableColumnDef = {
  name: 'dossierCreationDate',
  label: 'Bereitgestellt am',
  options: {
    filter: true,
    sort: true,
    customBodyRender: (value: string) => dateTimeFormatter(value, 'dd.LL.yyyy'),
  },
};

const rejectionDate: MUIDataTableColumnDef = {
  name: 'rejectionDate',
  label: 'Abgesagt am',
  options: {
    filter: true,
    sort: true,
    customBodyRender: (value: string) => dateTimeFormatter(value, 'dd.LL.yyyy'),
  },
};

const clientWishesContractProposal: MUIDataTableColumnDef = {
  name: 'clientWishesContractProposal',
  label: 'Vertragsangebot',
  options: {
    filter: true,
    sort: true,
    customBodyRender: (value: any, { rowData }) => (
      <ContractProposalSwitch  key={rowData[0]} checked={value} id={rowData[1]} />
    ),
  },
};

interface Props {
  rowData: any[],
  columnIndex: number
}

const PopoverButton = ({ rowData, columnIndex }: Props): JSX.Element | null => {
  const [anchorElement, setAnchorElement] = useState<(EventTarget & Element) | null>(null);

  const handleClick = (event: React.MouseEvent | null) => {
    setAnchorElement(event === null ? null : event.currentTarget);
  }

  if (rowData[columnIndex] && rowData[columnIndex] !== '') {
    const elementId = anchorElement ? 'simple-popover' : undefined;
    return (
      <div>
        <IconButton aria-describedby={elementId} onClick={(event) => { handleClick(event) }}>
          <Icon className='td-icon-center'>info</Icon>
        </IconButton>
        <Popover
          id={elementId}
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={() => { handleClick(null) }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{ paper: 'htmlPopoverPaper' }}
        >
          <Typography className='htmlpopover' dangerouslySetInnerHTML={{ __html: rowData[columnIndex] }} />
        </Popover>
      </div>
    );
  }
  return null;
};

export const useColumns = (tableType: TableType): MUIDataTableColumnDef[] => {
  const clientCommentLongList: MUIDataTableColumnDef = {
    label: 'Kommentar',
    name: 'clientCommentLongList',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowData, columnIndex }) => (
        <PopoverButton
          rowData={rowData}
          columnIndex={columnIndex}
        />
      )
    }
  };


  const projectComment: MUIDataTableColumnDef = {
    label: 'Beraterkommentar',
    name: 'comment',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, { rowData, columnIndex }) => (
        <PopoverButton
          rowData={rowData}
          columnIndex={columnIndex}
        />
      )
    }
  };

  const columnsStandard: MUIDataTableColumnDef[] = [
    id,
    candidateId,
    firstName,
    lastName,
    dateOfBirth,
    city,
    duration,
    currentCompany,
    position,
    rating,
    dossierLienertLongList,
    clientCommentLongList,
    projectComment
  ];

  const columnsLatecomer: MUIDataTableColumnDef[] = [
    id,
    candidateId,
    firstName,
    lastName,
    dateOfBirth,
    city,
    duration,
    currentCompany,
    position,
    rating,
    dossierLienertLatecomerList,
    clientCommentLongList,
    projectComment
  ];

  const columnsShort: MUIDataTableColumnDef[] = [
    id,
    candidateId,
    firstName,
    lastName,
    dateOfBirth,
    city,
    duration,
    currentCompany,
    position,
    rating,
    dossierLienertShortList,
    showAdditionalDocuments,
    additionalDocuments,
    clientCommentLongList,
    projectComment
  ];

  const columnsSenatorDossier: MUIDataTableColumnDef[] = [
    id,
    candidateId,
    dossierIsVisibleForClient,
    dossierIsViewedByClient,
    firstName,
    lastName,
    dossierCreationDate,
    firstInterview,
    secondInterview,
    clientWishesContractProposal,
    rejectionDate,
    senatorDossier,
    // TODO: May show more in dossier
  ];

  switch (tableType) {
    case TableType.LONG_LIST:
    case TableType.RECEIVED_APPLICATIONS:
      return columnsStandard;
    case TableType.SHORT_LIST:
    case TableType.DYNAMIC_SPECIAL_LIST:
      return columnsShort;
    case TableType.LATECOMER_LIST:
      return columnsLatecomer
    case TableType.CANDIDATE_DOSSIER_LIST:
      return columnsSenatorDossier
    default:
      return []
  }
}

// TODO: Add more columns def
