import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, createTheme, Paper, ThemeProvider } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { LoginFormFieldsValues } from '../../models/LoginFormFieldsValues';
import RequestTan from '../../models/RequestTanEnum';
import TEXT from '../../text';
import THEME from '../../theme';
import { useLoginContext } from './hook';
import LoginFormFields from './LoginFormFields';

const useStyle = makeStyles(
  createStyles({
    wrongTanAlert: {
      color: 'rgb(255,255,255)',
      backgroundColor: 'rgb(225,45,28)',
      padding: '13px 16px',
      borderRadius: '4px',
      display: 'flex',
      lineHeight: '1.7',
      marginTop: '10px',
      marginBottom: '10px',
    },
    errorIcon: {
      marginRight: '12px',
      opacity: '0.9',
      color: '#ffffff',
      fontSize: '22px',
    },
  })
);

const loginTheme = createTheme(THEME.login);

const LoginForm = (): JSX.Element => {
  const { t } = useTranslation('auth');
  const { loginCall, tanType, errorMsg } = useLoginContext();
  const classes = useStyle();
  const handleSubmit = async (
    values: LoginFormFieldsValues,
    formikHelpers: FormikHelpers<LoginFormFieldsValues>
  ) => {
    formikHelpers.setSubmitting(true);
    loginCall(values).then(() => {
      formikHelpers.setSubmitting(false);
    });
  };

  const validationLoginSchema = yup.object({
    email: yup.string().required(t('emailRequired')).email(t('emailError')),
    password: yup.string().required(t('passwordRequired')),
  });

  const validationTanSchema = yup.object({
    tan: yup
      .string()
      .matches(/^\d+$/, t('tanOnlyDigits'))
      .min(6, t('tanMin6Chars'))
      .required(t('tanRequired')),
  });

  const validationSMSSchema = yup.object({
    tan: yup
      .string()
      .matches(/^\d+$/, t('smsCodeOnlyDigits'))
      .min(6, t('smsCodeMin6Chars'))
      .required(t('smsCodeRequired')),
  });

  const getValidationSchema = (): any => {
    switch (tanType) {
      case RequestTan.None: {
        return validationLoginSchema;
      }
      case RequestTan.TAN: {
        return validationTanSchema;
      }
      case RequestTan.SMS: {
        return validationSMSSchema;
      }
      default: {
        return validationLoginSchema;
      }
    }
  };

  const initialLoginValues: LoginFormFieldsValues = {
    email: '',
    password: '',
    tan: '',
    requestTan: false,
  };

  const formik = useFormik({
    initialValues: initialLoginValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Paper className="paperLogin text-center" elevation={24}>
          <ThemeProvider theme={loginTheme}>
            <Paper
              className="paperLogin text-center"
              elevation={0}
              sx={{ marginBottom: 3, ...THEME.loginPaper }}
            >
              <img
                alt={TEXT.loginPage.imageAlt}
                src={THEME.loginLogo}
                style={THEME.loginLogoStyle}
              />
            </Paper>
          </ThemeProvider>
          <LoginFormFields requestTan={tanType} />
          {errorMsg && (
            <div className={classes.wrongTanAlert}>
              <ErrorOutlineIcon className={classes.errorIcon} />
              {errorMsg}
            </div>
          )}
          <Button
            className="login-button mt-3"
            color="primary"
            disabled={!formik.isValid}
            fullWidth
            type="submit"
            variant="contained"
          >
            {TEXT.loginPage.button}
          </Button>
        </Paper>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
