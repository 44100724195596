import React, { useCallback, useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { HomeParams } from '../../pages/Home/Home.params';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  selected: number;
}

function TabPanel({ children, selected, index }: TabPanelProps) {
  return (
    <div hidden={selected !== index} style={{ height: '100%' }}>
      {selected === index ? children : null}
    </div>
  );
}

export interface TabConfig {
  id: string;
  title: string;
  content: React.ReactNode;
}

interface TabReturn {
  Tabs: React.ReactNode;
  TabContent: React.ReactNode;
}

function useTabs(tabs: TabConfig[], defaultTab?: number): TabReturn {
  const navigate = useNavigate();
  const params = useParams<HomeParams>();
  const tab = !defaultTab || defaultTab < 0 ? 0 : defaultTab;
  const [selectedTab, setSelectedTab] = React.useState(tab);

  const handleChangeTab = useCallback(
    (event: unknown, newValue: number) => {
      setSelectedTab(newValue);
      navigate(`/projects/details/${params.selectedProjectId}/${tabs[newValue].id}`);
    },
    [navigate, params.selectedProjectId, tabs]
  );

  return {
    Tabs: (
      <Tabs centered onChange={handleChangeTab} value={selectedTab}>
        {useMemo(
          () => tabs.map((t) => <Tab key={t.title} label={t.title} />),
          [tabs]
        )}
      </Tabs>
    ),
    TabContent: useMemo(
      () =>
        tabs.map((t, i) => (
          <TabPanel index={i} key={t.title} selected={selectedTab}>
            {t.content}
          </TabPanel>
        )),
      [selectedTab, tabs]
    ),
  };
}

export default useTabs;
