import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import dataTable from '../components/DataTable/translations';
import auth from '../modules/Auth/translations';
import sidebar from '../modules/Company/Details/translations';
import projectListTable from '../modules/Company/ProjecstList/Table/translations';
import error from '../modules/Error/translations';
import jobPosting from '../modules/JobPosting/translations';
import table from '../modules/SingleProject/CandidatesList/tanslations';
import documentList from '../modules/SingleProject/Documents/DocumentList/translations';
import lienertDossierDialog from '../modules/SingleProject/LienertDossierDialog/translations';
import statusReport from '../modules/SingleProject/Report/translations';
import singleProjectInformationsForm from '../modules/SingleProject/RequirementsProfile/Form/translations';
import errorPage from '../pages/Error/translations';
import singleProject from '../pages/Home/SingleProject/translations';
import loginPage from '../pages/Login/translations';
import register from '../modules/Register/translation';
import notificationTranslation from '../modules/Notificaton/translation'

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: {
        loginPage,
        table,
        sidebar,
        projectListTable,
        singleProject,
        jobPosting,
        dataTable,
        singleProjectInformationsForm,
        statusReport,
        documentList,
        // singleProjectDossierListTable,
        auth,
        error,
        errorPage,
        lienertDossierDialog,
        notificationTranslation,
        register
      },
    },
    lng: 'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  });
