interface NotificationTranslation {
  title: string;
  description: string;
}
type NotificationTranslations = Record<string, NotificationTranslation>;

const notificationTranslation: NotificationTranslations = {
  clientProjectRelease: {
    title: 'Auftrag freigeschaltet',
    description: 'Auftrag {{projectName}} wurde freigegeben',
  },
  clientJobPostingUpdated: {
    title: 'Anzeige wurde aktualisiert',
    description: 'Die Anzeige {{projectName}} wurde aktualisiert',
  },
  clientNewDossierAdded: {
    title: 'Dossier zum Auftrag hinzugefügt',
    description:
      'Es wurde ein neues Dossier zum Auftrag {{projectName}} hinzugefügt',
  },
  clientNewDokumentAdded: {
    title: 'Dokument zum Auftrag hinzugefügt',
    description:
      'Es wurde ein neues Dokument zu Auftrag {{projectName}} hinzugefügt',
  },
  clientNewLinkAdded: {
    title: 'Neuer Link freigeschaltet',
    description: 'Es wurde ein neuer Link zu Auftrag {{projectName}} hinzugefügt',
  }
};

export default notificationTranslation;
