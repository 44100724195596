import { Box, BoxProps, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    fontWeight: 300,
  },
});

interface Props extends BoxProps {
  title: string;
  value?: string | number;
}

const NamedField = ({ title, value, ...boxProps }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box {...boxProps}>
      <Typography className={classes.title} variant="subtitle2">
        {title}:
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

export default NamedField;
