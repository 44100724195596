import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormikProps } from 'formik';

interface Props {
  onCancel: () => void;
  saveButtonText: string;
  cancelButtonText: string;
  // eslint-disable-next-line
  formik: FormikProps<any>;
}

const useStyles = makeStyles({
  buttonWrapper: {
    position: 'relative',
    display: 'initial',
  },
  saveButtonContainer: {
    textAlign: 'right',
  },
  root: {
    paddingTop: 20,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const FormButtons = memo(
  ({ onCancel, saveButtonText, cancelButtonText, formik }: Props) => {
    const { t } = useTranslation('form');

    const classes = useStyles();

    const saveText = saveButtonText || t('save');
    const cancelText = cancelButtonText || t('abort');

    return (
      <Grid className={classes.root} container spacing={3}>
        <Grid item xs={6}>
          <div className={classes.buttonWrapper}>
            <Button onClick={onCancel} variant="contained">
              {cancelText}
            </Button>
          </div>
        </Grid>
        <Grid className={classes.saveButtonContainer} item xs={6}>
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              startIcon={<FontAwesomeIcon icon={faSave} />}
              type="submit"
              variant="contained"
            >
              {saveText}
            </Button>
            {formik.isSubmitting && (
              <CircularProgress className={classes.loader} size={24} />
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
);

FormButtons.displayName = 'FormButtons';

export default FormButtons;
