/* eslint-disable react/no-unstable-nested-components */
import { Drafts, Mail, OpenInNew } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React, { useMemo } from 'react';
import { NotificationsDTO } from '../../api/notifications.dto';
import DataTable from '../../components/DataTable';
import useMapNotification from './hooks';

interface Props {
  notifications: NotificationsDTO[],
}

const NotificationMap: React.FC<Props> = ({ notifications }) => {

  const { mappedNotificationData } = useMapNotification({ notifications })

  const COLUMNS = useMemo((): MUIDataTableColumnDef[] => ([
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      }
    },
    {
      name: 'isRead',
      label: 'Gelesen',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          const isRead = Boolean(value);
          return isRead ? <Drafts /> : <Mail />
        },
      }
    },
    {
      name: 'triggerDate',
      label: 'Datum',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          const triggerDate = value as Date;

          return DateTime.fromJSDate(triggerDate).toLocaleString(DateTime.DATE_MED)
        },
      }
    },
    {
      name: 'title',
      label: 'Titel',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'description',
      label: 'Beschreibung',
      options: {
        searchable: true,
        filter: false,
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Aktionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender(value) {
          return value ? <IconButton onClick={value}><OpenInNew /></IconButton> : null;
        },
      }
    }
  ]), [])

  return (
    <Box maxWidth='100%'>
      <DataTable rowsPerPage={5} elevation={0} columns={COLUMNS} data={mappedNotificationData} title="Benachrichtigungen" />
    </Box>

  )
}

export default NotificationMap