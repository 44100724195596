import { makeStyles } from '@mui/styles';
import { Buffer } from 'buffer';
import { useAppAbility } from '../../../casl';
import { SEE } from '../../../casl/actions';
import { COMPANY_LOGO } from '../../../casl/subjects';
import HeaderedPaper from '../../../components/HeaderedPaper';
import useInformations from '../../SingleProject/RequirementsProfile';
import Fields from './Fields';
import Title from './Title';

const useStyles = makeStyles({
  companyLogo: {
    padding: '10px',
    alignSelf: 'center',
  },
});

interface DetailsProps {
  selectedCustomerId: string;
}

const Details = ({ selectedCustomerId }: DetailsProps): JSX.Element => {
  const { informations } = useInformations({ selectedCustomerId });
  const ability = useAppAbility();
  const classes = useStyles();
  return (
    <HeaderedPaper
      header={
        <>
          {informations?.picture && ability.can(SEE, COMPANY_LOGO) && (
            <img
              src={`data:image/jpeg;base64,${Buffer.from(
                informations.picture
              ).toString('base64')}`}
              alt=""
              width="100%"
              className={classes.companyLogo}
            />
          )}
          <Title
            title={
              informations?.title ||
              informations?.jobTitle1 ||
              informations?.projectNumber ||
              ''
            }
          />
        </>
      }
      height="100%"
    >
      <Fields information={informations} />
    </HeaderedPaper>
  );
};

export default Details;
