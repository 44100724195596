import { Route, Routes } from 'react-router-dom';

import { SafeGuard } from '../../modules/Auth';
import Company from './Company';
import SingleProject from './SingleProject';
import { useProjectList } from '../../modules/Company/ProjecstList';

const Home = (): JSX.Element => {
  const { setCustomerId, projects, selectedCustomerId } = useProjectList();

  return (
    <SafeGuard>
      <Routes>
        <Route
          element={
            <Company
              projects={projects}
              setCustomerId={setCustomerId}
              selectedCustomerId={selectedCustomerId}
            />
          }
          path="/" />
        <Route
          element={
            <SingleProject
              projects={projects}
              setCustomerId={setCustomerId}
              selectedCustomerId={selectedCustomerId}
            />
          }
          path="/details/:selectedProjectId/:selectedTabId?"
        />
      </Routes>
    </SafeGuard>
  );
};

export default Home;
