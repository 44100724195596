import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FolderIcon from '@mui/icons-material/Folder';
import { CircularProgress, Divider, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

import { useSnackbar } from 'notistack';
import { LinkType } from '../../../../api/projects.dto';
import useDocumentDialog from '../../../../components/DocumentDialog';
import { useWithMessage } from '../../../Error';
import LinkList from '../LinkList/LinkList';
import useDocuments from '../hooks';
import { Can } from '../../../../casl';
import { SEE } from '../../../../casl/actions';
import { LINKS } from '../../../../casl/subjects';

const useStyles = makeStyles({
    list: {
        width: '100%'
    },
    loadingDiv: {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 10
    },
    noDocumentsDiv: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});

interface DocumentListProps {
    selectedCustomerId: string;
}

const DocumentList = ({ selectedCustomerId }: DocumentListProps): JSX.Element => {
    const classes = useStyles();

    const { documents, links, loadSingleDocument } = useDocuments({ selectedCustomerId });
    const { open, openDialog, DocumentDialog } = useDocumentDialog();
    const [loading, setLoading] = useState(false);
    const withMessage = useWithMessage();
    const { enqueueSnackbar } = useSnackbar()

    const { t } = useTranslation('documentList');

    const handleLoading = useCallback(
        () => {
            setLoading(open);
        }, [open]
    );

    useEffect(() => {
        handleLoading();
    }, [handleLoading]);

    const handleClick = useCallback(async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        id: string | undefined,
        fileName: string) => {
        if (id) {
            setLoading(true);
            const singleDocument = await loadSingleDocument(id, fileName).catch(withMessage());
            openDialog(singleDocument?.contentUrl, 'Report', true);
            setLoading(false);
        }
    }, [loadSingleDocument, openDialog, withMessage]);

    const documentList = documents?.map((document) => (
        <ListItem
            button
            // eslint-disable-next-line no-underscore-dangle
            onClick={(event) => handleClick(event, document._id, document.documentName)}
            key={documents?.indexOf(document)}
        >
            <ListItemAvatar>
                <Avatar>
                    <FolderIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={document.documentName} />
        </ListItem>
    ));

    const handleOnLinkClick = useCallback((link: LinkType) => {
        navigator.clipboard.writeText(link.url);
        enqueueSnackbar(t('clipboard'))
    }, [enqueueSnackbar, t])

    const handleOnLinkOpen = useCallback((link: LinkType) => {
        window.open(link.url, '_blank');
    }, [])

    return (
        <>
            {loading &&
                <div className={classes.loadingDiv}>
                    <div>
                        <CircularProgress color="secondary" size={60} />
                    </div>
                </div>
            }
            {documentList && documentList?.length > 0 ?
                <>
                    <Typography sx={{ padding: 2 }} variant='overline'>{t('documentsHeader')}</Typography>
                    <List className={classes.list}>
                        {documentList}
                    </List>
                </>
                : <div className={classes.noDocumentsDiv}>{t('noDocumentsFound')}</div>}
            {DocumentDialog}
            <Can I={SEE} a={LINKS}>
                <Divider />
                <Typography sx={{ padding: 2 }} variant='overline'>{t('linksHeader')}</Typography>
                <LinkList links={links} onLinkClick={handleOnLinkClick} onLinkOpen={handleOnLinkOpen} />
            </Can>



        </>
    );
};

export default DocumentList;
