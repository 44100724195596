import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getProjectByIdForClient,
  getProjectDocumentById,
} from '../../../api/projects';
import {
  LinkType,
  ProjectDocumentDTO,
  ProjectSingleDocumentDTO,
} from '../../../api/projects.dto';
import { AbilityContext } from '../../../casl';
import { SEE } from '../../../casl/actions';
import { LINKS } from '../../../casl/subjects';

interface Return {
  documents: ProjectDocumentDTO[] | undefined;
  links: LinkType[];
  loadSingleDocument: (
    documentId: string,
    fileName: string
  ) => Promise<ProjectSingleDocumentDTO | undefined>;
}

type Params = {
  selectedProjectId: string;
};

interface UseDocumentsProps {
  selectedCustomerId: string;
}

const useDocuments = ({ selectedCustomerId }: UseDocumentsProps): Return => {
  const ability = useContext(AbilityContext);

  const [documents, setDocuments] = useState<ProjectDocumentDTO[] | undefined>(
    []
  );
  const [links, setLinks] = useState<LinkType[]>([]);

  const { selectedProjectId } = useParams<Params>();
  const reloadData = useCallback(async () => {
    try {
      const { project } = await getProjectByIdForClient(
        selectedProjectId || '',
        selectedCustomerId
      );
      setDocuments(project.documents);
      if (ability.can(SEE, LINKS)) {
        setLinks(project.links);
      }
    } catch (e) {
      setDocuments([]);
    }
  }, [selectedProjectId, ability, selectedCustomerId]);

  const loadSingleDocument = async (documentId: string, fileName: string) => {
    try {
      const document = await getProjectDocumentById(
        documentId,
        fileName,
        selectedProjectId || '',
        selectedCustomerId
      );
      return document;
    } catch (e) {
      throw new Error();
    }
  };

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  return { documents, links, loadSingleDocument };
};

export default useDocuments;
