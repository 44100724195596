import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Grid, Typography,} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Form, Formik, FormikHelpers} from 'formik';
import * as qrcode from 'qrcode';

import { useNavigate } from 'react-router-dom';
import {get2FASecret, verifyTan} from '../../api/login';
import {Input} from '../../components/Form';
import {LoginPhase, useLoginContext} from './hook';

/* TODO: transform to Material UI styles and use the theme */
// import './RegisterTan.css';

interface TanFields {
  tan: string
}

const useStyle = makeStyles(
  createStyles({
    typography: {
      color: 'grey',
    },
    wrongTanAlert: {
      color: 'rgb(97, 26, 21)',
      backgroundColor: 'rgb(253, 236, 234)',
      padding: '13px 16px',
      borderRadius: '4px',
      display: 'flex',
      lineHeight: '1.7',
    },
    errorIcon: {
      marginRight: '12px',
      opacity: '0.9',
      color: '#f44336',
      fontSize: '22px',
    },
  })
);
  
const RegisterTan = (): JSX.Element => {
  const { t } = useTranslation('auth');
  const loginState = useLoginContext();
  
  const [secret, setSecret] = useState<string>();
  const [qrURL, setQRUrl] = useState<string>('');
  const [errorWrongTan, setErrorWrongTan] = useState(false);
  const [tanIsValidated, setTanIsValidated] = useState(false);
  
  const classes = useStyle();
  
  const navigate = useNavigate();
    
  useEffect(() => {
    // prepare QRCodeImage
    const keyName = 'HR-Consult-Group';

    if (secret) {
      const rawUrl = `otpauth://totp/${keyName}?secret=${secret}`;
      qrcode.toDataURL(rawUrl).then(setQRUrl);
    }
  }, [secret]);

  useEffect(() => {
    get2FASecret()
      .then((response) => {
        const { userStatus, twoFASecret } = response.data;
        if (userStatus === 'passwordSet') {
          setSecret(twoFASecret);
        } else if (userStatus === 'initialLogin') {
          loginState.setLoginPhase(LoginPhase.REGISTER);
        } else {
          loginState.setLoginPhase(LoginPhase.LOGIN);}
        })

      .catch((err) => {
        // ToDo: error handling
        // eslint-disable-next-line no-console
        console.error(err);
      });
  });

  const handleSubmit = async (
    values: TanFields,
    formikHelpers: FormikHelpers<TanFields>
  ) => {
    formikHelpers.setSubmitting(true);

    verifyTan(values.tan)
      .then((res) => {
        if (res.data.userStatus === 'active' && res.data.isValidTan) {
          setTanIsValidated(true);
          setErrorWrongTan(false);
        } else if (!res.data.isValidTan) {
          setErrorWrongTan(true);
          formikHelpers.resetForm();
        } else {
          // TODO: error handling
          navigate('/err');
        }
        formikHelpers.setSubmitting(false);
      })
      .catch((err) => {
        if (err.status) {
          setErrorWrongTan(true);
          formikHelpers.resetForm();
        }

        // TODO: Error handling
        // const ignoreErrorCodes = [400];
        // this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  const handleButtonRedirect = () => {
    loginState.setLoginPhase(LoginPhase.FINISHED);
  };

  const initialValues: TanFields = {
    tan: '',
  };
  
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item>
        {/* TODO: Use translation  */}
        <Grid alignItems="center" container justifyContent="center">
          <Grid item>
            <h1>{t('registerTan.header')}</h1>
          </Grid>
        </Grid>
        <Box border={1} borderColor="lightgray" padding={3}>
          <h4>{t('registerTan.activate2FA')}</h4>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Typography className={classes.typography} variant="h6">
                {t('registerTan.step')} 1
              </Typography>
            </Grid>
            <Grid item md={10}>
              {t('registerTan.useGoogleAuthenticator')}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Typography className={classes.typography} variant="h6">
                {t('registerTan.step')} 2
              </Typography>
            </Grid>
            <Grid item md={10}>
              {t('registerTan.scan2FA')}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              {secret && qrURL ? (
                <img alt="qr code for 2 factor authentication" src={qrURL} />
              ) : (
                <CircularProgress className="mb-3" size={60} thickness={3} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Typography className={classes.typography} variant="h6">
                {t('registerTan.step')} 3
              </Typography>
            </Grid>
            <Grid item md={10}>
              {t('registerTan.enterTan')}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              {errorWrongTan && (
                <div className={classes.wrongTanAlert}>
                  <ErrorOutlineIcon className={classes.errorIcon} />
                  {t('registerTan.tanNotValid')}
                </div>
              )}
              {tanIsValidated ? (
                <Button
                  className="buttonRedirect"
                  color="primary"
                  onClick={handleButtonRedirect}
                  variant="contained"
                >
                  {t('registerTan.buttonRedirect')}
                  <i className="material-icons pl-1">arrow_forward_ios</i>
                </Button>
              ) : (
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                  {(formik) => (
                    <Form>
                      <Input
                        formik={formik}
                        fullWidth
                        id="tan"
                        inputMode="numeric"
                        label={t('registerTan.tanFieldLabel')}
                        margin="normal"
                        name="tan"
                        onBlur={(e) => formik.handleBlur(e)}
                        type="tan"
                      />
                      <Button
                        color="primary"
                        fullWidth
                        type="submit"
                        variant="contained"
                      >
                        {t('registerTan.buttonValidateTan')}
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterTan;
