import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormikContext } from 'formik';

import { Input } from '../../components/Form';
import { LoginFormFieldsValues } from '../../models/LoginFormFieldsValues';
import RequestTan from '../../models/RequestTanEnum';

interface LoginFormProps {
  requestTan: RequestTan;
}

const useStyles = makeStyles({
  input: {
    minHeight: '70px',
  },
  fieldWidth: {
    width: '100%',
  },
});

const LoginFormFields = ({ requestTan }: LoginFormProps): JSX.Element => {
  const { t } = useTranslation('auth');
  const formik = useFormikContext<LoginFormFieldsValues>();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.fieldWidth}>
      {requestTan === RequestTan.None && (
        <div>
          <Input
            autoFocus
            formik={formik}
            id="email"
            label={t('labelEmail')}
            name="email"
            onBlur={(e) => formik.handleBlur(e)}
            type="email"
            className={classes.input}
            variant="filled"
          />
          <Input
            formik={formik}
            fullWidth
            id="password"
            label={t('labelPassword')}
            margin="normal"
            name="password"
            onBlur={(e) => formik.handleBlur(e)}
            type={showPassword ? 'text' : 'password'}
            className={classes.input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="filled"
          />
        </div>
      )}
      {requestTan !== RequestTan.None && (
        <div>
          <Input
            autoFocus
            formik={formik}
            fullWidth
            id="tan"
            label={
              requestTan === RequestTan.SMS ? t('labelSmsCode') : t('labelTan')
            }
            margin="normal"
            name="tan"
            onBlur={(e) => formik.handleBlur(e)}
            className={classes.input}
            variant="filled"
          />
        </div>
      )}
    </div>
  );
};

export default LoginFormFields;
