
import { TFunction } from 'i18next';
import { MUIDataTableColumnDef } from 'mui-datatables';

import TabShortcuts from './Renderers/TabShortcuts';

const getColumns = (
  translation: TFunction
): MUIDataTableColumnDef[] => [
  {
    name: '_id',
    options: {
      display: false,
    },
  },
  {
    name: 'projectNumber',
    label: 'Auftragsnummer',
  },
  {
    name: 'title',
    label: translation('title'),
  },
  {
    name: 'tabShortcuts',
    options: {

      customHeadLabelRender: () => <> </>,
      customBodyRender: (value, tableMeta) => (
        <TabShortcuts columnId={tableMeta.rowData[0]} project={value} />
      ),
    },
  },
  // {
  //   name: 'dynamische Listen',
  //   label: translation('dynamicLists'),
  //   options: {
  //     customBodyRender: (
  //       value: string | string[]
  //     ): React.ReactNode | undefined => {
  //       if (Array.isArray(value)) {
  //         return value.map((v) => <div key={v}>{v}</div>);
  //       }
  //       return value;
  //     },
  //   },
  // },
];
export default getColumns;
