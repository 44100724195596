import React, { memo } from "react";

import { TextField, TextFieldProps } from "@mui/material";
import { FormikProps } from "formik";

interface Props {
  // eslint-disable-next-line
  formik: FormikProps<any>;
  name: string;
}

const Input: React.FC<TextFieldProps & Props> = memo(
  ({ formik, name, ...rest }) => {
    const handleChange = (
      evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      const target = evt.target as HTMLTextAreaElement | HTMLInputElement;
      formik.setFieldValue(name, target.value);
    };

    const helperText = formik.touched[name] && formik.errors[name]
    
    return (
      <TextField
        error={formik.touched[name] && Boolean(formik.errors[name])}
        fullWidth
        helperText={helperText && `${helperText}`}
        name={name}
        onChange={handleChange}
        size="small"
        value={formik.values[name]}
        variant="outlined"
        {...rest}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
