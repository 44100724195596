import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmDialogProps {
    onClose: (agree: boolean) => void;
    title: string;
    text: string;
    cancelText: string;
    okText: string;
    open: boolean;
  }

const ConfirmDialog = ({onClose, open, title, text, cancelText, okText,}: ConfirmDialogProps): JSX.Element => {

    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                  {text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCancel} color="primary">
                {cancelText}
            </Button>
            <Button onClick={handleOk} color="primary" autoFocus>
                {okText}
            </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
