import TableType from '../../../../enums/TableType';
import { CandidatesListProvider } from '../../../../modules/SingleProject/CandidatesList/hooks';
import Table from '../../../../modules/SingleProject/CandidatesList/Table';

interface Props {
  tableType: TableType;
  selectedCustomerId: string;
}

const Candidates = ({ tableType, selectedCustomerId }: Props): JSX.Element => (
  <CandidatesListProvider>
    <Table tableType={tableType} selectedCustomerId={selectedCustomerId} />
  </CandidatesListProvider>
);

export default Candidates;
