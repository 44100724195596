import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Editor } from '@tinymce/tinymce-react';

import { CandidateDTO } from '../../../api/projects.dto';
import CandidateRating from '../../../components/Rating';
import Rating from '../../../enums/Rating';
import { CommentType } from '../CandidatesList/Table/Columns/Renderers/Comment/hooks';

const useStyles = makeStyles({
  candidateCommentContainer: {
    padding: '30px 0',
  },
  consultantComment: {
    padding: '10px',
    border: '1px solid #c5c5c5',
  },
  consultantNoComment: {
    color: '#9f9f9f',
    fontStyle: 'italic',
  },
});

interface Props {
  candidate: CandidateDTO;
  commentType: CommentType;
  onClose: () => void;
  handleEditorChange: (text: string) => void;
}

const LienertComments: React.FC<Props> = ({
  candidate,
  commentType,
  handleEditorChange,
}) => {
  const classes = useStyles();

  const { t } = useTranslation('lienertDossierDialog');

  return (
    <Box padding={2}>
      <Box fontWeight={500}>{t('rating')}</Box>
      <Box>
        {candidate.rating ? (
          <CandidateRating value={candidate.rating as Rating} />
        ) : (
          t('noRating')
        )}
      </Box>

      <Box className={classes.candidateCommentContainer}>
        <Box fontWeight={500}>{t('commentCustomer')}</Box>
        <Editor
          initialValue={candidate[commentType] || ''}
          init={{
            language_url: '/langs/de.js',
            branding: false,
            height: '300',
            fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
            plugins:
              'link code lists textcolor colorpicker textpattern imagetools',
            font_formats:
              // eslint-disable-next-line max-len
              'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif',
            toolbar:
              'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image',
          }}
          onEditorChange={handleEditorChange}
        />
      </Box>
      <Box>
        <Box marginBottom={1} fontWeight={500}>
          {t('consultantComment')}
        </Box>
        {candidate.comment ? (
          <Box
            className={classes.consultantComment}
            dangerouslySetInnerHTML={{ __html: candidate.comment }}
          />
        ) : (
          <Box
            className={`${classes.consultantComment} ${classes.consultantNoComment}`}
          >
            {t('noComment')}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LienertComments;
