import { PureComponent, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ProviderContext, withSnackbar } from 'notistack';

import { handleError } from './MessageError';

// https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/

class ErrorBoundary extends PureComponent<
  ProviderContext & WithTranslation & { children: ReactNode }
> {
  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error: unknown): void {
    const { enqueueSnackbar, t } = this.props;
    handleError(error, enqueueSnackbar, t);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler
    );
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    const { enqueueSnackbar, t } = this.props;
    handleError(event, enqueueSnackbar, t);
  };

  render(): ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default withTranslation('error')(withSnackbar(ErrorBoundary));
