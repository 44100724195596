import { AxiosPromise } from 'axios';

import { get2FASecretURL, loginURL, verifyTanURL, verifyTokenURL } from '../constants';
import { doFetch } from './apiUtils';

export interface LoginRequestDTO {
  email: string;
  password: string;
  tan?: string;
  smsTan?: string;
  company?: string;
}

export interface LoginResponseDTO {
  status: string;
  roles: string[];
}

export interface Get2FASecretResponseDTO {
  twoFASecret: string;
  userStatus: string;
}

export interface VerifyTanResponseDTO {
  isValidTan: boolean;
  userStatus: string;
}

export interface VerifyTokenResponseDTO {
  notificationCount: number;
  user: {
    status: string,
    roles: string[]
  }
}

export const loginApiCall = (requestBody: LoginRequestDTO): AxiosPromise<LoginResponseDTO> => {
  const res = doFetch(loginURL, requestBody, "POST", {
    ignore401NavToLogin: true
  });
  return res;
};

export const get2FASecret = (): AxiosPromise<Get2FASecretResponseDTO> => {
  const res = doFetch(get2FASecretURL, null, "POST");
  return res;
};

export const verifyTan = (tan: string): AxiosPromise<VerifyTanResponseDTO> => {
  const res = doFetch(verifyTanURL, {tan}, "POST");
  return res;
};

export const verifyToken = (): AxiosPromise<VerifyTokenResponseDTO> => {
  const res = doFetch(verifyTokenURL, {
    // TODO: Do we have / need release notes?  requestReleaseNote:
  }, "POST");
  return res;
};
