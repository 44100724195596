import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getJobPostingByProjectId, updateProjectForClient } from '../../api/projects';
import { JobPosting, UpdateProjectDTO } from '../../api/projects.dto';
import { useWithMessage } from '../Error';

type Params = {
    selectedProjectId: string;
}

interface Return {
    jobPosting?: JobPosting;
    loading: boolean;
    isReleasedByClientButton: boolean;
    changeIsReleased: () => void;
}

const useJobPosting = (): Return => {
    const [jobPosting, setJobPosting] = useState<JobPosting>();
    const [loading, setLoading] = useState<boolean>(true);
    const { selectedProjectId } = useParams<Params>();
    const [isReleasedByClientButton, setIsReleasedByClientButton] = useState<boolean>(false);
    const withMessage = useWithMessage();

    const loadJobPosting = useCallback(async () => {
        const jobPostingRes = await getJobPostingByProjectId(selectedProjectId || "");
        if ( jobPostingRes?.isReleasedByClient !== undefined ) {
            setIsReleasedByClientButton(jobPostingRes.isReleasedByClient);
        }
        setJobPosting(jobPostingRes);
        setLoading(false);
    }, [selectedProjectId]);

    const changeIsReleased = useCallback(
        async () => {
            setLoading(true);
            const updateData: UpdateProjectDTO = {
                jobPosting: {
                    isReleasedByClient: !isReleasedByClientButton
                }
            };
            await updateProjectForClient(selectedProjectId || "", updateData).catch(withMessage());
            setIsReleasedByClientButton(!isReleasedByClientButton);
            setLoading(false);
        }, [isReleasedByClientButton, selectedProjectId, withMessage]
    );

    useEffect(() => {
        loadJobPosting();

        return () => {
            setJobPosting(undefined);
        };
    }, [loadJobPosting]);

    return {
        jobPosting,
        loading,
        isReleasedByClientButton,
        changeIsReleased
    };
};

export default useJobPosting;