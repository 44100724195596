export default {
  rating: 'Bewertung:',
  noRating: 'Keine Bewertung vorhanden',
  consultantComment: 'Kommentar des Beraters:',
  noComment: 'Kein Kommentar vorhanden',
  save: 'Speichern',
  download: 'Herunterladen',
  noFurtherProceeding: 'Kein weiteres Vorgehen angegeben',
  commentCustomer: 'Kommentar des Kunden:',
  furtherProceeding: 'Weiteres Vorgehen:',
  furtherProceedingTypes: {
    INVITE: 'Einladen',
    ON_HOLD: 'On Hold',
    DECLINE: 'Absagen',
  },
};
