import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { getProjectList } from '../../../api/projects';
import { ProjectsDTO } from '../../../api/projects.dto';
import { useWithMessage } from '../../Error';

function mapProjectList(projectList: ProjectsDTO[]) {
  const mappedProjectList: ProjectsDTO[] = [];
  projectList.forEach(project => mappedProjectList.push({
    isReleasedByClient: false,
    // eslint-disable-next-line no-underscore-dangle
    _id: project._id,
    projectNumber: project.projectNumber,
    title: project.title || project.jobTitle1,
    jobTitle1: project.jobTitle1,
    tabShortcuts : {
      hasLongList: project.tabShortcuts ? project.tabShortcuts.hasLongList : false,
      hasShortList: project.tabShortcuts ? project.tabShortcuts.hasShortList : false,
      hasLatecomer: project.tabShortcuts ? project.tabShortcuts.hasLatecomer : false
    }
  }));
  return mappedProjectList;
}

export const useProjectList = (): {
  projects: ProjectsDTO[];
  refreshProjects: () => void;
  setCustomerId: Dispatch<SetStateAction<string>>;
  selectedCustomerId: string;
} => {
  const [projects, setProjects] = useState<ProjectsDTO[]>([]);
  const [selectedCustomerId, setCustomerId] = useState<string>('');

  const withMessage = useWithMessage();

  const loadProjects = useCallback(async () => {
    const { projects: projectList } = await getProjectList({selectedCustomerId});
    setProjects(mapProjectList(projectList));
  }, [selectedCustomerId]);

  useEffect(() => {
    loadProjects().catch(withMessage());
  }, [loadProjects, withMessage]);

  return {
    projects,
    refreshProjects: loadProjects,
    setCustomerId,
    selectedCustomerId
  };
};
