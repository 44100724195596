import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
  },
});

interface Props {
  title: string
}

const Title = ({title}:Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography className={classes.title} color="primary" variant="h6">
      {title}
    </Typography>
  );
};

export default Title;
