export default {
  selectCandidatesForDossierDownload:
    'Kandidaten für Dossierdownload auswählen',
  databaseTextLabels: {
    body: {
      noMatch: 'Keine passenden Einträge gefunden',
      toolTip: 'Sortieren',
    },
    pagination: {
      next: 'Nächste Seite',
      previous: 'Vorherige Seite',
      rowsPerPage: 'Zeilen pro Seite:',
      displayRows: 'von',
    },
    toolbar: {
      search: 'Suche',
      viewColumns: 'Spalten',
      filterTable: 'Tabelle filtern',
    },
    filter: {
      all: 'Alle',
      title: 'Filter',
      reset: 'Zurücksetzten',
    },
    viewColumns: {
      title: 'Spalten Anzeigen',
      titleAria: 'Spalten anzeigen/ausblenden',
    },
    selectedRows: {
      text: 'Zeilen ausgewählt',
      delete: 'Löschen',
      deleteAria: 'Ausgewählte Zeilen löschen',
    },
  },
};
