const TOKEN_KEY = 'token';
const CREDENTIALS_FLUSH = 'CREDENTIALS_FLUSH';
const REQUESTING_SHARED_CREDENTIALS = 'REQUESTING_SHARED_CREDENTIALS';
const CREDENTIALS_SHARING = 'CREDENTIALS_SHARING';

export const readToken = (): string | null => window.sessionStorage.getItem(TOKEN_KEY);

export const storeToken = (token: string ):void  => window.sessionStorage.setItem(TOKEN_KEY, token);

export const clearToken = ():void => window.sessionStorage.removeItem(TOKEN_KEY);

// callback is called when a token becomes available, or after an arbitrarily chosen delay
export const syncStoredToken= (callback: () => any): void => {
  let timeout: number;
  window.addEventListener('storage', event => {
    const credentials = readToken();
    if (event.key === REQUESTING_SHARED_CREDENTIALS && credentials) {
      window.localStorage.setItem(CREDENTIALS_SHARING, credentials);
      window.localStorage.removeItem(CREDENTIALS_SHARING);
    }
    if (event.key === CREDENTIALS_SHARING && !credentials) {
      if(event.newValue !== null) {
        storeToken(event.newValue);
        window.clearTimeout(timeout);
      }
      callback();
    }
    if (event.key === CREDENTIALS_FLUSH && credentials) {
      clearToken();
    }
  });

  if (readToken()) {
    callback();
  } else {
    timeout = window.setTimeout(callback, 100);
    window.localStorage.setItem(
      REQUESTING_SHARED_CREDENTIALS,
      Date.now().toString()
    );
    window.localStorage.removeItem(REQUESTING_SHARED_CREDENTIALS);
  }
};

export const clearStoredToken = (): void => {
  window.localStorage.setItem(CREDENTIALS_FLUSH, Date.now().toString());
  window.localStorage.removeItem(CREDENTIALS_FLUSH);
  clearToken();
};
