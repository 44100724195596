import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Button, Snackbar } from '@mui/material';

import { CandidateDTO } from '../../../../api/projects.dto';
import { useAppAbility } from '../../../../casl';
import { DOWNLOAD_TABLE_SELECTION } from '../../../../casl/actions';
import { DOSSIER } from '../../../../casl/subjects';
import DataTable from '../../../../components/DataTable';
import { SelectedRowI } from '../../../../components/DataTable/DataTable';
import TableType from '../../../../enums/TableType';
import { useWithMessage } from '../../../Error';
import useInformations from "../../RequirementsProfile";
import usePotentialCandidateTables, { downloadDossier } from './hooks';
import { InterviewDialogProvider } from './InterviewDialog';
import { getClientListExcel } from '../../../../api/excelExport';
import ListType from '../../../../enums/ListTypes';

interface Props {
  tableType: TableType;
  selectedCustomerId: string;
}

type Params = {
  selectedProjectId: string;
  selectedTabId: ListType;
}

const Table = ({ tableType, selectedCustomerId }: Props): JSX.Element => {
  const { columns, data, title } = usePotentialCandidateTables(tableType);
  const { selectedProjectId, selectedTabId } = useParams<Params>();
  const { informations } = useInformations({selectedCustomerId});
  const { t } = useTranslation('table');
  const ability = useAppAbility();
  const withMessage = useWithMessage();
  const [showAlert, setShowAlert] = useState(false);

  const [selectedRows, setSelectedRows] = useState<SelectedRowI[]>([]);

  const handleNoDossiersAvailable = () => {
    setShowAlert(true);
  };

  const handleDossierDownload = () => {
    const selectedCandidates: CandidateDTO[] = [];
    selectedRows.forEach((selectedRow) => {
      selectedCandidates.push(data[selectedRow.dataIndex] as CandidateDTO);
    });
    downloadDossier(
      selectedCandidates,
      tableType,
      selectedProjectId || "",
      informations?.projectNumber,
      withMessage,
      handleNoDossiersAvailable
    );
  };

  const handleGetExcelList = useCallback(async () => {
    if (!selectedTabId || !selectedProjectId) {
      return;
    }
      const res = await getClientListExcel({listType: selectedTabId, projectId: selectedProjectId})

      if (res !== undefined) {
        const fileName = `Auftragsexport_Kunde.xlsx`;
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(new Blob([res as any]));
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
  }, [selectedProjectId, selectedTabId])

  const onSelectCandidate = (
    _: SelectedRowI[],
    allRowsSelected: SelectedRowI[]
  ) => {
    setSelectedRows(allRowsSelected);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <InterviewDialogProvider>
      <DataTable
        columns={columns}
        data={data}
        title={title}
        selectableRows={
          ability.can(DOWNLOAD_TABLE_SELECTION, DOSSIER) ? 'multiple' : 'none'
        }
        onRowSelectionChange={onSelectCandidate}
      />
      {ability.can(DOWNLOAD_TABLE_SELECTION, DOSSIER) && (
        <Box style={{width: "100%", display: 'flex', justifyContent: 'end'}}>
          <Box marginTop={1}>
            <Button onClick={handleDossierDownload}>
              {t('downloadSelectedDossiers')}
            </Button>
            <Button onClick={handleGetExcelList}>
              {t('downloadProjectExcel')}
            </Button>
          </Box>
          <Snackbar
            open={showAlert}
            onClose={handleClose}
            message={t('noDossiersAvailable')}
          />
        </Box>
      )}
    </InterviewDialogProvider>
  );
};

export default Table;
