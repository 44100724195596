import ListType from '../enums/ListTypes';
import TableType from '../enums/TableType';
import { performPost } from './apiUtils';
import {
  CandidateDossierDTO,
  CandidateDossierSenatorDTO,
  CandidateDTO,
  CommentField,
  GetCustomerListResponseDTO,
  GetProjectByIdForClientDTO,
  JobPosting,
  ProjectsDTO,
  ProjectSingleDocumentDTO,
  UpdatePotentialCandidateDataInterview,
  UpdatePotentialCandidateResponse,
  UpdateProjectDTO,
} from './projects.dto';

export interface CandidateIdAndFilename {
  id: string;
  fileName: string;
}

export interface GetProjectListProps {
  selectedCustomerId?: string;
}

export async function getProjectList(props?: GetProjectListProps): Promise<{
  success: boolean;
  projects: ProjectsDTO[];
}> {
  let payload = {};
  if (props && props.selectedCustomerId) {
    payload = {selectedCustomerId: props.selectedCustomerId};
  }
return performPost('/getAccessibleProjectsByUserId', payload);
}

/**
 * gets potentialCandidates for a specific project
 * @param projectId id of the selected Project
 * @param clickEventLocation only for Logging purpose
 * @returns a list of CandidateDTO
 */
export async function getPotentialCandidatesForProject(
  projectId: string,
  clickEventLocation: ListType
): Promise<CandidateDTO[]> {
  return performPost('/getPotentialCandidatesForProjectClient', {
    projectId,
    clickEventLocation,
  });
}

export async function getPotentialCandidateDossier(
  candidateId: string,
  fileName: string,
  selectedProjectId: string
): Promise<CandidateDossierDTO> {
  return performPost('/getProjectCandidateDossierUrlForClient', {
    candidateId,
    fileName,
    selectedProjectId,
  });
}

export async function downloadPotentialCandidateDossiers(
  candidateIdAndFileNames: CandidateIdAndFilename[],
  projectId: string,
  tableType: TableType
): Promise<any> {
  return performPost(
    '/downloadPotentialCandidateDossiersClient',
    {
      candidateIdAndFileNames,
      projectId,
      tableType,
    },
    {
      responseType: 'arraybuffer',
    }
  );
}

export async function getCustomerList(): Promise<GetCustomerListResponseDTO[]> {
  return performPost('/getCustomerList',{});
}

/**
 *
 * @param fileName
 * @param projectId
 * @param potentialCandidateId
 */
export async function getProjectCandidateAdditionalDocumentById(
  fileName: string,
  projectId: string,
  potentialCandidateId: string
): Promise<CandidateDossierDTO> {
  return performPost('/getProjectCandidateAdditionalDocumentById', {
    fileName,
    projectId,
    potentialCandidateId,
  });
}

export async function getProjectByIdForClient(
  projectId: string,
  selectedCustomerId?: string
): Promise<{ project: GetProjectByIdForClientDTO }> {
  return performPost(`/getProjectByIdForClient`, { projectId, selectedCustomerId });
}

export async function getRequirementsPDF(
  projectId: string
): Promise<{ buffer: string }> {
  return performPost('/getRequirementsDocument', { projectId });
}

export async function updatePotentialCandidates(
  projectId: string,
  potentialCandidateId: string,
  updateData: UpdatePotentialCandidateDataInterview
): Promise<
  { success: boolean; result: UpdatePotentialCandidateResponse[] } | undefined
> {
  return performPost('/updatePotentialCandidateInterview', {
    projectId,
    potentialCandidateId,
    updateData,
  });
}

export async function updatePotentialCandidatesContractProposal(
  projectId: string,
  potentialCandidateId: string,
  updateData: UpdatePotentialCandidateDataInterview
): Promise<
  { success: boolean; result: UpdatePotentialCandidateResponse[] } | undefined
> {
  return performPost('/updatePotentialCandidateContractProposal', {
    projectId,
    potentialCandidateId,
    updateData,
  });
}

export async function updatePotentialCandidateComment(
  projectId: string,
  candidateId: string,
  updateData: { field: CommentField; comment: string }
): Promise<{ success: boolean } | undefined> {
  return performPost('/updatePotentialCandidateCommentClient', {
    projectId,
    candidateId,
    updateData,
  });
}

/**
 * updateProjectForClient checks if the currently Logged In User has Access to the Project
 * and only allows to update it if that's the case.
 * Note: only some fields can be updated with this call.
 */
export async function updateProjectForClient(
  projectId: string,
  updateData: UpdateProjectDTO
): Promise<{ status: 'success' }> {
  return performPost(`/updateProjectForClient`, { projectId, updateData });
}
export async function getJobPostingByProjectId(
  projectId: string
): Promise<JobPosting> {
  return performPost(`/getJobPostingByProjectId`, { projectId });
}
export async function getProjectDocumentById(
  documentId: string,
  fileName: string,
  projectId: string,
  selectedCustomerId?: string
): Promise<ProjectSingleDocumentDTO> {
  return performPost('/getProjectDocumentByIdForClient', {
    documentId,
    fileName,
    projectId,
    selectedCustomerId
  });
}

export async function getProjectStatusReport(projectId: string): Promise<any> {
  return performPost('/getProjectStatusReportForClient', { projectId });
}

export async function getPotentialCandidateDossierSenator(
  projectId: string,
  candidateId: string
): Promise<CandidateDossierSenatorDTO> {
  return performPost(`/getCandidateDossierForSenatorClient`, {
    projectId,
    candidateId,
  });
}
