export default {
  interviewDialog: {
    header: 'Terminvorschläge eintragen',
    saveButton: 'Speichern',
    closeButton: 'Abbrechen',
  },
  downloadSelectedDossiers: 'Ausgewählte Dossiers herunterladen',
  downloadProjectExcel: 'Liste in Excel exportieren',
  noDossiersAvailable:
    'Sie haben keinen Kandidaten ausgewählt für den ein Dossier verfügbar ist',
};
