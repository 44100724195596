import { Grid, TextField as MUITextField } from '@mui/material';

interface Props {
  label: string;
  fullWidth?: boolean;
  value?: string;
}

const TextField = ({ label, fullWidth, value }: Props): JSX.Element => (
  <Grid item md={fullWidth ? 12 : 6} xs={12}>
    <MUITextField
      disabled={!value}
      fullWidth
      InputProps={{ readOnly: true }}
      label={label}
      size="small"
      value={value || ''}
      multiline
      variant='standard'
    />
  </Grid>
);
export default TextField;
