import { partner } from './constants';

const GeneralSettings: any = {
  fontSize: '16px'
};

const SenatorColors: any = {
  default: {
    primary: {
      light: '#0079b0',
      main: '#0079b0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00a69f',
    },
    background: {
      default: '#fafafa'
    },
  },
  white: '#fff',
  loginBackgroundColor: '#747e86',
  appBarBackground: 'rgb(229, 229, 224)',
  appBarTitle: '#666',
};

const LienertColors: any = {
  default: {
    primary: {
      light: '#697a97',
      main: '#092455',
      contrastText: '#fff',
    },
    secondary: {
      main: '#697a97',
    },
    background: {
      default: '#fff'
    },
  },
  dark: {
    type: 'dark',
    primary: {
      light: '#fff',
      main: '#092455',
      contrastText: '#fff'
    }
  },
  white: '#fff',
  loginPrimary: {
    main: '#962225'
  },
  loginPanelBackgroundColor: '#092455',
  loginBackgroundColor: '#dcdcdc',
  
};

const PersonalTotalColors: any = {
  default: {
    primary: {
      light: '#fff',
      main: '#962225',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00a69f',
    },
    background: {
      default: '#fafafa'
    },
  },
  white: '#fff',
  loginBackgroundColor: 'rgb(150, 34, 37)',
  appBarBackground: '#671314',
};

const ComponentOverrides: any = {
    MuiTooltip: {
      tooltip: {
        'font-size': GeneralSettings.fontSize,
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        flex: 'none',
      },
    },
    MUIDataTable: {
      responsiveBase: {
        flexGrow: '1',
      },
    },
};

const THEME: any = {
  senator: {
    name: 'Senator-Partners',
    // login page
    login: {
      palette: {
        primary: SenatorColors.default.primary,
        secondary: SenatorColors.default.secondary,
        background: {
          background: {
            default: SenatorColors.default.background.default,
            paper: SenatorColors.loginBackgroundColor
          },
        },
      },
    },
    // main view
    app: {
      palette: SenatorColors.default,
      overrides: ComponentOverrides,
    },
    // images no real theme setting
    logo: '/img/senator_logo.png',
    loginLogo: '/img/senator_logo.png',
    // box with the login form no real theme setting
    appBarTitle: SenatorColors.appBarTitle,
    loginPaper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    appBar: {
      palette: {
        primary: {
          light: SenatorColors.default.primary.light,
          main: SenatorColors.appBarBackground,
        },
        secondary: SenatorColors.default.secondary
      },
    },
    appBarReport:{
      palette:{
        primary: {
          light: '#697a97',
          main: '#000',
          contrastText: '#fff',
        },
      }
    }
  },
  lienert: {
    name: 'Jörg Lienert AG',
    login: {
      typography: {
        useNextVariants: true,
      },
      palette: {
        type: 'dark',
        primary: LienertColors.loginPrimary,
        background: {
          default: LienertColors.default.background.default,
          paper: LienertColors.loginPanelBackgroundColor
        },
      },
    },
    // box with the login form no real theme setting
    loginPaper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    appBar: {
      palette: LienertColors.dark,
    },
    app: {
      typography: {
        useNextVariants: true,
      },
      palette: LienertColors.default,
      overrides: ComponentOverrides,
    },
    logo: '/img/JL_Logo_rgb_weiss.svg',
    loginLogo: '/img/JL_Logo_rgb_weiss.svg',
    loginLogoStyle: {
      maxWidth: '90%',
      minWidth: "400px",
      padding: '10px'
    },
    homePageLogoStyle: {
      height: '35px',
      padding: '2px'
    },
  },
  personaltotal: {
    name: 'Personal-Total',
    // login page
    login: {
      palette: {
        background: {
          default: PersonalTotalColors.default.background.default,
          paper: PersonalTotalColors.loginBackgroundColor
        },
      },
    },
    // main view
    app: {
      palette: PersonalTotalColors.default,
      overrides: ComponentOverrides,
    },
    // images no real theme setting
    logo: '/img/pt_logo.png',
    loginLogo: '/img/pt_logo.png',
    // box with the login form no real theme setting
    appBarTitle: PersonalTotalColors.appBarTitle,
    loginPaper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    appBar: {
      palette: {
        primary: {
          light: PersonalTotalColors.default.primary.light,
          main: PersonalTotalColors.appBarBackground,
        },
        secondary: PersonalTotalColors.default.secondary
      },
    },
    appBarReport:{
      palette:{
        primary: {
          light: '#697a97',
          main: '#000',
          contrastText: '#fff',
        },
      }
    }
  },
};

export default THEME[partner];
