import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, ButtonGroup } from '@mui/material';

import { updatePotentialCandidates } from '../../../../../../api/projects';
import { usePotentialCandidatesContext } from '../../../hooks';
import { useInterviewDialog } from '../../InterviewDialog';

interface Props {
  id: string;
  isSecondInterview: boolean;
}

const Interview = ({ id, isSecondInterview }: Props): JSX.Element => {
  // read candidate data from context
  const { potentialCandidates, refreshCandidates } =
    usePotentialCandidatesContext();
  const candidate = potentialCandidates.find((c) => c.candidateId === id);

  let interviewAccepted;
  if (!isSecondInterview) {
    interviewAccepted = candidate?.clientAcceptsFirstInterview;
  } else {
    interviewAccepted = candidate?.clientAcceptsSecondInterview;
  }

  const [acceptButtonActive, setAcceptButtonActive] = useState(
    interviewAccepted === true
  );
  const [declineButtonActive, setDeclineButtonActive] = useState(
    interviewAccepted === false
  );

  const { selectedProjectId } = useParams<{ selectedProjectId?: string }>();

  const handleOpenDialog = useInterviewDialog();

  const handleSetAcceptActive = useCallback(() => {
    handleOpenDialog({
      text: isSecondInterview
        ? candidate?.secondInterviewText || ''
        : candidate?.firstInterviewText || '',
      onSuccess: (interviewText) => {
        setAcceptButtonActive(true);
        setDeclineButtonActive(false);
        if (selectedProjectId) {
          updatePotentialCandidates(selectedProjectId, id, {
            clientAcceptsFirstInterview: isSecondInterview
              ? candidate?.clientAcceptsFirstInterview
              : true,
            firstInterviewText: isSecondInterview
              ? candidate?.firstInterviewText
              : interviewText,
            clientAcceptsSecondInterview: isSecondInterview ? true : undefined,
            secondInterviewText: isSecondInterview ? interviewText : undefined,
          }).then(() => {
            refreshCandidates();
          });
        }
      },
    });
  }, [
    handleOpenDialog,
    isSecondInterview,
    candidate,
    id,
    selectedProjectId,
    refreshCandidates,
  ]);

  const handleSetDeclineActive = useCallback(async () => {
    const projectId = selectedProjectId;
    if (!projectId) {
      throw new Error('No projectId');
    }
    await updatePotentialCandidates(projectId, id, {
      firstInterviewText: isSecondInterview
        ? candidate?.firstInterviewText
        : '',
      clientAcceptsFirstInterview: isSecondInterview
        ? candidate?.clientAcceptsFirstInterview
        : false,
      secondInterviewText: isSecondInterview ? '' : undefined,
      clientAcceptsSecondInterview: isSecondInterview ? false : undefined,
    });
    refreshCandidates();
    if (isSecondInterview) {
      setAcceptButtonActive(false);
      setDeclineButtonActive(!!candidate?.clientAcceptsFirstInterview);
    } else {
      setAcceptButtonActive(false);
      setDeclineButtonActive(true);
    }
  }, [id, selectedProjectId, candidate, isSecondInterview, refreshCandidates]);
  return (
    <ButtonGroup
      disabled={isSecondInterview && !candidate?.clientAcceptsFirstInterview}
    >
      <Button
        variant={acceptButtonActive ? 'contained' : 'outlined'}
        onClick={handleSetAcceptActive}
      >
        Annehmen
      </Button>
      <Button
        variant={declineButtonActive ? 'contained' : 'outlined'}
        onClick={handleSetDeclineActive}
      >
        Ablehnen
      </Button>
    </ButtonGroup>
  );
};

export default Interview;
