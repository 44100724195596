import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProjectByIdForClient, getRequirementsPDF } from '../../../api/projects';
import { BookingOptions, ConsultingUser, CustomerInformation } from '../../../api/projects.dto';

export interface Informations {
  customerInformations?: CustomerInformation;
  bookingOptions?: BookingOptions;
  projectNumber?: string;
  creationDate?: string;
  status?: string;
  systemPartner?: string;
  serviceType?: string;
  clientCommentLongList?: string;
  clientCommentShortList?: string;
  title?: string;
  jobTitle1?: string;
  consultingUser?: ConsultingUser;
  companyName?: string;
  picture?: Buffer;
}

interface Return {
  informations?: Informations;
  refreshInformations: () => void;
  loading: boolean;
  generatePDF: () => Promise<string>;
}
type Params = {
  selectedProjectId?: string;
}

interface UseInformationsProps {
  selectedCustomerId: string;
}

const useInformations = ({ selectedCustomerId }: UseInformationsProps): Return => {
  const [informations, setInformations] = useState<Informations>();
  const [loading, setLoading] = useState(true);

  const { selectedProjectId }: Params = useParams();

  const loadInformations = useCallback(async () => {
    if (!selectedProjectId) {
      return;
    }
    const { project } = await getProjectByIdForClient(selectedProjectId, selectedCustomerId).then(
      (res) => {
        setLoading(false);
        return res;
      }
    );

    if (!project) {
      return;
    }

    setInformations({
      customerInformations: project.customerInformation,
      bookingOptions: project.bookingOptions,
      projectNumber: project.projectNumber,
      creationDate: project.creationDate,
      status: project.status,
      systemPartner: project.systemPartner,
      serviceType: project.serviceType,
      clientCommentLongList: project.clientCommentLongList,
      clientCommentShortList: project.clientCommentShortList,
      title: project.jobPosting?.title,
      jobTitle1: project.jobTitle1,
      consultingUser: project.consultingUser,
      companyName: project.companyName,
      picture: project.picture
    });
  }, [selectedCustomerId, selectedProjectId]);

  const generatePDF = useCallback(async () => {
    if (!selectedProjectId) {
      return "";
    }
    setLoading(true);
    try {
      const base64String = await getRequirementsPDF(selectedProjectId);
      setLoading(false);
      return `data:application/pdf;base64,${base64String.buffer}`;
    } catch (e) {
      setLoading(false);
      throw new Error("Leeres Dokument kann nicht generiert werden");
    }
  }, [selectedProjectId]);

  useEffect(() => {
    loadInformations();

    return () => {
      setInformations(undefined);
    };
  }, [loadInformations]);

  return {
    informations,
    refreshInformations: loadInformations,
    loading,
    generatePDF,
  };
};

export default useInformations;
