
import { useNavigate } from 'react-router-dom';
import { updatePotentialCandidateComment } from '../../../api/projects';
import { CommentType } from '../CandidatesList/Table/Columns/Renderers/Comment/hooks';

interface ReturnI {
  updateClientComment: (
    selectedProjectId: string,
    candidateId: string,
    comment: string,
    commentType: CommentType,

  ) => Promise<void>;
}

const useLienertDossier = (): ReturnI => {
  const navigate = useNavigate();

  const updateClientComment = async (
    selectedProjectId: string,
    candidateId: string,
    comment: string,
    commentType: CommentType,
  ) => {
    const updateObject = {
      field: commentType,
      comment,
    };
    await updatePotentialCandidateComment(
      selectedProjectId,
      candidateId,
      updateObject
    ).catch(() => {
      navigate('/err');
    });
  };

  return { updateClientComment };
};

export default useLienertDossier;
