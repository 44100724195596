import {
  Box,
  Button,
  createTheme,
  IconButton,
  AppBar as MuiAppBar,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import { ManageAccounts } from '@mui/icons-material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectsDTO } from '../../api/projects.dto';
import useGlobalState from '../../globalHook';
import { useLoginContext } from '../../modules/Auth';
import THEME from '../../theme';
import IconButtons, { ClickableIcon } from '../IconButtons';
import ChangeUserConfirmDialog from './ChangeUserConfirmDialog';

const useStyles = makeStyles({
  appBar: {
    maxHeight: '64px',
  },
  title: {
    flexGrow: 2,
    color: THEME.appBarTitle,
  },
  logo: {
    maxHeight: 64,
    minHeight: 28,
    maxWidth: 170,
  },
});

interface AppBarProps {
  title: string;
  clickableIcons?: ClickableIcon[];
  additionalTheme?: Record<string, any>;
  setCustomerId?: Dispatch<SetStateAction<string>>;
  projects?: ProjectsDTO[];
}
const theme = createTheme(THEME.appBar);

const AppBar = ({
  title,
  clickableIcons,
  additionalTheme,
  setCustomerId,
  projects,
}: AppBarProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');

  const navigate = useNavigate();
  const { user } = useLoginContext();
  const isUserAdmin = user?.role === 'admin';
  const { t } = useTranslation('singleProject');
  const { customerList } = useGlobalState(isUserAdmin);

  const classes = useStyles();

  const handleClose = (agree: boolean) => {
    if (agree && setCustomerId) {
      setCustomerId(selectedCustomerId);
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  if (isInitial && isUserAdmin && (!projects || projects.length === 0)) {
    setOpen(true);
    setIsInitial(false);
  }

  return (
    <ThemeProvider theme={additionalTheme || theme}>
      <MuiAppBar className={classes.appBar} position="static">
        <Toolbar>
          <Box ml={1} mr={3}>
            <Button onClick={() => navigate('/projects')}>
              <img alt="Logo" className={classes.logo} src={THEME.logo} style={THEME.homePageLogoStyle} />
            </Button>
          </Box>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          {isUserAdmin && (
            <Box ml={5}>
              <IconButton
                key="chooseClient"
                onClick={handleClick}
                color="inherit"
              >
                <ManageAccounts />
              </IconButton>
            </Box>
          )}
          {clickableIcons && (
            <Box ml={5}>
              <IconButtons icons={clickableIcons} />
            </Box>
          )}
        </Toolbar>
      </MuiAppBar>
      {isUserAdmin && (
        <ChangeUserConfirmDialog
          onClose={handleClose}
          title={t('confirmTitle')}
          text={t('confirmText')}
          cancelText={t('confirmCancel')}
          okText={t('confirmRelease')}
          open={open}
          customerList={customerList}
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
        />
      )}
    </ThemeProvider>
  );
};

export default AppBar;
