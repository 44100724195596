import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import useDocumentDialog from "../../../../components/DocumentDialog/DocumentDialog";
import { useWithMessage } from '../../../Error';
import {Informations} from "../../RequirementsProfile/hooks";
import useReport from "../hooks";

type Params = {
    selectedProjectId?: string;
}

const useStyles = makeStyles({
    div: {
      width: '100%',
      padding: '10px, 20px',
      textAlign: 'center'
    },
    loadingDiv: {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 10
    }
});

interface Props {
    informations?: Informations;
  }

const ReportButton = ({informations}:Props): JSX.Element=> {
    const classes = useStyles();

    const { selectedProjectId }: Params = useParams();

    const { t } = useTranslation('statusReport');

    const [ loading, setLoading ] = useState(false);
    const { loadStatusReport } = useReport(selectedProjectId || "");
    const { openDialog, DocumentDialog } = useDocumentDialog();
    const withMessage = useWithMessage();

    const handleButtonClick = async () => {
        setLoading(true);
        const fileString = await loadStatusReport().catch(withMessage());
        const projectNumber = informations?.projectNumber ? `_${informations.projectNumber}`: '';
        const jobTitle1 = informations?.jobTitle1 ? `_${informations.jobTitle1}` : '';
        openDialog(fileString || '', `Statusreport${projectNumber}${jobTitle1}.pdf`, true);
        setLoading(false);
    };

    return (
        <>  
            {loading &&
                <div className={classes.loadingDiv}>
                    <div>
                        <CircularProgress color="secondary" size={60} />
                    </div>
                </div>
            }
            <div>
                <div className={classes.div}>
                    <CloudDownloadIcon
                        color="primary"
                        fontSize="large"
                        className="mt-2"
                    />
                </div>
                <div className={classes.div}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleButtonClick}
                    >
                    {t('showStatusReport')}
                    </Button>
                </div>
            </div>
            {DocumentDialog}
        </>
    );
};

export default ReportButton;
