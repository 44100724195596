import React, { memo } from "react";

import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  SelectProps,
} from "@mui/material";
import { FormikProps } from "formik";

import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { mapEnumToOptions } from "./utils";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  name: string;
  label: string;
  options: Record<number, string>;
  defaultText: string;
}

const Select: React.FC<SelectProps & Props> = memo(
  ({ formik, name, label, options, defaultText, ...rest }) => {
    const handleChange: SelectInputProps['onChange'] = (e) => {
      const target = e.target as HTMLSelectElement;
      formik.setFieldValue(name, target.value);
    };
    const mappedOptions = mapEnumToOptions(options);
    return (
      <FormControl fullWidth size="small" variant="outlined">
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <MaterialSelect
          label={label}
          native
          onChange={handleChange}
          value={formik.values[name]}
          {...rest}
        >
          <option value={0}>{defaultText}</option>
          {mappedOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </MaterialSelect>
      </FormControl>
    );
  }
);

Select.displayName = "Select";

export default Select;
