import { MUIDataTableColumnDef } from 'mui-datatables';
import { Dispatch, SetStateAction } from 'react';
import { GetCustomerListResponseDTO } from '../../api/projects.dto';
import DataTable from '../DataTable/DataTable';

interface ChangeUserProps {
  customerList: GetCustomerListResponseDTO[];
  setSelectedCustomerId: Dispatch<SetStateAction<string>>;
  selectedCustomerId: string;
}

const ChangeUser = ({
  customerList,
  setSelectedCustomerId,
  selectedCustomerId,
}: ChangeUserProps): JSX.Element => {
  const onClick = (value: string) => {
    setSelectedCustomerId(value);
  };

  const idColumn: MUIDataTableColumnDef = {
    name: 'id',
    options: { display: 'excluded' },
  };

  const nameColumn: MUIDataTableColumnDef = {
    label: 'Vorname',
    name: 'firstname',
  };
  const lastNameColumn: MUIDataTableColumnDef = {
    label: 'Nachname',
    name: 'lastname',
  };
  const emailColumn: MUIDataTableColumnDef = {
    label: 'Email',
    name: 'email',
  };
  return (
    <div>
      <DataTable
        setRowProps={(row) => {
          if (row[0] === selectedCustomerId) {
            return { style: { background: 'lightgrey', cursor: 'pointer' } };
          }
          return { style: { cursor: 'pointer' } };
        }}
        onRowClick={(row) => onClick(row[0])}
        columns={[idColumn, nameColumn, lastNameColumn, emailColumn]}
        data={customerList.map((c) => [c.id, c.firstname, c.lastname, c.email])}
        title=""
        elevation={0}
      />
    </div>
  );
};
export default ChangeUser;
