import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Switch from '@mui/material/Switch';

import { performPost } from '../../../../../../api/apiUtils';

interface Props {
  checked: boolean;
  id: string;
}

export const ContractProposalSwitch = ({ checked, id }: Props): JSX.Element => {
  const [isChecked, setChecked] = useState(checked);
  const { selectedProjectId } = useParams<{ selectedProjectId?: string }>();
  const updateProposal = useCallback(
    (checkedState: boolean) => {
      performPost('/clientWishesContractProposal', {
        projectId: selectedProjectId,
        potentialCandidateId: id,
        updateData: { clientWishesContractProposal: checkedState },
      });
    },
    [selectedProjectId, id]
  );

  return (
    <Switch
      checked={isChecked}
      onChange={() => {
        updateProposal(!isChecked);
        setChecked(!isChecked);
      }}
    />
  );
};
