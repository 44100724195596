import { MUIDataTableColumnDef } from 'mui-datatables';

import {
  CandidateIdAndFilename,
  downloadPotentialCandidateDossiers,
} from '../../../../api/projects';
import { CandidateDTO } from '../../../../api/projects.dto';
import TableType from '../../../../enums/TableType';
import TEXT from '../../../../text';
import { ErrorCatcher } from '../../../Error/MessageError';
import { usePotentialCandidatesContext } from '../hooks';
import {
  useColumns,
} from './Columns';

export const downloadDossier = async (
  selectedCandidates: CandidateDTO[],
  tableType: TableType,
  projectId: string,
  projectNumber: string | undefined,
  withMessage: (catchers?: ErrorCatcher[] | undefined) => (err: Error) => void,
  handleNoDossiersAvailable: () => void
): Promise<void> => {
  const candidateIdAndFileNames: CandidateIdAndFilename[] = [];
  selectedCandidates.forEach((sC) => {
    if (
      (tableType === TableType.LONG_LIST ||
        tableType === TableType.LATECOMER_LIST) &&
      sC.dossierLienertLongList &&
      sC.dossierLienertLongList.length > 0
    ) {
      const { fileName } =
        sC.dossierLienertLongList[sC.dossierLienertLongList.length - 1];
      candidateIdAndFileNames.push({ id: sC.candidateId, fileName });
    }
    if (
      tableType === TableType.SHORT_LIST &&
      sC.dossierLienertShortList &&
      sC.dossierLienertShortList.length > 0
    ) {
      const { fileName } =
        sC.dossierLienertShortList[sC.dossierLienertShortList.length - 1];
      candidateIdAndFileNames.push({ id: sC.candidateId, fileName });
    }
  });
  if (candidateIdAndFileNames.length > 0) {
    const res = await downloadPotentialCandidateDossiers(
      candidateIdAndFileNames,
      projectId,
      tableType
    ).catch(withMessage());
    if (res) {
      const fileName = projectNumber ? `Dossier_${projectNumber}.zip` : `Dossier.zip`;
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(new Blob([res]));
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  } else {
    handleNoDossiersAvailable();
  }
};

const usePotentialCandidateTables = (
  tableType: TableType
): {
  columns: MUIDataTableColumnDef[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: (object | string[] | number[])[];
  title: string;
} => {
  const {
    potentialCandidates,
    potentialCandidatesLatecomer,
    potentialCandidatesShortlist,
  } = usePotentialCandidatesContext();

  const sortList = (pC: CandidateDTO[]) => {
    pC.sort((a, b) => {
      if (!a.rating && b.rating) return -1;
      if (a.rating && !b.rating) return 1;
      if (a.rating && b.rating && a.rating < b.rating) return -1;
      if (a.rating && b.rating && a.rating > b.rating) return 1;
      if (a.lastName && b.lastName && a.lastName < b.lastName) return -1;
      if (a.lastName && b.lastName && a.lastName > b.lastName) return 1;
      if (a.firstName && b.firstName && a.firstName < b.firstName) return -1;
      if (a.firstName && b.firstName && a.firstName > b.firstName) return 1;
      return 0;
    });
    return pC;
  };

  const {
    receivedApplications,
    longList,
    shortList,
    dynamicSpecialList,
    presentedCandidates,
    latecomer,
  } = TEXT.projectDetailPage.tabsHeader;

  const columns = useColumns(tableType);

  switch (tableType) {
    case TableType.RECEIVED_APPLICATIONS:
      return {
        columns,
        data: potentialCandidates,
        title: receivedApplications,
      };

    case TableType.LONG_LIST:
      return {
        columns,
        data: sortList(potentialCandidates),
        title: longList,
      };

    case TableType.LATECOMER_LIST:
      return {
        columns,
        data: sortList(potentialCandidatesLatecomer),
        title: latecomer,
      };

    case TableType.SHORT_LIST:
      return {
        columns,
        data: sortList(potentialCandidatesShortlist),
        title: shortList,
      };

    case TableType.DYNAMIC_SPECIAL_LIST:
      return {
        columns,
        data: potentialCandidates,
        title: dynamicSpecialList,
      };

    case TableType.CANDIDATE_DOSSIER_LIST:
      return {
        columns,
        data: potentialCandidates,
        title: presentedCandidates,
      };

    default:
      return {
        columns: [],
        data: [],
        title: '',
      };
  }
};

export default usePotentialCandidateTables;
