import { createContext, useContext } from 'react';

import { Ability, AbilityBuilder } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { partner } from '../constants';

import Partner from '../enums/Partner';
import {
  DOWNLOAD,
  DOWNLOAD_TABLE_SELECTION,
  EDIT,
  PRINT,
  SEE
} from './actions';
import {
  ALL,
  COMPANY_DETAILS,
  COMPANY_LOGO,
  DOCUMENTS,
  DOSSIER,
  DYNAMIC_SPECIAL_LIST,
  IS_ONLINE,
  JOB_AD,
  LATECOMER_LIST,
  LINKS,
  LONG_LIST,
  NOTIFICATIONS,
  REPORT,
  SHORT_LIST,
  SINGLE_PROJECT_INFORMATION
} from './subjects';

export const AbilityContext = createContext<Ability>(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);
export const useAppAbility = (): Ability => useContext(AbilityContext);

interface User {
  access: 'auth';
  additionalPermissions: string[];
  /**
   * ! even tho the company comes from the token its the same as the partner field inside constants
   */
  company: string;
  exp: number;
  iat: number;
  id: string;
  roles: string[];
}

export default function defineAbilitiesFor(token: string): Ability {
  const user: User = JSON.parse(atob(token.split('.')[1]));

  const { rules, can } = new AbilityBuilder(Ability);

  // ! for testing
  // user.company = Partner.SENATOR;

  if (user.company === Partner.LIENERT) {
    can(SEE, SHORT_LIST);
    can(SEE, LATECOMER_LIST);
    can(SEE, LONG_LIST);
    can(SEE, DYNAMIC_SPECIAL_LIST);
    can(DOWNLOAD_TABLE_SELECTION, DOSSIER);
    can(DOWNLOAD, ALL);
  }

  if (
    user.company === Partner.SENATOR ||
    user.company === Partner.PERSONALTOTAL
  ) {
    can(SEE, SINGLE_PROJECT_INFORMATION);
    can(SEE, DOSSIER);
    can(SEE, JOB_AD);
    can(SEE, REPORT);
    can(SEE, DOCUMENTS);
    can(SEE, IS_ONLINE);
    can(EDIT, JOB_AD);
    can(SEE, COMPANY_LOGO);
    can(SEE, COMPANY_DETAILS);
    can(DOWNLOAD, ALL);
    can(PRINT, ALL);
  }

  if (partner === Partner.SENATOR || partner === Partner.PERSONALTOTAL) {
    can(SEE, NOTIFICATIONS);
  }

  if (partner === Partner.SENATOR || partner === Partner.PERSONALTOTAL) {
    can(SEE, LINKS);
  }

  return new Ability(rules);
}
