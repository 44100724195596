import { useTranslation } from 'react-i18next';

import { Box, TableCell, Typography } from '@mui/material';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableViewCol,
  SelectableRows,
  TableViewCol,
} from 'mui-datatables';
import { useAppAbility } from '../../casl';
import { DOWNLOAD, PRINT } from '../../casl/actions';
import { ALL } from '../../casl/subjects';

export interface SelectedRowI {
  index: number;
  dataIndex: number;
}

const ViewCol: React.FC<MUIDataTableViewCol> = (props) => {
  return (
    <Box sx={{ margin: 3 }}>
      <TableViewCol {...props} />
    </Box>
  );
};

const toolbar = (text: string) => {
  return () => (
    <TableCell style={{ border: 'none' }}>
      <Typography>{text}</Typography>
    </TableCell>
  );
};

interface Props {
  columns: MUIDataTableColumnDef[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: (object | string[] | number[] | boolean[])[];
  title: string;
  onRowClick?: (
    rowData: string[],
    rowMeta: { dataIndex: number; rowIndex: number }
  ) => void;
  selectableRows?: SelectableRows;
  onRowSelectionChange?:
    | ((
        currentRowsSelected: SelectedRowI[],
        allRowsSelected: SelectedRowI[],
        rowsSelected?: any[] | undefined
      ) => void)
    | undefined;
  setRowProps?: (row: any[], dataIndex: number, rowIndex: number) => object;
  elevation?: number;
  rowsPerPage?: number;
}

const DataTable = ({
  columns,
  data,
  title,
  onRowClick,
  selectableRows = 'none',
  onRowSelectionChange = () => {},
  setRowProps,
  elevation,
  rowsPerPage,
}: Props): JSX.Element => {
  const ability = useAppAbility();
  const { t } = useTranslation('dataTable');

  return (
    <Box
      sx={{
        '& thead .MuiButton-root': {
          padding: 0,
          textTransform: 'none',
        },
        // Needed So Right Paper Box with Table is atleast same height as left paper box with details
        '& .MuiPaper-root': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        // By Making Paper Flex and direction column the selectionIndicator breaks because it has same css, but it is nested inside paper
        '& .MuiPaper-root .MuiPaper-root': {
          flexDirection: 'row',
          flex: 2,
        },
        height: '100%',
      }}
    >
      <MUIDataTable
        columns={columns}
        data={data}
        options={{
          elevation,
          tableBodyHeight: '100%',
          onRowClick,
          onRowsDelete: () => false,
          onRowSelectionChange,
          selectableRows,
          setRowProps,
          rowsPerPage,
          print: ability.can(PRINT, ALL),
          download: ability.can(DOWNLOAD, ALL),
          textLabels: t('databaseTextLabels', { returnObjects: true }),
          customToolbarSelect: toolbar(t('selectCandidatesForDossierDownload')),
          fixedSelectColumn: false,
        }}
        title={title}
        components={{
          TableViewCol: ViewCol,
        }}
      />
    </Box>
  );
};

export default DataTable;
