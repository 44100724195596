import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ReportButton from '../../../../modules/SingleProject/Report/ReportButton';
import useInformations from "../../../../modules/SingleProject/RequirementsProfile";

const useStyles = makeStyles({
    paper: {
      display: 'flex',
      minHeight: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
});

interface ReportProps {
  selectedCustomerId: string;
}

const Report = ({ selectedCustomerId }: ReportProps): JSX.Element => {
    const {informations} = useInformations({selectedCustomerId});
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <ReportButton informations={informations} />
        </Paper>
    );
};

export default Report;
