import React from 'react';

import {RegisterForm} from '../../modules/Register';

import './Register.css';

const Register = (): JSX.Element => (
    <RegisterForm/>
);

export default Register;
