import { getProjectStatusReport } from '../../../api/projects';

interface Return {
    loadStatusReport: () => Promise<string>;
}

const useReport = (selectedProjectId: string): Return => {

    const loadStatusReport = async () => {
        try {
            const res = await getProjectStatusReport(selectedProjectId);
            return `data:application/pdf;base64,${res.buffer}`;
        } catch (e) {
            throw new Error();
        }
        
    };

    return {
        loadStatusReport
    };
};

export default useReport;