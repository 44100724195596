import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  getPotentialCandidateDossier,
  getPotentialCandidatesForProject,
  getProjectCandidateAdditionalDocumentById,
} from '../../../api/projects';
import { CandidateDossierDTO, CandidateDTO } from '../../../api/projects.dto';
import ListType from '../../../enums/ListTypes';

export enum LienertDossierType {
  longList = 'longList',
  shortList = 'shortList',
  latecomerList = 'latecomerList',
}
interface ReturnData {
  refreshCandidates: () => void;
  potentialCandidates: CandidateDTO[];
  potentialCandidatesLatecomer: CandidateDTO[];
  potentialCandidatesShortlist: CandidateDTO[];
  generateDossier: (
    candidateId: string,
    fileName: string
  ) => Promise<CandidateDossierDTO | undefined>;
  getProjectCandidateAdditionalDocument: (
    fileName: string,
    potentialCandidateId: string
  ) => Promise<CandidateDossierDTO | undefined>;
}

interface PotentialCandidatesListContextI {
  refreshCandidates: () => void;
  potentialCandidates: CandidateDTO[];
  potentialCandidatesLatecomer: CandidateDTO[];
  potentialCandidatesShortlist: CandidateDTO[];
  generateDossier: (
    candidateId: string,
    fileName: string
  ) => Promise<CandidateDossierDTO | undefined>;
  getProjectCandidateAdditionalDocument: (
    fileName: string,
    potentialCandidateId: string
  ) => Promise<CandidateDossierDTO | undefined>;
}

const PotentialCandidatesListContext = createContext<
  PotentialCandidatesListContextI | undefined
>(undefined);

const usePotentialCandidatesList = (): ReturnData => {
  // LIENERT:
  // if you use one state for all candidates and filter the list afterwards the checkboxes for the dossier download don't work
  const [potentialCandidates, setPotentialCandidates] = useState<
    CandidateDTO[]
  >([]);
  const [potentialCandidatesLatecomer, setPotentialCandidatesLatecomer] =
    useState<CandidateDTO[]>([]);
  const [potentialCandidatesShortlist, setPotentialCandidatesShortlist] =
    useState<CandidateDTO[]>([]);

  type Params = {
    selectedProjectId: string;
    selectedTabId: string;
  }

  // selectedProjectId was lost, so first idea was to just save it inside url)
  const { selectedProjectId, selectedTabId } = useParams<Params>();
  const loadPotentialCandidates = useCallback(() => {
    if (!selectedProjectId) {
      return;
    }
    let clickEventLocation: ListType;
    if (selectedTabId) {
      clickEventLocation = selectedTabId as ListType;
    } else {
      clickEventLocation = ListType.longList;
    }
    getPotentialCandidatesForProject(
      selectedProjectId,
      clickEventLocation
    ).then((pC) => {
      setPotentialCandidates(
        pC.filter((candidate) => candidate.showInLongList)
      );
      setPotentialCandidatesLatecomer(
        pC.filter((candidate) => candidate.isLatecomer)
      );
      setPotentialCandidatesShortlist(
        pC.filter((candidate) => candidate.showInShortList)
      );
    });
  }, [selectedProjectId, selectedTabId]);

  useEffect(() => {
    loadPotentialCandidates();
    /**
     * !Important:  Needed to fix Warning:
     *
     * -> Warning: Can't perform a React state update on an unmounted component. This is a no-op,
     * but it indicates a memory leak in your application. To fix, cancel all subscriptions and
     * asynchronous tasks in a useEffect cleanup function
     */
    return () => {
      setPotentialCandidates([]);
      setPotentialCandidatesLatecomer([]);
      setPotentialCandidatesShortlist([]);
    };
  }, [loadPotentialCandidates]);

  const generateDossier = useCallback(
    async (candidateId: string, fileName: string) => {
      const dossier = await getPotentialCandidateDossier(
        candidateId,
        fileName,
        selectedProjectId || ""
      );
      if (dossier) {
        return dossier;
      }
      return undefined;
    },
    [selectedProjectId]
  );
  const getProjectCandidateAdditionalDocument = useCallback(
    async (
      fileName: string,
      potentialCandidateId: string
    ): Promise<CandidateDossierDTO> =>
      // const candidate = potentialCandidates.find((pC) => pC.id === potentialCandidateId);
      //
      // if (!candidate) {
      //   throw new Error('no Candidate found with this id');
      // }
      getProjectCandidateAdditionalDocumentById(
        fileName,
        selectedProjectId || "",
        potentialCandidateId
      ),

    [selectedProjectId]
  );

  return {
    refreshCandidates: loadPotentialCandidates,
    potentialCandidates,
    potentialCandidatesLatecomer,
    potentialCandidatesShortlist,
    generateDossier,
    getProjectCandidateAdditionalDocument,
  };
};

export const CandidatesListProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const potentialCandidateList = usePotentialCandidatesList();
  return (
    <PotentialCandidatesListContext.Provider value={potentialCandidateList}>
      {children}
    </PotentialCandidatesListContext.Provider>
  );
};

export const usePotentialCandidatesContext =
  (): PotentialCandidatesListContextI => {
    const potentialCandidates = useContext(PotentialCandidatesListContext);
    if (potentialCandidates === undefined) {
      throw new Error('candidates not injected');
    }
    return potentialCandidates;
  };
