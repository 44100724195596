enum Subjects {
  SINGLE_PROJECT_INFORMATION = 'singleProjectInfo',
  JOB_AD = 'jobAd',
  DOSSIER = 'dossier',
  REPORT = 'report',
  DOCUMENTS = 'documents',
  LINKS= 'links',
  JOB_POSTING = 'jobPosting',
  RECEIVED_APPLICATIONS = 'receivedApplications',
  LONG_LIST = 'longList',
  SHORT_LIST = 'shortList',
  LATECOMER_LIST = 'latecomerList',
  DYNAMIC_SPECIAL_LIST = 'dynamicSpecialList',
  IS_ONLINE = 'isOnline',
  COMPANY_LOGO = 'companyLogo',
  COMPANY_DETAILS = 'companyDetails',
  NOTIFICATIONS = 'notifications',
  ALL = 'all',
}

/**
 * !Important do not forget to export like this
 * For more fancy usage in Code
 */
export const {
  SINGLE_PROJECT_INFORMATION,
  JOB_AD,
  DOSSIER,
  REPORT,
  DOCUMENTS,
  LINKS,
  RECEIVED_APPLICATIONS,
  LONG_LIST,
  SHORT_LIST,
  LATECOMER_LIST,
  DYNAMIC_SPECIAL_LIST,
  COMPANY_LOGO,
  COMPANY_DETAILS,
  IS_ONLINE,
  NOTIFICATIONS,
  ALL,
} = Subjects;
