import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  GridSize,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CandidateDossierDTO, CandidateDTO } from '../../../api/projects.dto';
import DocumentViewer from '../../../components/DocumentViewer';
import { usePotentialCandidatesContext } from '../CandidatesList';
import { LienertDossierType } from '../CandidatesList/hooks';
import useLienertDossier from './hooks';
import LienertComments from './LienertComments';
import { CommentType } from '../CandidatesList/Table/Columns/Renderers/Comment/hooks';

const useStyles = makeStyles({
  dossierContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#bcbcbc',
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
  },
  toolbarClose: {
    marginLeft: 'auto',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
  dossier: CandidateDossierDTO;
  candidate: CandidateDTO;
  dossierType: LienertDossierType;

  onNextDossier?: () => void;
  onPrevDossier?: () => void;
}

const getListTypeName = (listType: LienertDossierType) => {
  switch (listType) {
    case LienertDossierType.longList:
      return 'Longlist';
    case LienertDossierType.shortList:
      return 'Shortlist';
    case LienertDossierType.latecomerList:
      return 'Nachzügler';
    default:
      return '';
  }
};

const LienertDossierDialog: React.FC<Props> = ({
  open,
  onClose,
  dossier,
  candidate,
  dossierType,
  onNextDossier,
  onPrevDossier
}) => {
  const classes = useStyles();
  const { updateClientComment } = useLienertDossier();
  const { refreshCandidates } = usePotentialCandidatesContext();
  const { selectedProjectId } = useParams<{ selectedProjectId: string }>();
  const [isSavingComment, setIsSavingComment] = useState(false);
  const [clientComment, setClientComment] = useState(
    candidate.clientCommentLongList || ''
  );
  const [dialogSize, setDialogSize] = useState(8);
  const { t } = useTranslation('lienertDossierDialog');

  const commentType = CommentType.longList

  const handleEditorChange = useCallback((text: string) => {
    setClientComment(text);
  }, []);

  const handleDownload = useCallback(() => {
    if (dossier.contentUrl) {
      const downloadElement = document.createElement('a');
      downloadElement.href = dossier.contentUrl;
      downloadElement.download = `${candidate.lastName}_${candidate.firstName}`;
      downloadElement.style.display = 'none';
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
    }
  }, [candidate.firstName, candidate.lastName, dossier.contentUrl]);

  const handleSave = useCallback(async () => {
    setIsSavingComment(true);
    await updateClientComment(
      selectedProjectId || "",
      candidate.candidateId,
      clientComment,
      commentType
    );
    refreshCandidates();
    setIsSavingComment(false);
    onClose();
  }, [updateClientComment, selectedProjectId, candidate.candidateId, clientComment, commentType, refreshCandidates, onClose]);

  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <Toolbar sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
        <Box flex={1} display="flex" flexWrap="nowrap" flexShrink={1} alignItems="center" overflow="hidden">
            <IconButton
              color="inherit"
              onClick={onClose}
              aria-label="close"
              disabled={isSavingComment}
            >
              <Close />
            </IconButton>
            <Typography variant="h6" textOverflow="ellipsis" sx={{whiteSpace: "nowrap",  overflow:"hidden"}} >
            {`Dossier - ${getListTypeName(dossierType)}: ${candidate.lastName} ${candidate.firstName}`}
            </Typography>
          
        </Box>
          <Box flex={1} className={classes.toolbarClose}  textAlign="center">
            <IconButton onClick={onPrevDossier}><ArrowBack /></IconButton>
            <IconButton onClick={onNextDossier}><ArrowForward /></IconButton>
          </Box>
          <Box flex={1} className={classes.toolbarClose} display="flex" justifyContent="end" gap={2}>
            <Button onClick={handleDownload} variant="contained">
              {t('download')}
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={isSavingComment}
            >
              {t('save')}
            </Button>
        </Box>

      </Toolbar>
      <Grid container>
        <Grid
          item
          xs={dialogSize as GridSize}
          className={classes.dossierContainer}
        >
          <DocumentViewer file={dossier.contentUrl} />
        </Grid>
        <Grid item xs={(12 - dialogSize) as GridSize}>
          <Box textAlign="right" paddingRight={3}>
            <Typography>Breite Dossier</Typography>
            <ButtonGroup>
              <Button onClick={() => setDialogSize(6)}>1:1</Button>
              <Button onClick={() => setDialogSize(8)}>2:1</Button>
              <Button onClick={() => setDialogSize(9)}>3:1</Button>
            </ButtonGroup>
          </Box>
          <LienertComments
            commentType={commentType}
            candidate={candidate}
            onClose={onClose}
            handleEditorChange={handleEditorChange}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LienertDossierDialog;
