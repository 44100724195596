import { useCallback, useEffect, useState } from 'react';

import { Description } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { useSnackbar } from 'notistack';
import {
  CandidateDossierDTO,
  CandidateDTO
} from '../../../../../../api/projects.dto';
import IconButtons from '../../../../../../components/IconButtons';
import { useWithMessage } from '../../../../../Error';
import LienertDossierDialog from '../../../../LienertDossierDialog/LienertDossierDialog';
import {
  LienertDossierType,
  usePotentialCandidatesContext
} from '../../../hooks';

interface Props {
  potentialCandidateId: string;
  isAccessible: boolean;
  dossierType: LienertDossierType;
}

export enum PagingDirection {
  FORWARD,
  BACKWARD
}

const LienertDossier = ({
  potentialCandidateId,
  dossierType,
  isAccessible,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  // if an error occurs the icon should be hidden
  const [showIcon, setShowIcon] = useState(false);
  const [fileName, setFileName] = useState('');
  const [candidateDossier, setCandidateDossier] =
    useState<CandidateDossierDTO>();
  const [candidate, setCandidate] = useState<CandidateDTO>();
  const {
    generateDossier,
    potentialCandidates,
    potentialCandidatesLatecomer,
    potentialCandidatesShortlist,
  } = usePotentialCandidatesContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const withMessage = useWithMessage();
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    let potentialCandidate;
    switch (dossierType) {
      case LienertDossierType.longList:
        potentialCandidate = potentialCandidates.find(
          (pC) => pC.id === potentialCandidateId
        );
        setCandidate(potentialCandidate);
        if (
          potentialCandidate &&
          potentialCandidate.dossierLienertLongList &&
          potentialCandidate.dossierLienertLongList.length > 0
        ) {

          setFileName(
            potentialCandidate.dossierLienertLongList[
              potentialCandidate.dossierLienertLongList.length - 1
            ].fileName
          );
          setShowIcon(true);
        }
        break;
      case LienertDossierType.latecomerList:
        potentialCandidate = potentialCandidatesLatecomer.find(
          (pC) => pC.id === potentialCandidateId
        );
        setCandidate(potentialCandidate);
        if (
          potentialCandidate &&
          potentialCandidate.dossierLienertLongList &&
          potentialCandidate.dossierLienertLongList.length > 0
        ) {
          setFileName(
            potentialCandidate.dossierLienertLongList[
              potentialCandidate.dossierLienertLongList.length - 1
            ].fileName
          );
          setShowIcon(true);
        }
        break;
      case LienertDossierType.shortList:
        potentialCandidate = potentialCandidatesShortlist.find(
          (pC) => pC.id === potentialCandidateId
        );
        setCandidate(potentialCandidate);
        if (
          potentialCandidate &&
          potentialCandidate.dossierLienertShortList &&
          potentialCandidate.dossierLienertShortList.length > 0
        ) {
          setFileName(
            potentialCandidate.dossierLienertShortList[
              potentialCandidate.dossierLienertShortList.length - 1
            ].fileName
          );
          setShowIcon(true);
        }
        break;
      default:
        setShowIcon(false);
    }
  }, [dossierType, potentialCandidates, potentialCandidateId, potentialCandidatesLatecomer, potentialCandidatesShortlist]);

  const openCV = useCallback(async (candidateId: string, file: string) => {
    if (!dialogOpen) {
      setIsLoading(true);
    }
    const dossier = await generateDossier(candidateId, file).catch(
      withMessage()
    );
    setIsLoading(false);
    if (dossier && candidate) {
      setCandidateDossier(dossier);
      setFileName(dossier.fileName);
    }
    setDialogOpen(true);
  }, [dialogOpen, generateDossier, withMessage, candidate]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const selectDossierInList = useCallback((direction: PagingDirection) => {
    let filtertPotentialCandidates: CandidateDTO[] = [];
    let index: number = 0;
    switch (dossierType) {
      case LienertDossierType.longList:
        filtertPotentialCandidates = potentialCandidates.filter(pCandidate => (pCandidate.dossierLienertLongList || []).length > 0);
        index = filtertPotentialCandidates.findIndex(pCandidate => pCandidate.id === candidate?.id);
        break;
      case LienertDossierType.shortList:
        filtertPotentialCandidates = potentialCandidatesShortlist.filter(pCandidate => (pCandidate.dossierLienertShortList || []).length > 0);
        index = filtertPotentialCandidates.findIndex(pCandidate => pCandidate.id === candidate?.id);
        break;
        case LienertDossierType.latecomerList:
          filtertPotentialCandidates = potentialCandidatesLatecomer.filter(pCandidate => (pCandidate.dossierLienertLongList || []).length > 0);
          index = filtertPotentialCandidates.findIndex(pCandidate => pCandidate.id === candidate?.id);
          break;
      default:
        enqueueSnackbar('Keine weiteren Dossiers!')
        break;
    }
    if (index === -1) {
      enqueueSnackbar('Keine weiteren Dossiers!')
      return;
    }
    const nextPotentialCandidate = filtertPotentialCandidates[direction === PagingDirection.FORWARD ? index + 1 : index - 1];

    if (!nextPotentialCandidate) {
      enqueueSnackbar('Keine weiteren Dossiers!')
      return;
    }
    setCandidate(nextPotentialCandidate);

    let file: string;
    switch (dossierType) {
      
      case LienertDossierType.longList:
        file = nextPotentialCandidate.dossierLienertLongList![nextPotentialCandidate.dossierLienertLongList!.length - 1].fileName
        break;
      case LienertDossierType.shortList:
        file = nextPotentialCandidate.dossierLienertShortList![nextPotentialCandidate.dossierLienertShortList!.length - 1].fileName
        break;
      case LienertDossierType.latecomerList:
          file = nextPotentialCandidate.dossierLienertLongList![nextPotentialCandidate.dossierLienertLongList!.length - 1].fileName
          break;

      default:
        return;

    }
    setFileName(file)
    setDialogOpen(true);
    openCV(nextPotentialCandidate.candidateId, file);

  }, [candidate?.id, dossierType, enqueueSnackbar, openCV, potentialCandidates, potentialCandidatesShortlist, potentialCandidatesLatecomer])

  const handleNextDossier = useCallback(() => {
    selectDossierInList(PagingDirection.FORWARD);
  }, [selectDossierInList]);

  const handlePrevDossier = useCallback(() => {
    selectDossierInList(PagingDirection.BACKWARD);
  }, [selectDossierInList]);

  const handleOnClickButton = useCallback(() => {
    if (candidate && fileName !== '') {
      openCV(candidate.candidateId, fileName)
    }
  }, [candidate, fileName, openCV])


  if (isLoading) {
    return <CircularProgress color="secondary" size={30} />;
  }

  return (
    <>
      {showIcon && (
        <IconButtons
          icons={[{ key: 'description', icon: Description, onClick: handleOnClickButton }]}
          isDisabled={!isAccessible}
        />
      )}
      {candidateDossier && candidate && (
        <LienertDossierDialog
          dossierType={dossierType}
          open={dialogOpen}
          onClose={handleClose}
          dossier={candidateDossier}
          candidate={candidate}
          onNextDossier={handleNextDossier}
          onPrevDossier={handlePrevDossier}
        />
      )}
    </>
  );
};

export default LienertDossier;
