import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { markClientNotificationAsRead } from '../../api/notifications';
import {
  NotificationsDTO,
  NotificationType,
} from '../../api/notifications.dto';

interface MapNotificationParams {
  notifications: NotificationsDTO[];
}

function updateWhenNotRead(id: string, isRead: boolean) {
  if (!isRead) {
    markClientNotificationAsRead([id]);
  }
}

const useMapNotification = ({ notifications }: MapNotificationParams) => {
  const { t } = useTranslation('notificationTranslation');
  const navigate = useNavigate();

  const mapNotificationObject = useCallback(
    (
      notification: NotificationsDTO
    ): { title: string; description: string; onClick: () => void } => {
      const { notificationObject } = notification;
      switch (notification.type) {
        case NotificationType.PROJECT_RELEASED:
          return {
            title: t('clientProjectRelease.title'),
            description: t('clientProjectRelease.description', {
              projectName:
                notification.notificationObject[notification.type].project
                  ?.jobTitle1,
            }),
            onClick: () => {
              if (notificationObject[notification.type].project.id) {
                navigate(
                  `/projects/details/${
                    notificationObject[notification.type].project.id
                  }`
                );
              }
              updateWhenNotRead(notification.id, notification.isRead);
            },
          };
        case NotificationType.UPDATED_JOB_POSTING:
          return {
            title: t('clientJobPostingUpdated.title'),
            description: t('clientJobPostingUpdated.description', {
              projectName:
                notification.notificationObject[notification.type].project
                  ?.jobTitle1,
            }),
            onClick: () => {
              if (notificationObject[notification.type].project.id) {
                navigate(
                  `/projects/details/${
                    notificationObject[notification.type].project.id
                  }/jobAd`
                );
              }
              updateWhenNotRead(notification.id, notification.isRead);
            },
          };
        case NotificationType.NEW_DOSSIER_ADDED:
          return {
            title: t('clientNewDossierAdded.title'),
            description: t('clientNewDossierAdded.description', {
              projectName:
                notification.notificationObject[notification.type].project
                  ?.jobTitle1,
            }),
            onClick: () => {
              if (notificationObject[notification.type].project.id) {
                navigate(
                  `/projects/details/${
                    notificationObject[notification.type].project.id
                  }/dossier`
                );
              }
              updateWhenNotRead(notification.id, notification.isRead);
            },
          };
        case NotificationType.NEW_DOKUMENT_ADDED:
          return {
            title: t('clientNewDokumentAdded.title'),
            description: t('clientNewDokumentAdded.description', {
              projectName:
                notification.notificationObject[notification.type].project
                  ?.jobTitle1,
            }),
            onClick: () => {
              if (notificationObject[notification.type].project.id) {
                navigate(
                  `/projects/details/${
                    notificationObject[notification.type].project.id
                  }/documents`
                );
              }
              updateWhenNotRead(notification.id, notification.isRead);
            },
          };

          case NotificationType.NEW_LINK_ADDED:
            return {
              title: t('clientNewLinkAdded.title'),
              description: t('clientNewLinkAdded.description', {
                projectName: notification.notificationObject[notification.type].project?.jobTitel1,
              }),
              onClick: () => {
                if (notificationObject[notification.type].project.id) {
                  navigate(
                    `/projects/details/${
                      notificationObject[notification.type].project.id
                    }/documents`
                  );
                }
                updateWhenNotRead(notification.id, notification.isRead);
              }
            }

        default:
          throw new Error(
            `Invalid notification type: Notification type ${notification.type} is invalid!`
          );
      }
    },
    [t, navigate]
  );

  const mappedNotificationData = useMemo(
    () =>
      notifications
        .map((n) => {
          const { description, title, onClick } = mapNotificationObject(n);

          return [
            n.id,
            n.isRead,
            new Date(n.triggerDate),
            title,
            description,
            onClick,
          ];
        })
        .reverse(),
    [mapNotificationObject, notifications]
  );

  return { mappedNotificationData };
};

export default useMapNotification;
