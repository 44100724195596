import { Grid } from '@mui/material';

interface Props {
    fullWidth?: boolean;
    value?: any;
}

const HTMLField = ({fullWidth, value}: Props): JSX.Element => (
    <Grid item md={fullWidth ? 12 : 6} xs={12}>
        {/* eslint-disable-next-line react/no-danger */ }
        <div dangerouslySetInnerHTML={{ __html: value}} style={{wordBreak: 'break-word'}}/>
    </Grid>
);

export default HTMLField;