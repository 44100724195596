
import { Box, Container, Paper } from "@mui/material";
import { useEffect } from "react";
import { redirect } from "react-router-dom";

import { partner } from '../../constants';
import Partner from '../../enums/Partner';

const Privacy: React.FC = () => {
    
    useEffect(() => {
        if (partner !== Partner.LIENERT) {
            redirect('/impressum');
        }
    })

    return (
    <Container>
        <Paper>
            <Box m={5} p={5}>
                {partner === Partner.LIENERT ? (
                    <Box paddingTop={8}>
                        <Box>
                            <p>
                                <strong>Willkommen bei der Jörg Lienert AG</strong>
                                <br />
                                Wir freuen uns, dass Sie unser Kundenportal nutzen. Wir
                                sensibilisieren Sie mit den vorliegenden Nutzungsbestimmungen,
                                welche unter anderem Datenschutz und Datensicherheitsregelungen
                                enthalten. Die Jörg Lienert AG sowie Sie als Nutzer des
                                Kundenportals werden zum Verantwortlichen / Datenbearbeiter im
                                Sinne der europäischen Datenschutz-Grundverordnung (DSGVO) und
                                dem schweizerischen Datenschutzgesetz (DSG) sowie der Verordnung
                                zum Bundesgesetz über den Datenschutz (VDSG).
                                <br />
                                Die Jörg Lienert AG behandelt Ihre personenbezogenen Daten sowie
                                die anvertrauten Bewerbungsdossiers vertraulich und hält die
                                gesetzlichen Datenschutzbestimmungen ein, namentlich das
                                Bundesgesetz über den Datenschutz (DSG). Die Europäische Union
                                (EU) anerkennt, dass das schweizerische Datenschutzrecht einen
                                angemessenen Datenschutz gewährleistet.
                                <br />
                                <br />
                                Mit Zustimmung der besonderen Nutzungsbestimmungen
                                «Kundenportal» stimmen Sie zu, dass Sie und Ihre Mitarbeitenden
                                die gesetzlichen Vorschriften und die nachfolgend aufgeführten
                                Bestimmungen einhalten werden.
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>
                                    Worum geht es bei diesen besonderen Nutzungsbestimmungen
                                    «Kundenportal»?
                                </strong>
                                <br />
                                Diese besonderen Nutzungsbestimmungen «Kundenportal» geben
                                Auskunft darüber, welche Daten die Jörg Lienert AG während Ihres
                                Besuchs auf dieses «Kundenportal» erhebt und wie die Jörg
                                Lienert AG diese Daten nutzt. Zudem werden auch die Rechte und
                                Pflichten erläutert, welche Sie als Nutzer in Bezug auf den
                                Datenschutz und Datensicherheit einzuhalten haben.
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>1. Nutzung des Kundenportals</strong>
                                <br />
                                Ihre personenbezogenen Daten werden benötigt, damit Sie das
                                Kundenportal der Jörg Lienert AG nutzen können. Das Portal
                                https://portal.joerg-lienert.ch dient als zentrale
                                Informations-Plattform im Bewerbungsprozess zwischen der Jörg
                                Lienert AG und Ihnen als vertretende Person der auftraggebenden
                                Firma.
                                <ol>
                                    <Box component="li" paddingBottom={2}>
                                        Erforderliche personenbezogene Daten für den Zugang zum
                                        Kundenportal
                                        <br />
                                        Nachfolgende personenbezogene Daten werden von Ihnen für die
                                        Nutzung des Kundenportals benötigt:
                                        <ul>
                                            <li>Vor- und Nachname</li>
                                            <li>E-Mailadresse</li>
                                            <li>Mobil-Telefonnummer</li>
                                        </ul>
                                    </Box>
                                    <Box component="li" paddingBottom={2}>
                                        Login Kundenportal
                                        <br />
                                        Nach erfolgreichem Login mittels Benutzername, Passwort und
                                        2. Faktor (SMS) erhalten Sie im Zuge der Auftragsabwicklung
                                        Einsicht in Listen und personenbezogene Daten von
                                        Kandidatinnen und Kandidaten.
                                        <br />
                                        <br />
                                        Ihre personenbezogenen Daten werden dabei mithilfe
                                        technischer Anlage gespeichert, bearbeitet und ausgewertet.
                                        <br />
                                        <br />
                                        Die Nutzung Ihres Zugangsaccounts wird während der
                                        Mandatsdauer im System registriert, damit im Falle von
                                        technischen Problemen reagiert werden kann. Es ist daher
                                        systembedingt möglich zu rekonstruieren, welche
                                        personenbezogenen Daten von Bewerbenden Sie im Kundenportal
                                        aufgerufen haben.
                                    </Box>
                                    <Box component="li" paddingBottom={2}>
                                        Dauer der Speicherung
                                        <br />
                                        Die Daten werden gelöscht, sobald sie für die Erreichung des
                                        Zwecks ihrer Erhebung nicht mehr erforderlich sind oder
                                        soweit die weitergehende Speicherung nicht durch
                                        überwiegende Interessen oder gesetzliche Pflichten des
                                        Verantwortlichen gerechtfertigt werden.
                                        <br />
                                        <br />
                                        Nach Abschluss eines Mandates wird Ihr Zugang wieder
                                        gesperrt, sofern kein anderes Mandat bei Jörg Lienert AG
                                        aktiv ist. Für eine Reaktivierung Ihres Zugangs wird ein
                                        neuer Zugang erstellt.
                                        <br />
                                        <br />
                                        Für betriebliche Daten (z.B. Systemprotokolle, Logs) gelten
                                        grundsätzliche Aufbewahrungsfristen von zwölf Monaten oder
                                        weniger.
                                        <br />
                                        <br />
                                        Die Löschung einzelner Daten aus den Backups ist aus Gründen
                                        der Datensicherheit nicht möglich. Die Backups werden aber
                                        als Ganzes gelöscht, sobald sie zur Datensicherung nicht
                                        mehr erforderlich sind.
                                    </Box>
                                    <Box component="li" paddingBottom={2}>
                                        Widerspruchs- und Beseitigungsmöglichkeit
                                        <br />
                                        Der Nutzer hat jederzeit die Möglichkeit, die Löschung
                                        seines Accounts zu beantragen. Hierdurch wird ebenfalls ein
                                        Widerruf der Einwilligung der Speicherung der während des
                                        Anmeldevorgangs erhobenen personenbezogenen Daten
                                        ermöglicht.{' '}
                                    </Box>
                                </ol>
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>2. Beschreibung des Kundenportals </strong>
                                <br />
                                Auf dem Kundenportal der Jörg Lienert AG können Kunden während
                                eines laufenden Rekrutierungsmandates die Daten der
                                eingegangenen Bewerbenden (Listen, personenbezogene
                                Informationen zu Kandidatinnen und Kandidaten) einsehen und
                                herunterladen.
                                <br />
                                <br />
                                2.1 Personenbezogene Daten von Bewerbenden sind:
                                <br />
                                <ul>
                                    <li>
                                        Personendaten wie Vorname, Name, Geburtsdatum, E-Mail,
                                        Adresse, Zivilstand, Beruf, Arbeitgeber, Funktion/Stelle,
                                        Ausbildungen und weitere.
                                    </li>
                                    <li>
                                        Dokumente wie Bewerbungsbrief, Lebenslauf/CV, Zeugnisse,
                                        Arbeitszeugnisse und weitere.
                                    </li>
                                    <li>
                                        Beurteilungen und Berichte der Jörg Lienert AG und
                                        beauftragten Dritten wie Assessmentberichte und weitere.
                                    </li>
                                </ul>
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>3. Zweck der Datenbearbeitung</strong>
                                <br />
                                Die Jörg Lienert AG hat als Betreiberin zahlreiche technische
                                und organisatorische Massnahmen umgesetzt, um einen möglichst
                                lückenlosen Schutz der über dieses Kundenportal verarbeiteten
                                personenbezogenen Daten sicherzustellen. Dennoch können
                                plattformbasierte Datenübertragungen grundsätzlich
                                Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
                                gewährleistet werden kann.
                                <br />
                                <br />
                                Die Jörg Lienert AG gibt Daten nur an Externe weiter, wenn die
                                Zustimmung dazu erfolgt ist, soweit dies zur Auftragsabwicklung
                                erforderlich ist und diese den entsprechenden Vertraulichkeits-
                                und Sorgfaltsbestimmungen zugestimmt haben.
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>4. Verantwortung von Inhalt und Informationen</strong>
                                <br />
                                Die Verantwortung der extern angelieferten Inhalte
                                «Bewerbungsunterlagen» und die Art und Weise der Speicherung im
                                System der Jörg Lienert AG liegt bei der Jörg Lienert AG.
                                Verantwortung des Nutzers: der Nutzer stellt sicher, dass bei
                                der Konsultation der Bewerbungsunterlagen die Datenschutz- und
                                Datensicherheitsbestimmungen eingehalten werden. Ein Download
                                der Unterlagen ist zulässig, sofern die Daten in einem
                                geschützten Bereich abgespeichert werden.
                                <br />
                                <br />
                                Der Zugriff auf das Kundenportal erfolgt über das Internet. Dies
                                impliziert Risiken, die dem Nutzer bekannt sind oder bei
                                Anwendung der gebotenen Sorgfalt bekannt sein können. Der Nutzer
                                ist verpflichtet, sich bzgl. Sicherheitsrisiken, die durch die
                                Benutzung des Internets entstehen können, vollumfänglich selbst
                                zu informieren und abzusichern. Der Nutzer verpflichtet sich zum
                                sorgfältigen Umgang mit Passwörtern, Zugangscodes und
                                Userinformationen.
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>5. Vertraulichkeit und Sorgfaltsbestimmungen</strong>
                                <br />
                                Beide Parteien, sowie deren Hilfspersonen, verpflichten sich,
                                sämtliche Informationen, welche im Zusammenhang mit den
                                Leistungen unterbreitet oder angeeignet wurden, vertraulich zu
                                behandeln. Diese Pflicht bleibt auch nach Beendigung des
                                Vertrages bestehen.
                                <br />
                                <br />
                                Sie, als nutzende Person des Kundenportals, halten folgende
                                Vertraulichkeits- und Sorgfaltsbestimmungen vollumfänglich ein:
                                <br />
                                <ul>
                                    <li>
                                        Personendaten von Kandidatinnen und Kandidaten dürfen
                                        ausserhalb des Portals der Jörg Lienert AG nicht
                                        weiterverarbeitet werden, sei denn, die betroffene Person
                                        hat explizit die Freigabe zur Weiterverarbeitung erteilt.
                                    </li>
                                    <li>
                                        Ausgehändigte Personendaten von bewerbenden Personen dürfen
                                        nicht länger aufbewahrt werden, als das es zur
                                        Auftragsabwicklung erforderlich ist.
                                    </li>
                                    <li>
                                        Sie verpflichten sich, den persönlichen Zugang zum
                                        Kundenportal nicht an eine Drittperson weiterzugeben.
                                        Bewahren Sie Ihren Benutzername und Passwort immer an einem
                                        sicheren Ort auf.
                                    </li>
                                    <li>
                                        Sie melden Datenschutz- oder Datensicherheitsverletzungen
                                        direkt an datenschutz@joerg-lienert.ch, damit Verletzungen
                                        umgehend behoben werden können.
                                    </li>
                                </ul>
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>6. Datensicherheit</strong>
                                <br />
                                Jörg Lienert AG ergreift angemessene technische und
                                organisatorische Sicherheitsvorkehrungen zum Schutz der bei Jörg
                                Lienert AG gespeicherten Personendaten vor unberechtigtem
                                Zugriff und Missbrauch (beispielweise IT- und
                                Netzwerksicherheitslösungen, Erlass von Weisungen, Schulungen,
                                Zugangskontrollen und -beschränkungen).
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>7. Missbrauch</strong>
                                <br />
                                Die Jörg Lienert AG behält sich das Recht vor, Nutzerprofile bei
                                Verdacht auf Missbrauch oder bei erfolgtem Missbrauch jederzeit
                                und ohne Angabe von Gründen zu sperren oder zu löschen. Der
                                diesbezügliche Entscheid liegt im alleinigen Ermessen der Jörg
                                Lienert AG, die dem betroffenen Nutzer im Zweifelsfall
                                Gelegenheit zur Stellungnahme gibt. Als Missbrauch gilt
                                insbesondere die absichtliche Angabe von falschen Profildaten.
                                Um sich vor Missbräuchen zu schützen, geht die Jörg Lienert AG
                                mit allen gebotenen rechtlichen Mittel gegen Missbräuche vor
                                (Strafanzeige, Schadenersatzklage usw.)
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>8. Datenschutzverantwortlicher</strong>
                                <br />
                                Jede betroffene Person kann sich jederzeit bei allen Fragen und
                                Anregungen zum Datenschutz sowie bezüglich der Ausübung ihrer
                                Rechte direkt per E-Mail an datenschutz@joerg-lienert.ch wenden.
                                Unsere Datenschutzverantwortliche Person wird dann mit Ihnen
                                Kontakt aufnehmen.
                            </p>
                        </Box>
                        <Box>
                            <p>
                                <strong>
                                    Einwilligungserklärung
                                    <br />
                                    Ich bestätige hiermit, dass ich den Inhalt der
                                    Nutzungsbestimmung «Kundenportal» zur Kenntnis genommen und
                                    verstanden habe. Ich erkläre hiermit, dass ich meine
                                    Einwilligung gebe, dass die Jörg Lienert AG meine zuvor
                                    genannten personenbezogenen Daten (Ziff. 1) unter den
                                    aufgeführten Bedingungen und Voraussetzungen gemäss
                                    Datenschutzerklärung bearbeiten darf. Gleichzeitig bestätige
                                    ich, die Vertraulichkeits- und Sorgfaltsbestimmungen (Ziff. 5)
                                    für das Kundenportal der Jörg Lienert AG einzuhalten.
                                </strong>
                            </p>
                        </Box>
                    </Box>
                ) : (
                   null
                )}
            </Box>
        </Paper>
    </Container>
)
};

export default Privacy;