import { useCallback, useEffect, useState } from 'react';
import { NotificationsDTO } from '../../api/notifications.dto';
import {
  getClientNotifications,
  getClientNotificationsCount,
} from '../../api/notifications';
import { useAppAbility } from '../../casl';
import { SEE } from '../../casl/actions';
import { NOTIFICATIONS } from '../../casl/subjects';

const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationsDTO[]>([]);

  const loadNotifications = useCallback(() => {
    getClientNotifications().then((res) => {
      setNotifications(res);
    });
  }, []);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return {
    notifications,
    refreshNotifications: loadNotifications,
  };
};

export const useNotificationCount = () => {
  const [notificationCount, setNotificationCount] = useState<
    number | undefined
  >(undefined);

  const ability = useAppAbility();

  const loadNotificationNumber = useCallback(() => {
    if (ability.can(SEE, NOTIFICATIONS)) {
      getClientNotificationsCount().then((res) => {
        setNotificationCount(res.count);
      });
    }
  }, [ability]);

  useEffect(() => {
    loadNotificationNumber();
  });

  return {
    notificationCount,
    refreshNotificationCount: loadNotificationNumber,
  };
};

export default useNotifications;
