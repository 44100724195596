import './Error.scss';

import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';

const Error = (): JSX.Element => {
  const {t} = useTranslation("errorPage");
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      style={{minHeight: '100vh'}}
    >
      <Grid item>
        <Typography gutterBottom variant="h2">
          {t("headlineLarge")}
        </Typography>
        <h1>{t("headlineSmall")}</h1>
        <p>{t("infoMessage")}</p>
      </Grid>
    </Grid>
  );
};

export default Error;
