import { useParams } from "react-router-dom";

import { ExitToApp } from '@mui/icons-material';

import { Dispatch, SetStateAction } from "react";
import { ProjectsDTO } from "../../../api/projects.dto";
import { Can } from "../../../casl";
import { SEE } from "../../../casl/actions";
import { NOTIFICATIONS } from "../../../casl/subjects";
import AppBar from '../../../components/AppBar';
import Layout from '../../../components/Layout';
import { useLoginContext } from '../../../modules/Auth';
import Details from '../../../modules/Company/Details';
import Table from '../../../modules/Company/ProjecstList/Table';
import Notifications from "../../Notification/Notifications";

interface CompanyProps {
  projects: ProjectsDTO[];
  setCustomerId: Dispatch<SetStateAction<string>>;
  selectedCustomerId: string;
}

const Company = ({ projects, setCustomerId, selectedCustomerId }: CompanyProps): JSX.Element => {
  const { logout } = useLoginContext();
  const { selectedProjectId } = useParams<{ selectedProjectId?: string }>();

  return (
    <Layout
      appBar={
        <AppBar
          clickableIcons={[
            {
              key: 'exit',
              icon: ExitToApp,
              onClick: logout,
            },
          ]}
          title="Aufträge"
          projects={projects}
          setCustomerId={setCustomerId}
        />
      }
      leftBody={selectedProjectId ? <Details selectedCustomerId={selectedCustomerId} /> : null}
    >
      <Can I={SEE} a={NOTIFICATIONS} >
        <Notifications />
      </Can>
      <Table
        projects={projects}
      />

    </Layout>
  );
};

export default Company;
