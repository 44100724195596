/* eslint-disable max-classes-per-file */
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { TFunction } from "i18next";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";

import { isDevelopment } from "../../constants";

export type ErrorCatcher = (err: Error) => (string | null)

export class MessageError extends Error {
  public readonly displayMessage?: string;

  public readonly originalError: Error;

  constructor(originalError: Error,  displayMessage?: string) {
    super(originalError.message);
    this.displayMessage = displayMessage;
    this.originalError = originalError;
  }
}

export class UnhandledError extends Error {
  public readonly originalError: unknown;

  constructor(originalError: unknown) {
    super("unhandled error");
    this.originalError = originalError;
  }
}

export const handleError = (
  error: unknown, 
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,  
  t: TFunction
): void => {
  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof MessageError) {
    // eslint-disable-next-line no-console
    console.error(error.originalError);
    enqueueSnackbar(error.displayMessage || `${t("defaultMessage")}`);
    return;
  }

  if (isDevelopment) {
    throw error;
  } else {
    window.location.href = "/err";
  }
};

export const useWithMessage = (): ((catchers?: ErrorCatcher[]) => (err: Error) => void) => {
  const {t} = useTranslation("error");
  const { enqueueSnackbar } = useSnackbar();

  return useCallback((catchers: ErrorCatcher[] = []) =>
    (err: Error): void => {
      const found = catchers.find((c) => !!c(err));
      const message = (found && found(err)) || undefined;
      
      handleError(new MessageError(err, message), enqueueSnackbar, t);
    },
    [enqueueSnackbar, t],
  );
};
