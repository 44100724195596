import { Box, Divider } from '@mui/material';

interface Props {
  mb?: number;
  mt?: number;
}

const PaddedDivider = ({ mb = 2, mt }: Props): JSX.Element => (
  <Box mb={mb} mt={mt}>
    <Divider />
  </Box>
);

export default PaddedDivider;
