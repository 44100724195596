import { Box, Paper } from '@mui/material'
import { SafeGuard } from '../../modules/Auth'
import NotificationMap from '../../modules/Notificaton/Notifications'
import useNotifications from './hooks'


const Notifications = () => {
  const { notifications } = useNotifications();

  return (
    <SafeGuard>
      <Box sx={{ width: '100%',}} my={2} pb={2}>
        <Paper sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <NotificationMap notifications={notifications} />
        </Paper>
      </Box>
    </SafeGuard>
  )
}

export default Notifications