import { AxiosPromise } from 'axios';

import { registerURL } from '../constants';
import { doFetch } from './apiUtils';

export interface RegisterRequestDTO {
  firstname: string;
  lastname: string;
  // phonenumber: string, //
  password: string; // password
  passwordRepeated: string;
}

export interface RegisterBodyDTO {
  status: string;
}

// eslint-disable-next-line arrow-body-style
const registerApiCall = (requestBody: RegisterRequestDTO): AxiosPromise<RegisterBodyDTO> => {
  return doFetch(registerURL, requestBody, "POST");
};

export default registerApiCall;
