import { useCallback, useEffect, useState } from 'react';
import { getCustomerList } from './api/projects';
import { GetCustomerListResponseDTO } from './api/projects.dto';

interface Return {
  customerList: GetCustomerListResponseDTO[] | undefined;
}

const useGlobalState = (isUserAdmin: boolean): Return => {
  const [customerList, setCustomerList] = useState<
    GetCustomerListResponseDTO[]
  >([]);
  const loadCustomerList = useCallback(async () => {
    try {
      const customerListResponse: GetCustomerListResponseDTO[] =
        await getCustomerList();
      setCustomerList(customerListResponse);
    } catch (e) {
      setCustomerList([
        {
          id: '',
          firstname: '',
          lastname: '',
          email: '',
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (isUserAdmin) {
      loadCustomerList();
    }
  }, [loadCustomerList, isUserAdmin]);

  if (!isUserAdmin) {
    return { customerList: undefined };
  }
  return { customerList };
  //     const [showLoadingIndicatorGlobal, setShowLoadingIndicatorGlobal] = useState(false);
  //     const [loggedIn, setLoggedIn] = useState(false);
  //     const [currentPage, setCurrentPage] = useState();
  //     const [token, setToken] = useState(false);
  //     const [notificationCount, setNotificationCount] = useState(false);
  //     // const [loggedIn, setLoggedIn] = useState(false);

  //     return { showLoadingIndicatorGlobal, loggedIn,
  //         setCurrentPage, setToken, setNotificationCount,
  //          setLoggedIn, setShowLoadingIndicatorGlobal};
};

export default useGlobalState;
