import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import defineAbilitiesFor, { AbilityContext } from "../../casl";
import { readToken } from "../../utils/tokenStorage";
import { useLoginContext } from "./hook";

interface Props {
  children: ReactNode
}

const SafeGuard: React.FC<Props> = ({ children }) => {
  const { loggedIn } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    // First wait for the auto login to set the loggedIn field. (If its not set yet.)
    if (loggedIn === undefined) {
      return;
    }
 
    // If not logged in, return back to auth page.
    if (!loggedIn) {
      navigate("/");
    }
  }, [navigate, loggedIn]);

  return loggedIn ? (
    <AbilityContext.Provider value={defineAbilitiesFor(readToken() || "")}>
      {children}
    </AbilityContext.Provider>
   ) : null;
};

export default SafeGuard;
