import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  title: string;
  children: JSX.Element;
}

const useStyles = makeStyles({
  container: {
    margin: 0,
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: 0,
    },
  },
});

const ReadOnlySection = ({ title, children }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} container key={title} spacing={5}>
      <Grid item md={12} xs={12}>
        <Box mt={3}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </Grid>
      {children}
    </Grid>
  );
};

export default ReadOnlySection;
