import { useCallback, useState } from 'react';

import { Box, Fab, Grid, Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ArrowBack, ArrowForward } from '@mui/icons-material';

interface Props {
  appBar: React.ReactNode;
  children: React.ReactNode;
  leftBody: React.ReactNode | null;
  header?: React.ReactNode;
}

const useStyles = makeStyles({
  headerContainer: {
    textAlign: 'center',
  },
  childrenGridContainer: {
    height: '75vh',
  },
  childrenGridItem: {
    height: '100%',
  },
});

const Layout = ({ children, appBar, leftBody, header }: Props): JSX.Element => {
  const [showLeftBar, setShowLeftBar] = useState(true);

  const toggleSidebar = useCallback(() => {
    setShowLeftBar((prevState) => !prevState);
  }, []);

  const classes = useStyles();

  return (
    <>
      {appBar}
      <Box m={2}>
        <Box mb={header ? 4 : 8}>
          <Grid className={classes.headerContainer} container>
            <Grid item md={12}>
              {header}
            </Grid>
          </Grid>
        </Box>

        <Grid
          className={classes.childrenGridContainer}
          container
          style={{position: 'relative'}}
          direction="row"
          spacing={3}
        >
          {leftBody && (
            <Hidden smDown>
            <Fab
              color="primary"
              onClick={toggleSidebar}
              style={{
                position: 'absolute',
                bottom: '0',
                left: showLeftBar ? 'calc(17vw - 40px)' : '0',
            
              }}
            >
              {showLeftBar ? <ArrowBack /> : <ArrowForward />}
            </Fab>
            </Hidden>

          )}
          {leftBody && showLeftBar && (
            <Grid className={classes.childrenGridItem} item md={2} xs={12}>
              {leftBody}
            </Grid>
          )}
          <Grid
            className={classes.childrenGridItem}
            item
            md={leftBody && showLeftBar ? 10 : 12}
            xs={12}
          >
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Layout;
