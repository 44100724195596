import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  createTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  ThemeProvider,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Form';
import { isLienert } from '../../constants';
import TEXT from '../../text';
import THEME from '../../theme';
import { useRegisterFormHook } from './hook';

const loginTheme = createTheme(THEME.login);

const RegisterForm = (): JSX.Element => {
  const {
    formik,
    isPasswordEightLong,
    containsSpecialCharacters,
    containsNumbers,
    containsCapitalLetter,
    containsLowerLetter,
    passwordsEqual,
  } = useRegisterFormHook();
  const { t } = useTranslation('register');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderPasswordRequirementIcons = (): JSX.Element => {
    let classNameIconMissing = 'material-icons list-icon-missing  pr-1';
    if (formik.values.password.length <= 0)
      classNameIconMissing = 'material-icons list-icon-missing-gray pr-1';
    return <i className={classNameIconMissing}>cancel</i>;
  };

  const renderPasswordRepeatIcons = (): JSX.Element => {
    let classNameIconMissing = 'material-icons list-icon-missing  pr-1';
    if (formik.values.passwordRepeated.length <= 0)
      classNameIconMissing = 'material-icons list-icon-missing-gray pr-1';
    return <i className={classNameIconMissing}>cancel</i>;
  };

  const iconCheck = (
    <i className="material-icons list-icon-check pr-1">check_circle</i>
  );
  const iconMissing = renderPasswordRequirementIcons();
  const iconNotMatching = renderPasswordRepeatIcons();
  return (
    <Paper className="registerPaper" elevation={24}>
      <form onSubmit={formik.handleSubmit}>
        <ThemeProvider theme={loginTheme}>
          <Paper className="registerPaper" elevation={0} sx={THEME.loginPaper}>
            <img
              alt={TEXT.loginPage.imageAlt}
              src={THEME.loginLogo}
              width="50%"
            />
          </Paper>
        </ThemeProvider>
        <div className="register-textContainer">
          <div className="register-welcomeHeadline">{t('welcome')}</div>
          <div className="register-welcomeSubheadline">
            <p>{t('completeRegistration')}</p>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Input
              autoFocus
              formik={formik}
              fullWidth
              id={TEXT.registerPage.inputFields.firstname}
              label={TEXT.registerPage.inputFields.firstname}
              name="firstName"
              onBlur={(e) => formik.handleBlur(e)}
              onChange={formik.handleChange}
              type="text"
            />
          </Grid>
          <Grid item md={6}>
            <Input
              formik={formik}
              fullWidth
              id={TEXT.registerPage.inputFields.lastname}
              label={TEXT.registerPage.inputFields.lastname}
              name="lastName"
              onBlur={(e) => formik.handleBlur(e)}
              onChange={formik.handleChange}
              type="text"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className="">
              <Typography>{t('selectNewPassword')}</Typography>
              <div className="list-passwordRequirements">
                <ul>
                  <li>
                    <div className="registerPage-listItem">
                      {isPasswordEightLong ? iconCheck : iconMissing}
                      {TEXT.registerPage.passwordRequirements.eightCharacters}
                    </div>
                  </li>
                  <li>
                    <div className="registerPage-listItem">
                      {containsSpecialCharacters ? iconCheck : iconMissing}
                      {
                        TEXT.registerPage.passwordRequirements
                          .oneSpecialCharacter
                      }
                    </div>
                  </li>
                  <li>
                    <div className="registerPage-listItem">
                      {containsNumbers ? iconCheck : iconMissing}
                      {TEXT.registerPage.passwordRequirements.oneNumber}
                    </div>
                  </li>
                  <li>
                    <div className="registerPage-listItem">
                      {containsCapitalLetter ? iconCheck : iconMissing}
                      {TEXT.registerPage.passwordRequirements.oneUpperLetter}
                    </div>
                  </li>
                  <li>
                    <div className="registerPage-listItem">
                      {containsLowerLetter ? iconCheck : iconMissing}
                      {TEXT.registerPage.passwordRequirements.oneLowerLetter}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <Grid item>
              <Input
                formik={formik}
                fullWidth
                id="password"
                label={TEXT.registerPage.inputFields.password}
                margin="normal"
                name="password"
                onBlur={(e) => formik.handleBlur(e)}
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Input
                formik={formik}
                fullWidth
                id="passwordRepeated"
                label={TEXT.registerPage.inputFields.passwordRepeated}
                margin="normal"
                name="passwordRepeated"
                onBlur={(e) => formik.handleBlur(e)}
                onChange={formik.handleChange}
                type="password"
              />
              <div className="registerPage-passwordRepeated-validationCheck">
                {passwordsEqual ? iconCheck : iconNotMatching}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <FormControl
              error={
                formik.touched.checkBoxChecked &&
                Boolean(formik.errors.checkBoxChecked)
              }
              required
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.checkBoxChecked}
                    name="checkBoxChecked"
                    onChange={formik.handleChange}
                  />
                }
                label={
                  isLienert
                    ? TEXT.registerPage.checkBox.licenseLienert
                    : TEXT.registerPage.checkBox.license
                }
              />
              <FormHelperText>{formik.errors.checkBoxChecked}</FormHelperText>
            </FormControl>
            {/* <Link
                  href="*"
                  // TODO: Add ref to license if they are available
                  /> */}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={12}>
            <Button
              className="login-button mt-3"
              color="primary"
              disabled={!formik.isValid}
              fullWidth
              type="submit"
              variant="contained"
            >
              {TEXT.registerPage.button}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Box marginTop={2}>
        <Grid container justifyContent="center">
          <Grid item>
            <Button component={Link} to="/impressum" target="_blank">
              {TEXT.registerPage.imprint}
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} to="/datenschutz" target="_blank">
              {' '}
              {/* Imprint contains also the privacy info */}
              {TEXT.registerPage.privacy}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
export default RegisterForm;
