import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress } from '@mui/material';

import useDocumentDialog from "../../../../components/DocumentDialog/DocumentDialog";
import { TextField } from '../../../../components/ReadOnlySection';
import ReadOnlySection from '../../../../components/ReadOnlySection/ReadOnlySection';
import SliderField from '../../../../components/ReadOnlySection/SliderField';
import SwitchField from '../../../../components/ReadOnlySection/SwitchField';
import { useWithMessage } from '../../../Error';
import useInformations from '../hooks';

const SALARY_MARKS = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map((v) => ({
  value: v * 10000,
  label: `${v * 10}k`,
}));
const AGE_MARKS = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 99].map((v) => ({
  value: v,
  label: v,
}));
const WORK_EXPERIENCE_MARKS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((v) => ({
  value: v,
  label: v,
}));

const PERCENT_SLIDER = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((v) => ({
  value: v,
  label: `${v}%`,
}));

interface FormProps {
  selectedCustomerId: string;
}

const Form = ({ selectedCustomerId }: FormProps): JSX.Element => {

  const { informations, loading, generatePDF } = useInformations({selectedCustomerId});

  const { openDialog, DocumentDialog } = useDocumentDialog();
  const withMessage = useWithMessage();

  const { t } = useTranslation('singleProjectInformationsForm');
  if (loading) {
    return <CircularProgress size="80px" />;
  }

  const handleDownloadButtonClick = async () => {
    const fileString = await generatePDF().catch(withMessage());
    openDialog(fileString || '', `Anforderungsprofil.pdf`, true);
  };

  return (
    <Box px={7} py={4}>
      {DocumentDialog}
      <Box textAlign="right">
        <Button variant="contained" color="primary" disabled={!informations?.customerInformations}
         onClick={handleDownloadButtonClick}>{t('download')}</Button>
      </Box>
      <ReadOnlySection title={t('basicInformation')}>
        <>
          <TextField
            fullWidth
            label={t('title')}
            value={informations?.customerInformations?.title}
          />
          <TextField
            fullWidth
            label={t('address')}
            value={
              informations?.customerInformations?.address?.postalCode &&
              informations?.customerInformations?.address?.city &&
              `${informations?.customerInformations?.address?.postalCode}
${informations?.customerInformations?.address?.city}`
            }
          />
          <TextField
            fullWidth
            label={t('tasks')}
            value={informations?.customerInformations?.tasks}
          />
          <TextField
            fullWidth
            label={t('superiors')}
            value={informations?.customerInformations?.superiors}
          />
          <TextField
            fullWidth
            label={t('subordinates')}
            value={informations?.customerInformations?.subordinates}
          />
          <TextField
            fullWidth
            label={t('departmentStructure')}
            value={informations?.customerInformations?.departmentStructure}
          />
          <TextField
            fullWidth
            label={t('respBudget')}
            value={informations?.customerInformations?.respBudget}
          />
          <TextField
            fullWidth
            label={t('specialTasks')}
            value={informations?.customerInformations?.specialTasks}
          />
          <TextField
            fullWidth
            label={t('reportLine')}
            value={informations?.customerInformations?.reportLine}
          />
          <TextField
            fullWidth
            label={t('other')}
            value={informations?.customerInformations?.other}
          />
        </>
      </ReadOnlySection>
      <ReadOnlySection title={t('desiredQualifications')}>
        <>
          <SliderField
            label={t('age')}
            marks={AGE_MARKS}
            max={99}
            min={0}
            step={1}
            value={informations?.customerInformations?.age}
          />
          <SliderField
            label={t('workExperience')}
            marks={WORK_EXPERIENCE_MARKS}
            min={0}
            max={15}
            step={1}
            value={informations?.customerInformations?.workExperience}
          />
          <TextField
            fullWidth
            label={t('education')}
            value={informations?.customerInformations?.education}
          />
          <TextField
            fullWidth
            label={t('career')}
            value={informations?.customerInformations?.career}
          />
          <TextField
            fullWidth
            label={t('skills')}
            value={informations?.customerInformations?.skills}
          />
          <TextField
            fullWidth
            label={t('qualifications')}
            value={informations?.customerInformations?.qualifications}
          />
           <SliderField
            label={t('travelActivity')}
            marks={PERCENT_SLIDER}
            min={0}
            max={100}
            step={10}
            value={informations?.customerInformations?.travelActivity}
          />
          <SwitchField
            label={t('leadershipExp')}
            value={informations?.customerInformations?.leadershipExp}
          />
         <TextField
            fullWidth
            label={t('goalForPosition')}
            value={informations?.customerInformations?.goalForPosition}
          />
          <TextField
            fullWidth
            label={t('perspective')}
            value={informations?.customerInformations?.perspective}
          />
          <TextField
            fullWidth
            label={t('softSkills')}
            value={informations?.customerInformations?.softSkills}
          />
          <TextField
            fullWidth
            label={t('koCriteria')}
            value={informations?.customerInformations?.koCriteria}
          />
        </>
      </ReadOnlySection>
      <ReadOnlySection title={t('changeReasons')}>
        <>
          <TextField
            fullWidth
            label={t('previousChanges')}
            value={informations?.customerInformations?.previousChanges}
          />
          <TextField
            fullWidth
            label={t('reasonsForEstablishment')}
            value={informations?.customerInformations?.reasonsForEstablishment}
          />
          <TextField
            fullWidth
            label={t('recruiting')}
            value={informations?.customerInformations?.recruiting}
          />
        </>
      </ReadOnlySection>
      <ReadOnlySection title={t('remuneration')}>
        <>
          <SliderField
            label={t('salary')}
            marks={SALARY_MARKS}
            max={250000}
            min={40000}
            step={1000}
            value={informations?.customerInformations?.salary}
          />
          <SliderField
            label={t('performanceComponentsSalary')}
            marks={PERCENT_SLIDER}
            min={0}
            max={100}
            step={10}
            value={informations?.customerInformations?.performanceComponentsSalary}
          />
          <TextField
            fullWidth
            label={t('benefits')}
            value={informations?.customerInformations?.benefits}
          />
          <TextField
            fullWidth
            label={t('workingTime')}
            value={informations?.customerInformations?.workingTime}
          />{' '}
          <TextField
            fullWidth
            label={t('homeOfficeHandling')}
            value={informations?.customerInformations?.homeOfficeHandling}
          />
          <TextField
            fullWidth
            label={t('familiarization')}
            value={informations?.customerInformations?.familiarization}
          />
        </>
      </ReadOnlySection>
      <ReadOnlySection title={t('additionalInformation')}>
        <>
          <TextField
            fullWidth
            label={t('companyInformation')}
            value={informations?.customerInformations?.companyInformation}
          />
          <TextField
            fullWidth
            label={t('blacklist')}
            value={informations?.customerInformations?.blacklist}
          />
          <TextField
            fullWidth
            label={t('whitelist')}
            value={informations?.customerInformations?.whitelist}
          />
          <TextField
            fullWidth
            label={t('advertLink')}
            value={informations?.customerInformations?.advertLink}
          />
          <TextField
            fullWidth
            label={t('moreOther')}
            value={informations?.customerInformations?.moreOther}
          />
        </>
      </ReadOnlySection>
    </Box>
  );
};

export default Form;
