import React from "react";
import {BrowserRouter} from 'react-router-dom';

import { AuthProvider } from "./modules/Auth/hook";
import Router from "./Router";
import './App.scss';

const App: React.FC = () => (
    <BrowserRouter>
      <AuthProvider>
        <Router/>
      </AuthProvider>
    </BrowserRouter>
  );

export default App;
