/* eslint-disable */
const SimpleLogger = {
    debug(...props: any) {
        console.debug(...props);
    },

    log(...props: any) {
        console.log(...props);
    },

    error(...props: any) {
        console.error(...props);
    }
};

export default SimpleLogger;
/* eslint-enable */
