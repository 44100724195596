import { Route, Routes } from 'react-router-dom';

import Error from './pages/Error/Error';
import Home from './pages/Home/Home';
import Imprint from './pages/Imprint/Imprint';
import Login from './pages/Login/Login';
import Privacy from './pages/Privacy/Privacy';

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Error />} path="/err" />
      <Route element={<Home />} path="/projects/*" />
      <Route element={<Imprint />} path="/impressum" />
      <Route element={<Privacy />} path="/datenschutz" />
      <Route element={<Login />} path="/" />
    </Routes>
  );
}
