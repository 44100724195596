import {useCallback, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import {updatePotentialCandidateComment} from "../../../../../../../api/projects";

export enum CommentType {
  longList = "clientCommentLongList",
  shortList = "clientCommentShortList",
}

interface ReturnObject {
  text: string;
  buttons: boolean;
  hideButtons: () => void;
  changeText: (text: string) => void;
  handleDelete: () => void;
  showButtons: () => void;
  handleSubmit: () => void;
}

const useComment = (commentType: CommentType.longList, initText = "", candidateId = ""): ReturnObject => {
  const {selectedProjectId} = useParams<{selectedProjectId: string}>();
  const navigate = useNavigate();

  if (!selectedProjectId) {
    throw new Error('No selected Project id');
  }

  const [text, setText] = useState<string>(initText || '');
  const [buttons, setButtons] = useState<boolean>(false);

  const updateComment = useCallback( async (updateText: string) =>{
    const updateObject = {
      field: commentType,
      comment: updateText
    };
    await updatePotentialCandidateComment(selectedProjectId, candidateId, updateObject)
      .catch( () => {
        navigate('/err');
      });
  },[candidateId, commentType, selectedProjectId, navigate]);

  const changeText = useCallback((newText: string) => {
    setText(newText);
  }, []);

  const hideButtons = useCallback(() => {
    setButtons(false);
  }, []);

  const showButtons = useCallback(() => {
    setButtons(true);
  }, []);

  const handleDelete = useCallback(() => {
    updateComment('').then();
    setText('');
    setButtons(false);
  }, [updateComment]);

  const handleSubmit = useCallback(() => {
    updateComment(text).then();
    setButtons(false);
  },[text, updateComment]);

  return {
    text,
    buttons,
    changeText,
    hideButtons,
    showButtons,
    handleDelete,
    handleSubmit,
  };
};

export default useComment;
