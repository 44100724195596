import {useState} from 'react';
import {useParams} from 'react-router-dom';

import {CircularProgress} from '@mui/material';
import {Description} from '@mui/icons-material';

import {getPotentialCandidateDossierSenator} from '../../../../../../api/projects';
import useDocumentDialog from '../../../../../../components/DocumentDialog';
import IconButtons from "../../../../../../components/IconButtons";
import {useWithMessage} from '../../../../../Error';
import {usePotentialCandidatesContext} from '../../../hooks';

type Params = {
    selectedProjectId: string;
}

interface Props {
    candidateId: string;
}

const Dossier = ({candidateId}: Props): JSX.Element => {

    const { selectedProjectId } = useParams<Params>();
    const withMessage = useWithMessage();
    const { openDialog, DocumentDialog } = useDocumentDialog();
    const [loading, setLoading] = useState(false);
    const { refreshCandidates } = usePotentialCandidatesContext();

    const loadDossier = async () => {
        setLoading(true);
        const res = await getPotentialCandidateDossierSenator(selectedProjectId || "", candidateId).catch(withMessage());
        if (res) {
            openDialog(res.contentUrl, res.fileName, true);
            refreshCandidates();
        }
        setLoading(false);
    };

    if (loading) {
        return <CircularProgress color="secondary" size={30} />;
    }

    return (
        <>
            <IconButtons
                icons={[{ key: 'description', icon: Description, onClick: loadDossier }]}
            />
            {DocumentDialog}
        </>
    );
};

export default Dossier;
