import { Dispatch, SetStateAction, useMemo } from 'react';

import { ExitToApp, Notifications } from '@mui/icons-material';

import { useNavigate, useParams } from 'react-router-dom';
import AppBar from '../../../components/AppBar';
import Layout from '../../../components/Layout';
import useTabs from '../../../components/Tab';
import { useLoginContext } from '../../../modules/Auth';
import Details from '../../../modules/Company/Details';
import useSingleProject from './hooks';
import { ProjectsDTO } from '../../../api/projects.dto';
import { useNotificationCount } from '../../Notification/hooks';
import { useAppAbility } from '../../../casl';
import { SEE } from '../../../casl/actions';
import { NOTIFICATIONS } from '../../../casl/subjects';

interface SingleProjectProps {
  projects: ProjectsDTO[];
  setCustomerId: Dispatch<SetStateAction<string>>;
  selectedCustomerId: string;
}

const SingleProject = ({ projects, setCustomerId, selectedCustomerId }: SingleProjectProps): JSX.Element => {
  const params = useParams()
  /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
  const project: ProjectsDTO | undefined = projects.find((value) => (value._id === params.selectedProjectId));
  const { allTabs } = useSingleProject({ selectedCustomerId, project });
  const { logout } = useLoginContext();
  const navigate = useNavigate()

  const selectedTabIndex = useMemo(
    () => allTabs.findIndex((t) => t.id === params.selectedTabId),
    [allTabs, params.selectedTabId]
  );
  const { Tabs, TabContent } = useTabs(allTabs, selectedTabIndex);

  const { notificationCount } = useNotificationCount()

  const ability = useAppAbility()

  return (
    <Layout
      appBar={
        <AppBar
          clickableIcons={[
            ...(ability.can(SEE, NOTIFICATIONS) ?
              [{
                badgeNumber: notificationCount,
                key: 'notification',
                icon: Notifications,
                onClick() {
                  navigate('/projects')
                },
              }] : []),
            {
              key: 'exit',
              icon: ExitToApp,
              onClick: logout,
            },
          ]}
          title="Aufträge"
          projects={projects}
          setCustomerId={setCustomerId}
        />
      }
      header={Tabs}
      leftBody={<Details selectedCustomerId={selectedCustomerId} />}
    >
      {TabContent}
    </Layout>
  );
};

export default SingleProject;
