export default {
  jobPosting: 'Anzeige',
  receivedApplications: 'Eingegangene Bewerbungen',
  longList: 'Longlist',
  shortList: 'Shortlist',
  dynamicSpecialList: 'Dynamische Spezialliste XY',
  requirementsProfile: 'Anforderungsprofil',
  jobAd: 'Stellenanzeige',
  dossier: 'Dossier',
  report: 'StatusReport',
  documents: 'Dokumente',
  latecomer: 'Nachzügler',
  confirmTitle: 'Kunde für Kundenansicht wählen',
  confirmText: 'Wählen Sie einen Kunden aus, um die Kundenansicht zu nutzen.',
  confirmCancel: 'Abbrechen',
  confirmRelease: 'Auswählen',
};
