export interface ISelectOptions {
  name: string;
  value: number;
}

export const mapEnumToOptions = (
  values: Record<number, string>
): ISelectOptions[] => {
  const options = [] as ISelectOptions[];
  Object.keys(values).forEach((key) => 
    options.push({
      name: values[Number(key)] as string,
      value: Number(key),
    })
  );

  return options;
};
