export default {
  download: 'Download',
  basicInformation: 'Position / Stellenprofil',
  personalInformation: 'Angaben zur Person',
  hierarchyAndResponsibility: 'Hierarchie und Verantwortung',
  desiredQualifications: 'Anforderungen an den Kandidaten',
  changeReasons: 'Vorgeschichte der Position',
  remuneration: 'Dotierung',
  additionalService: 'Zusatzleistungen',
  additionalInformation: 'Weitere Angaben',
  title: 'Position',
  address: 'Arbeitsort',
  positionFunction: 'Funktion',
  tasks: 'Aufgaben, Schwerpunkte',
  specialTasks: 'Standard- / Spezialfunktion',
  superiors: 'Führungsverantwortung / MA Gesamt',
  subordinates: 'Direct Reports',
  respBudget: 'Verantwortung für Budget / Umsatz etc.',
  respEmployees: 'Verantwortung für Mitarbeiter',
  age: 'Alter',
  education: 'Ausbildung',
  career: 'Berufliche Entwicklung',
  skills: 'Fachkenntnisse',
  qualifications: 'Zusatzqualifikationen',
  leadershipExp: 'Führungserfahrung erforderlich',
  previousChanges: 'Erfolgte Wechsel',
  reasonsForEstablishment:
    'Gründe für die Einrichtung der Position',
  recruiting: 'Bisherige Rekrutierungsbemühungen',
  reasonsForChange:
    'Nennen Sie 3 Gründe, warum der Kandidat zu Ihrem Unternehmen wechseln soll',
  salary: 'Zieljahreseinkommen',
  performanceComponentsSalary: 'Erfolgsbestandteile im Gehalt',
  maxSalary: 'Maximal möglicher Betrag',
  wageGroup: 'Tarifgruppe',
  compensation: 'Erfolgsbestandteile? / Variable Komponenten',
  workingTime: 'Arbeitszeit / Urlaub',
  benefits: 'Sozialleistungen / Benefits',
  perspective: 'Perspektive / Weiterentwicklungsmöglichkeiten',
  advertLink: 'Link zu bestehender Anzeige',
  advertFile: 'Stellenanzeige / Anforderungsprofil hochladen',
  other: 'Highlights der Position / Sonstige Punkte',
  activeSearch: 'Active Sourcing',
  advert: 'Anzeigenbundle',
  searchSupport: 'Search Service',
  reportLine: 'Berichtslinie',
  departmentStructure: 'Struktur der Abteilungen',
  goalForPosition: 'Ziele der Position / anstehende Projekte', 
  softSkills: 'Soft Skills',
  koCriteria: 'K.O.-Kriterien',
  homeOfficeHandling: 'Home Office Regelung',
  familiarization: 'Einarbeitung',
  companyInformation: 'Informationen zum Unternehmen',
  blacklist: 'Sperrvermerke / Blacklist',
  whitelist: 'Wunschtargets',
  moreOther: 'Sonstiges',
  travelActivity: 'Reisetätigkeit',
  workExperience: 'Berufserfahrung'
};
