const isRequired = 'ist erforderlich';
const min6Chars = 'muss mindestens 6 Zeichen lang sein';
const onlyDigits = 'darf nur Ziffern enthalten';

export default {
  labelPassword: 'Passwort',
  labelEmail: 'E-Mail',
  labelTan: 'TAN',
  labelSmsCode: 'SMS-Code',
  emailError: 'Ungültige E-Mail Adresse',
  passwordMinError: 'Das eingegebene Passwort ist zu kurz',
  emailRequired: `E-Mail Adresse ${isRequired}`,
  passwordRequired: `Passwort ${isRequired}`,
  tanRequired: `TAN ${isRequired}`,
  smsCodeRequired: `SMS-Code ${isRequired}`,
  tanMin6Chars: `TAN ${min6Chars}`,
  smsCodeMin6Chars: `SMS-Code ${min6Chars}`,
  tanOnlyDigits: `TAN ${onlyDigits}`,
  smsCodeOnlyDigits: `SMS-Code ${onlyDigits}`,
  login: 'Login',
  loginError:{
    wrongData: 'Bitte überprüfen Sie Ihre Logindaten!',
    accountLocked: 'Ihr Nutzer ist gesperrt!',
    startPasswordExpired: 'Ihr Start Passwort ist abgelaufen',
    accessOnlyToDifferentPortal: 'Fehlende Berechtigungen zum Login für dieses Portal',
  },

  registerTan: {
    header: "2-Faktor Authentifizierung",
    step:"Schritt",
    activate2FA: "Aktivieren Sie die 2 Faktor Authentifizierung, um die Sicherheit Ihres Accounts zu erhöhen.",
    useGoogleAuthenticator: "Downloaden Sie den \"Google Authenticator\" aus dem App Store Ihres Smartphones",
    scan2FA: "Scannen Sie den QR Code mit dem \"Google Authenticator\"",
    enterTan: "Geben Sie die angezeigte sechsstellige TAN ein und schließen Sie die Aktivierung ab\n",
    tanFieldLabel: 'Initiale TAN',
    buttonValidateTan: 'TAN Validieren',
    tanNotValid: 'Ihre eingegebene TAN ist nicht gültig. Versuchen Sie es erneut!',
    buttonRedirect: 'Zum Portal'
  }
};
