import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import { ajaxTimeout } from '../config';
import { baseUrl } from '../constants';
import { clearStoredToken, readToken } from '../utils/tokenStorage';

interface Config extends AxiosRequestConfig {
  ignore401NavToLogin?: boolean;
}

export function doFetch<T>(
  url: string,
  payload: T | undefined,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT',
  options?: Config
): AxiosPromise<any> {
  let finalUrl = url;
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    finalUrl = `${baseUrl}${url}`;
  }

  let conf: AxiosRequestConfig = {
    url: finalUrl,
    method,
    data: payload,
    timeout: ajaxTimeout,
  };

  const token = readToken();
  if (token) {
    conf.headers = {
      'x-auth': token,
    };
  }

  if (options) {
    conf = {
      ...conf,
      ...{
        ...options,
        ignore401NavToLogin: undefined,
      },
      headers: {
        ...conf.headers,
        ...options.headers,
      },
    };
  }

  return axios(conf).catch((err) => {
    if (options?.ignore401NavToLogin) {
      throw err;
    }

    if (err.response?.status === 401) {
      // eslint-disable-next-line no-console
      console.error(err);

      clearStoredToken();
      window.location.href = '/';
    }

    throw err;
  });
}

export function performGet<
  T extends string | string[][] | Record<string, string> | URLSearchParams,
  R
>(url: string, params?: T, options?: Config): Promise<R> {
  // const uri = new URL(url);
  // if (params) {
  //   uri.search = new URLSearchParams(params).toString();
  // }
  return doFetch(url.toString(), undefined, 'GET', options).then(
    (res) => res.data
  );
}

export function performPost<T, R>(
  url: string,
  payload: T,
  options?: Config
): Promise<R> {
  return doFetch(url, payload, 'POST', options).then((res) => res.data);
}

export function performPut<T, R>(
  url: string,
  payload: T,
  options?: Config
): Promise<R> {
  return doFetch(url, payload, 'PUT', options).then((res) => res.data);
}

export function performPatch<T, R>(
  url: string,
  payload: T,
  options?: Config
): Promise<R> {
  return doFetch(url, payload, 'PATCH', options).then((res) => res.data);
}

export function performDelete<T, R>(
  url: string,
  payload?: T,
  options?: Config
): Promise<R> {
  return doFetch(url, payload, 'DELETE', options).then((res) => res.data);
}
