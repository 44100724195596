import { useMemo } from 'react';

import { Badge, IconButton } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

// TODO: Think about this name
export interface ClickableIcon {
  key: string;
  icon: SvgIconComponent;
  onClick?: () => void;
  badgeNumber?: number
}
interface Props {
  icons: ClickableIcon[];
  iconClassName?: string;
  isDisabled?: boolean;
}

const IconButtons = ({ icons, iconClassName, isDisabled = false }: Props): JSX.Element => (
  <>
    {useMemo(() => icons.map((element) => (
      <Badge key={element.key} color="error" invisible={!element.badgeNumber} badgeContent={element.badgeNumber}>
        <IconButton
          onClick={element.onClick}
          disabled={isDisabled}
          color="inherit"
        >
          <element.icon className={iconClassName} />
        </IconButton>
      </Badge>
    )), [icons, iconClassName, isDisabled])}
  </>
);

export default IconButtons;
