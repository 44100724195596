import React from 'react';

import Rating from '../../enums/Rating';

interface Props {
  value: Rating;
}

const ratingNumber = (value: Rating) => {
  switch (value) {
    case Rating.excellent:
      return '1';
    case Rating.veryGood:
      return '1 - 2';
    case Rating.good:
      return '2';
    case Rating.ok:
      return '3';
    default:
      return value;
  }
};
const CandidateRating = ({value}: Props): JSX.Element => (
    <div>
      <span>{ratingNumber(value)}</span>
    </div>
  );
export default CandidateRating;
