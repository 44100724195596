import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DocumentList from '../../../../modules/SingleProject/Documents/DocumentList';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
});

interface DocumentsPageProps {
  selectedCustomerId: string;
}

const DocumentsPage = ({ selectedCustomerId }: DocumentsPageProps): JSX.Element => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <DocumentList
              selectedCustomerId={selectedCustomerId}
            />
        </Paper>
    );
};

export default DocumentsPage;
