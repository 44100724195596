enum Actions {
  SEE = 'see',
  EDIT = 'edit',
  DOWNLOAD = 'download',
  PRINT = 'print',
  DOWNLOAD_TABLE_SELECTION = 'downloadTableSelection',
}
/**
 * !Important do not forget to export like this
 * For more fancy usage in Code
 */
export const { SEE, EDIT, DOWNLOAD, PRINT, DOWNLOAD_TABLE_SELECTION } =
  Actions;
