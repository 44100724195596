import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

import App from './App';
import { ErrorBoundary } from './modules/Error';
import THEME from './theme';

import './index.scss';
import './utils/i18n';

const theme = createTheme(THEME.app);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider >
        <ErrorBoundary>
          <CssBaseline />
          <App />
        </ErrorBoundary>
      </SnackbarProvider>
    </ThemeProvider>
  </StrictMode>,
  
);
