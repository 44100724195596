import { Link as LinkIcon, OpenInNew } from '@mui/icons-material';
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useCallback } from 'react';
import { LinkType } from '../../../../api/projects.dto';

interface Props {
    linkItem: LinkType;
    onItemClick?: (link: LinkType) => void;
    onItemOpen?: (link: LinkType) => void;
}

const LinkListItem: React.FC<Props> = ({ linkItem, onItemClick, onItemOpen }) => {

    const handleOnClick = useCallback(() => {
        if (onItemClick) {
            onItemClick(linkItem)
        }
    }, [onItemClick, linkItem])

    const handleOnLinkOpen = useCallback(() => {
        if (onItemOpen) {
            onItemOpen(linkItem)
        }
    }, [onItemOpen, linkItem])

    return (
        <ListItem secondaryAction={
            <IconButton>
                <OpenInNew onClick={handleOnLinkOpen} />
            </IconButton>
        }>
            <ListItemButton onClick={handleOnClick}>
                <ListItemIcon>
                    <LinkIcon />
                </ListItemIcon>
                <ListItemText primary={linkItem.name} secondary={linkItem.url} />
            </ListItemButton>
        </ListItem>
    )
}

export default LinkListItem