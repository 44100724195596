import { FormControlLabel, Grid, Switch } from '@mui/material';

interface Props {
  value: boolean | undefined;
  label: string;
}

const SwitchField = ({ value, label }: Props): JSX.Element => (
  <Grid item md={12} xs={12}>
    <FormControlLabel
      control={
        <Switch
          checked={value || false}
          disabled={value === undefined}
          inputProps={{ readOnly: true }}
        />
      }
      label={label}
    />
  </Grid>
);

export default SwitchField;
