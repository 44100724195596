import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Form from '../../../../modules/SingleProject/RequirementsProfile/Form';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface RequirementsProfileProps {
  selectedCustomerId: string;
}

const RequirementsProfile = ({ selectedCustomerId }: RequirementsProfileProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Form selectedCustomerId={selectedCustomerId} />
    </Paper>
  );
};

export default RequirementsProfile;
