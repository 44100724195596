import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import JobPosting from '../../../../modules/JobPosting';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        minHeight: '100%',
        flexGrow: 1
    },
});

const JobPostingPage = (): JSX.Element => {
    const classes = useStyles();
    return(
        <Paper className={classes.paper}>
            <JobPosting />
        </Paper>
    );
};

export default JobPostingPage;