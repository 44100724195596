import {useCallback, useState} from 'react';

import {Button, CircularProgress, Typography} from '@mui/material';

import useDocumentDialog from '../../../../../../components/DocumentDialog';
import {useWithMessage} from '../../../../../Error';
import {usePotentialCandidatesContext} from '../../../hooks';

interface Props {
  potentialCandidateRowData: any[];
  additionalDocumentsArray: any[];
}

const AdditionalDocuments = ({potentialCandidateRowData, additionalDocumentsArray}: Props): JSX.Element => {
  // We can not be sure at which index the showAdditionalDocuments flag is,
  // so we have the assumption it is one index before the additionalDocuments
  const indexOf =  potentialCandidateRowData.findIndex((item: any) => item === additionalDocumentsArray);
  const showAdditionalDocuments = potentialCandidateRowData[indexOf-1];
  const potentialCandidateId = potentialCandidateRowData[0];
  const {getProjectCandidateAdditionalDocument} = usePotentialCandidatesContext();
  const {openDialog, DocumentDialog} = useDocumentDialog();
  const withMessage = useWithMessage();
  const [isLoading, setIsLoading] = useState(false);

  const handleTextLinkClicked = useCallback(async (selectedAdditionalDocument: any) => {
    setIsLoading(true);
    await
      getProjectCandidateAdditionalDocument(selectedAdditionalDocument.documentName, potentialCandidateId)
      .then((res) => {
        setIsLoading(false);
        if(res) {
          openDialog(res.contentUrl , res.fileName, true);
        }
        return res;
      })
      .catch(withMessage());
  }, [getProjectCandidateAdditionalDocument, openDialog, potentialCandidateId, withMessage]);

  if (isLoading) {
    return <CircularProgress color="secondary" size={30}/>;
  }

  return (
    <>
      { showAdditionalDocuments && additionalDocumentsArray
        && additionalDocumentsArray.length > 0 && additionalDocumentsArray.map(
        (additionalDocument: any) =>
        <Button key={`${additionalDocument.documentName}`} onClick={() => handleTextLinkClicked(additionalDocument)}>
          <Typography key={`${additionalDocument.documentName}`}  variant="caption" align="left">
            {additionalDocument.documentName.substring(0,35)}
          </Typography>
          {/* {additionalDocument.documentName} */}
        </Button>)
      }
      {DocumentDialog}
    </>
  );
};

export default AdditionalDocuments;
