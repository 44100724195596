import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch, SetStateAction } from 'react';
import { GetCustomerListResponseDTO } from '../../api/projects.dto';
import ChangeUser from '../ChangeUser/ChangeUser';

interface ConfirmDialogProps {
  onClose: (agree: boolean) => void;
  title: string;
  text: string;
  cancelText: string;
  okText: string;
  open: boolean;
  customerList?: GetCustomerListResponseDTO[];
  setSelectedCustomerId?: Dispatch<SetStateAction<string>>;
  selectedCustomerId?: string;
}

const ChangeUserConfirmDialog = ({
  onClose,
  open,
  title,
  text,
  cancelText,
  okText,
  customerList,
  setSelectedCustomerId = () => {},
  selectedCustomerId = '',
}: ConfirmDialogProps): JSX.Element => {
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {customerList && (
          <ChangeUser
            customerList={customerList}
            setSelectedCustomerId={setSelectedCustomerId}
            selectedCustomerId={selectedCustomerId}
          />
        )}
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelText}
        </Button>
        <Button onClick={handleOk} color="primary">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeUserConfirmDialog;
