enum TableType {
  RECEIVED_APPLICATIONS = 'receivedApplications',
  LONG_LIST = 'longList',
  SHORT_LIST = 'shortList',
  LATECOMER_LIST = 'latecomerList',
  DYNAMIC_SPECIAL_LIST = 'dynamicSpecialList',
  CANDIDATE_DOSSIER_LIST = 'candidateDossierList',
}

export default TableType;
