import React from "react";
import {useTranslation} from "react-i18next";

import {Box, Typography} from "@mui/material";

import {useAppAbility} from "../../../casl";
import {SEE} from "../../../casl/actions";
import {COMPANY_DETAILS} from "../../../casl/subjects";
import NamedField from '../../../components/NamedField';
import PaddedDivider from "../../../components/PaddedDivider";
import {dateTimeFormatter} from "../../../utils/helperFunctions";
import {Informations} from "../../SingleProject/RequirementsProfile/hooks";

interface Props {
  information?: Informations;
}

const Fields = ({information}:Props): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const ability = useAppAbility();
  return(
      <>
        { ability.can(SEE, COMPANY_DETAILS) ?
          ( <NamedField
              title={t('company')}
              value={information?.companyName}
              mb={2}
            /> ) :
          ( <Box mb={2}>
                <Typography variant="body2">{information?.companyName}</Typography>
            </Box> )
        }
        { ability.can(SEE, COMPANY_DETAILS) && (
          <>
            <NamedField
              title={t('titleInternal')}
              value={information?.jobTitle1}
              mb={2}
            />
            <NamedField
              title={t('projectNumber')}
              value={information?.projectNumber}
              mb={2}
            />
            <NamedField
              title={t('creationDate')}
              value={dateTimeFormatter(information?.creationDate ? information?.creationDate : '', 'dd.LL.yyyy')}
              mb={2}
            />
            <PaddedDivider/>
            <NamedField
              title={t('serviceType')}
              value={information?.serviceType}
              mb={2}
            />
          </> )
        }
        <NamedField
          title={t('consultant')}
          value={`${information?.consultingUser?.firstName  } ${  information?.consultingUser?.lastName}`}
          mb={2}
        />
      </>
    );
  }
;

export default Fields;
