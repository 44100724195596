import { performPost } from './apiUtils';
import { NotificationsDTO } from './notifications.dto';

export async function getClientNotifications(): Promise<NotificationsDTO[]> {
  const result = await performPost<undefined, NotificationsDTO[]>(
    '/getAllNotificationsOfClientUser',
    undefined
  );
  return result;
}

export async function getClientNotificationsCount(): Promise<{
  count: number;
}> {
  return performPost<undefined, { count: number }>(
    '/getCountClientNotification',
    undefined
  );
}

export async function markClientNotificationAsRead(
  notificationIds: string[]
): Promise<void> {
  return performPost<{ notificationIds: string[] }, void>(
    '/markClientNotificationsAsRead',
    { notificationIds }
  );
}
