import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {FormikHelpers, FormikProps, useFormik} from 'formik';
import * as yup from 'yup';

import registerApiCall, {RegisterBodyDTO} from '../../api/register';
import TEXT from '../../text';
import logger from '../../utils/logger';
import {useLoginContext} from '../Auth';
import {LoginPhase} from '../Auth/hook';
import {useWithMessage} from '../Error';

interface RegisterFields {
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeated: string;
  checkBoxChecked: boolean;
}

const useRegisterFormHook = (): RegisterState => {
  const { t } = useTranslation('auth');

  const loginState = useLoginContext();

  const withMessage = useWithMessage();

  // Password validation
  const [isPasswordEightLong, setIsPasswordEightLong] =
    useState<boolean>(false);
  const [containsSpecialCharacters, setContainsSpecialCharacters] =
    useState<boolean>(false);
  const [containsNumbers, setContainsNumbers] = useState<boolean>(false);
  const [containsCapitalLetter, setContainsCapitalLetter] =
    useState<boolean>(false);
  const [containsLowerLetter, setContainsLowerLetter] =
    useState<boolean>(false);
  const [passwordsEqual, setPasswordEquals] = useState<boolean>(false);

  const validationRegisterSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(t('passwordRequired'))
          .min(8, TEXT.registerPage.passwordRequirements.eightCharacters)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]/,
            'Password must match the requirements'
          ),
        passwordRepeated: yup
          .string()
          .oneOf(
            [yup.ref('password'), null],
            TEXT.registerPage.errorMsg.passwordNotEqual
          )
          .required(TEXT.registerPage.errorMsg.passwordNotEqual),
        checkBoxChecked: yup
          .bool()
          .isTrue(TEXT.registerPage.errorMsg.notAgreed),
        firstName: yup
          .string()
          .required(TEXT.registerPage.errorMsg.fistNameRequired),
        lastName: yup
          .string()
          .required(TEXT.registerPage.errorMsg.lastNameRequired),
      }),
    [t]
  );

  const initialRegisterValues: RegisterFields = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      password: '',
      passwordRepeated: '',
      checkBoxChecked: false,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values: RegisterFields, formikHelpers: FormikHelpers<any>) => {
      formikHelpers.setSubmitting(true);
      registerApiCall({
        firstname: values.firstName,
        lastname: values.lastName,
        password: values.password,
        passwordRepeated: values.passwordRepeated,
      })
        .then((response) => response.data)
        .then((response: RegisterBodyDTO) => {
          logger.debug('API /register response body: ', response);
          if (response) {
            loginState.setLoginPhase(LoginPhase.LOGIN);
          }
        }).catch(withMessage());

      formikHelpers.setSubmitting(false);
    },
    [loginState, withMessage]
  );

  const validatePassword = useCallback((values: RegisterFields) => {
    const passwordToValidate = values.password as string;
    const passwordRepeated = values.passwordRepeated as string;
    const passwordEightLong = passwordToValidate.length > 7;
    const passwordContainsSpecialCharacter =
      passwordToValidate.match(/(?=.*[\W_])/);
    const passwordContainsNumber = passwordToValidate.match(/(?=.*[0-9])/);
    const passwordContainsUpperCharacter =
      passwordToValidate.match(/(?=.*[A-Z])/);
    const passwordContainsLowerCharacter =
      passwordToValidate.match(/(?=.*[a-z])/);
    if (passwordEightLong) setIsPasswordEightLong(true);
    else setIsPasswordEightLong(false);
    if (passwordContainsSpecialCharacter) setContainsSpecialCharacters(true);
    else setContainsSpecialCharacters(false);
    if (passwordContainsNumber) setContainsNumbers(true);
    else setContainsNumbers(false);
    if (passwordContainsUpperCharacter) setContainsCapitalLetter(true);
    else setContainsCapitalLetter(false);
    if (passwordContainsLowerCharacter) setContainsLowerLetter(true);
    else setContainsLowerLetter(false);
    if (passwordRepeated === passwordToValidate && passwordRepeated)
      setPasswordEquals(true);
    else setPasswordEquals(false);
  }, []);

  const formik: FormikProps<RegisterFields> = useFormik<RegisterFields>({
    initialValues: initialRegisterValues,
    validationSchema: validationRegisterSchema,
    validate: validatePassword,
    onSubmit: handleSubmit,
    isInitialValid: false,
  });

  return {
    formik,
    isPasswordEightLong,
    containsSpecialCharacters,
    containsNumbers,
    containsCapitalLetter,
    containsLowerLetter,
    passwordsEqual
  };
};

export { useRegisterFormHook };

interface RegisterState {
  formik: FormikProps<RegisterFields>;
  isPasswordEightLong: boolean;
  containsSpecialCharacters: boolean;
  containsNumbers: boolean;
  containsCapitalLetter: boolean;
  containsLowerLetter: boolean;
  passwordsEqual: boolean;
}
