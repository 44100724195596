import { partner } from './constants';

const TEXT = {
  registerPage: {
    inputFields: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      password: 'Neues Passwort wählen',
      passwordRepeated: 'Neues Passwort wiederholen',
      phoneNumber: 'Telefonnummer',
      email: 'E-Mail',
      names: {
        firstName: 'firstName',
        lastName: 'lastName',
        password: 'password',
        passwordRepeated: 'passwordRepeated',
      },
    },
    passwordRequirements: {
      eightCharacters: 'Mindestenslänge 8 Zeichen',
      oneSpecialCharacter: 'Mindestens ein Sonderzeichen',
      oneNumber: 'Mindestens eine Zahl',
      oneUpperLetter: 'Mindestens einen Großbuchstaben',
      oneLowerLetter: 'Mindestens einen Kleinbuchstaben',
    },
    button: 'Registrierung abschließen',
    imprint: 'Impressum',
    privacy: 'Datenschutzerklärung',
    errorMsg: {
      newAndOldPasswordIsIdenticital: 'Bitte wählen Sie ein neues Passwort',
      fistNameRequired: 'Bitte Ihren Vornamen eingeben',
      passwordNotEqual: 'Passwort stimmt nicht überein',
      lastNameRequired: 'Bitte Ihren Nachnamen eingeben',
      notAgreed: 'Bitte Nutzungsbedingungen zustimmen',
    },
    checkBox: {
      licenseLienert:
        'Ich akzeptiere die besonderen Nutzungsbestimmungen sowie Datenschutzbestimmungen seitens der Jörg Lienert AG',

      license:
        'Ich akzeptiere die besonderen Nutzungsbestimmungen sowie Datenschutzbestimmungen',
    },
  },
  loginPage: {
    headline: 'Login Seite',
    errorMsg: {
      error400: 'Bitte überprüfen Sie Ihre Logindaten!',
      accountLocked: 'Ihr Nutzer ist gesperrt!',
      startPasswordExpired: 'Ihr Start Passwort ist abgelaufen',
      accessOnlyToDifferentPortal:
        'Fehlende Berechtigungen zum Login für dieses Portal',
    },
    inputfield: {
      email: {
        label: 'E-Mail',
      },
      password: {
        label: 'Passwort',
      },
      tan: {
        label: 'TAN',
      },
    },
    button: 'Login',
    imageAlt: `${partner} logo `,
  },
  resultPage: {
    errorUserTrysToSelectMoreThan10Candidates:
      'Sie können maximal 10 Kandidaten markieren',
    resetFiltersButton: 'Filter leeren',
    sortingOptions: {
      matchPercentage: 'Passgenauigkeit',
      willingnessToChange: 'Wechselwahrscheinlichkeit',
      postalcode: 'Entfernung',
      age: 'Alter',
    },
    skillDetails: {
      languageTableHeading: {
        leftColumn: 'Sprache',
        rightColumn: 'Grad der Beherrschung',
      },
      otherSkillsTableHeading: {
        leftColumn: 'EDV-/Sonstige Kenntnisse',
        rightColumn: 'Grad der Beherrschung',
      },
    },
    sideBar: {
      skillLevel0: 'Anfänger',
      skillLevel1: 'Grundkenntnisse (A1)',
      skillLevel2: 'erweiterte Grundkenntnisse (A2)',
      skillLevel3: 'gute Kenntnisse (B1)',
      skillLevel4: 'sehr gute Kenntnisse (B2)',
      skillLevel5: 'verhandlungssicher / fließend (C1)',
      skillLevel6: 'Muttersprache / Dolmetscher (C2)',
      documentsView: {
        selectDoc: {
          documentName: 'Dokument',
          label: 'Dokument auswählen',
        },
        downloadButton: 'Dokument herunterladen',
        pdfErrorNoDocumentAvailable: 'Kein Dokument vorhanden.',
        candidateHasNoDocs: 'Dieser Kandidat verfügt über keine Dokumente.',
        fileOnlyAsDownload:
          'Diese Datei wird nur als Download bereit gestellt.',
      },
    },
    selectCandidateForSidebar:
      'Wählen Sie einen Kandidaten aus, um weitere Informationen anzuzeigen.',
    amountOfHits: 'Anzahl Treffer:',
    labelRowsPerPage: 'Kandidaten pro Seite:',
    labelPaginationFromTo1: 'bis',
    labelPaginationFromTo2: 'aus',
    boostYourResults: 'Gewichten Sie Ihre Suche',
    adoptChanges: 'Änderungen übernehmen',
    boostDescription:
      'Markieren Sie einzelne Suchbegriffe, die für Sie besonders relevant sind',
    results: 'Suchergebnisse:',
    noJobDescription: 'Keine Angaben zum Job oder den Branchen.',
    lastJobs: 'Bisherige Positionen:',
    languageSkills: 'Sprachkentnisse:',
    additionalSkills: 'EDV-Kentnisse:',
    branches: 'Branchen:',
    description: 'Berufstätigkeit:',
    noJobDuration: 'Anstellungsdauer nicht vohanden.',
    available: 'verfügbar',
    notAvailable: 'nicht verfügbar',
    age: 'Alter:',
    lastChange: 'Letzte Änderung:',
    phoneNumber: 'Telefon:',
    mailAddress: 'E-Mail:',
    candidateId: 'Kandidatennummer',
    addToProject: 'Zur Interviewliste hinzufügen',
    containedInProject: 'Im Auftrag vorhanden',
    textCopied: 'Kandidatennummer wurde kopiert.',
    expandButton: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen',
    },
    addToOrder: 'zu Auftrag hinzufügen',
    matchPercentage: 'Passgenauigkeit',
    willingnessToChange: 'Wechsel&shy;wahr&shy;schein&shy;lich&shy;keit',
    noResults: 'Keine Ergebnisse passen zu Ihren Filterkriterien.',
    company: 'Name der Firma',
    firstName: 'Vorname',
    lastName: 'Nachname',
    lastPositions: {
      currentPosition: 'Derzeitige Position (',
      formerPosition: 'Letzte Position (',
      years: 'Jahre',
      months: 'Monate',
    },
    sendOrderBox: {
      sendOrder: 'Interviewanfrage absenden',
      yourCandidates: 'Ihre ausgewählten Kandidaten:',
      yourOrder: 'Ihre Interviewanfrage',
      lastJob: 'Letzer Job:',
      success: 'Ihre Interviewanfrage wurde erfolgreich übermittelt!',
      successText:
        'Wir werden uns sobald wie möglich mit Ihnen in Verbindung setzen.',
      startNextSearch: 'Weitere Suche starten',
    },
    saveSearchBox: {
      headline: 'Suche speichern',
      description:
        'Bitte geben Sie einen Namen für Ihre zu speichernde Suche ein:',
      labelInputField: 'Beschreibung der Suche',
      leftButton: 'Abbrechen',
      rightButton: 'Speichern',
    },
  },
  errorPage: {
    headlineLarge: 'Oh, No...',
    headlineSmall: 'Technischer Fehler',
    infoMessage:
      'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späterem Zeitpunkt erneut.',
  },
  projectDetailPage: {
    tabsHeader: {
      presentedCandidates: 'Präsentierte Kandidaten',
      receivedApplications: 'Eingegangene Bewerbungen',
      longList: 'Longlist',
      shortList: 'Shortlist',
      dynamicSpecialList: 'Dynamische Spezialliste XY',
      requirementsProfile: 'Anforderungsprofil',
      jobAd: 'Stellenanzeige',
      dossier: 'Dossier',
      report: 'StatusReport',
      documents: 'Dokumente',
      latecomer: 'Nachzügler',
    },
    projectDetailTable: {
      title: 'Eingegangene Bewerbung',
      shortlist: 'Shortlist',
      longList: 'Longlist',
      columns: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        dateOfBirth: 'Geburtsdatum',
        duration: 'Zeitraum',
        city: 'Wohnort',
        rating: 'Bewertung',
        currentCompany: 'Unternehmen',
        education: 'Ausbildung',
        position: 'Position',
        documentName: 'Dossier',
        comment: 'Bemerkung',
        additionalDocuments: 'ergänzende Dokumente',
      },
    },
  },
};
export default TEXT;
