import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button, createTheme, Grid, ThemeProvider, Paper } from '@mui/material';

import { LoginForm, RegisterTan } from '../../modules/Auth';
import { LoginPhase, useLoginContext } from '../../modules/Auth/hook';
import THEME from '../../theme';
import Register from '../Register/Register';

import './Login.css';

const footerTheme = createTheme(THEME.app);

const Login = (): JSX.Element => {
  const { t } = useTranslation('loginPage');
  const { loginPhase, loggedIn } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      navigate('/projects');
    }
  }, [loggedIn, navigate]);

  return (
    <Paper>
      {loginPhase === LoginPhase.LOGIN || loginPhase === LoginPhase.TAN ? (
        <>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
          >
            <Grid item md={4} xs={6}>
              <LoginForm />
            </Grid>
          </Grid>
          <div id="footer">
            <ThemeProvider theme={footerTheme}>
              <Button component={Link} to="/impressum">
                {t('imprint')}
              </Button>
              <Button component={Link} to="/datenschutz">
                {' '}
                {/* Imprint contains also the privacy info */}
                {t('privacy')}
              </Button>
            </ThemeProvider>
          </div>
        </>
      ) : (
        null
      )}
      {loginPhase === LoginPhase.REGISTER_TAN ? (
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item>
            <RegisterTan />
          </Grid>
        </Grid>
      ) : (
        null
      )}
      {loginPhase === LoginPhase.REGISTER ? (
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Grid item md={6}>
              <Register />
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
};

export default Login;
