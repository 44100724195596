import { Box, Paper } from '@mui/material';
import PaddedDivider from '../PaddedDivider';

interface Props {
  header: JSX.Element;
  children?: JSX.Element;
  height?: string;
}

const HeaderedPaper = ({ header, children, height }: Props): JSX.Element => {
  return (
    <Paper sx={{height}} elevation={4}>
      <Box sx={{height, overflow: 'auto'}} p={3}>
        {header}
        <PaddedDivider />
        {children}
      </Box>
    </Paper>
  );
};

export default HeaderedPaper;
