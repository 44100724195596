import { useState } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
interface Props {
  /**
   * Base 64 string
   */
  file: string | undefined;
}

const useClasses = makeStyles({
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
  },
  box: {
    padding: '10px',
    display: 'flex',
    flexGrow: 1,
  },
  page: {
    display: 'flex',
    flexGrow: 1,
  },
});

const DocumentViewer = ({ file }: Props): JSX.Element => {
  const [numberOfPages, setNumberOfPages] = useState<null | number>(null);
  const classes = useClasses();

  const renderPages = (): JSX.Element[] | undefined => {
    const pages: JSX.Element[] = [];
    if (!numberOfPages) {
      return pages;
    }
    for (let i = 1; i <= numberOfPages; i += 1) {
      pages.push(
        <Box key={i} mb={3} mt={3}>
          <Page
            className={classes.page}
            key={i}
            loading="Lade PDF..."
            pageNumber={i}
          />
        </Box>
      );
    }

    return pages;
  };
  return (
    <Document
      className={classes.document}
      file={file}
      renderMode="canvas"
      // eslint-disable-next-line no-console
      onLoadError={console.error}
      onLoadSuccess={(pdf) => setNumberOfPages(pdf.numPages)}
    >
      {renderPages()}
    </Document>
  );
};

export default DocumentViewer;
