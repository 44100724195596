import { useTranslation } from 'react-i18next';

import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { Can } from '../../../../../../casl';
import { SEE } from '../../../../../../casl/actions';
import {
  LATECOMER_LIST,
  LONG_LIST,
  RECEIVED_APPLICATIONS,
  SHORT_LIST,
} from '../../../../../../casl/subjects';
import TableType from '../../../../../../enums/TableType';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
});

interface Props {
  columnId: string;
  project: {
    hasLongList: false,
    hasShortList: false,
    hasLatecomer: false
  };
}

const TabShortcuts = ({ columnId, project }: Props): JSX.Element => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation('projectListTable');
  const pushToPotentialCandidatesTable = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tabId: string
  ) => {
    /**
     * else the onRowClick event would fire
     */
    e.stopPropagation();
    navigate(`details/${columnId}/${tabId}`);
  };
  return (
    <Grid className={classes.container} container>
      <Can I={SEE} a={RECEIVED_APPLICATIONS}>
        <Grid item lg={3}>
          <Button
            onClick={(e) =>
              pushToPotentialCandidatesTable(e, TableType.RECEIVED_APPLICATIONS)
            }
            size="small"
          >
            &gt; {t('receivedApplications')}
          </Button>
        </Grid>
      </Can>
      <Can I={SEE} a={LONG_LIST}>
        {project.hasLongList ?
          <Grid item lg={3}>
            <Button
              onClick={(e) =>
                pushToPotentialCandidatesTable(e, TableType.LONG_LIST)
              }
              size="small"
            >
              &gt; {t('longList')}
            </Button>
          </Grid>
          : null
        }
      </Can>
      <Can I={SEE} a={LATECOMER_LIST}>
        {project.hasLatecomer ?
          <Grid item lg={3}>
            <Button
              onClick={(e) =>
                pushToPotentialCandidatesTable(e, TableType.LATECOMER_LIST)
              }
              size="small"
            >
              &gt; {t('latecomerList')}
            </Button>
          </Grid>
          :null
        }
      </Can>
      <Can I={SEE} a={SHORT_LIST}>
        {project.hasShortList ?
          <Grid item lg={3}>
            <Button
              onClick={(e) =>
                pushToPotentialCandidatesTable(e, TableType.SHORT_LIST)
              }
              size="small"
            >
              &gt; {t('shortList')}
            </Button>
          </Grid>
          : null
        }
      </Can>
    </Grid>
  );
};

export default TabShortcuts;
