import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ProjectsDTO } from '../../../../api/projects.dto';
import DataTable from '../../../../components/DataTable';
import getColumns from './Columns';

interface TableProps {
  projects: ProjectsDTO[];
}

const Table = ({projects}: TableProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation('projectListTable');

  const showSingleProjectPage = useCallback(
    (selectedProjectId: string) => {
      navigate(`details/${selectedProjectId}/longList`);
    },
    [navigate]
  );
  return (
    <DataTable
      columns={useMemo(() => getColumns(t), [t])}
      data={projects}
      onRowClick={(value) => showSingleProjectPage(value[0])}
      title={t('currentProjects')}
    />
  );
};
export default Table;
