export enum NotificationType  {
    PROJECT_RELEASED = 'clientProjectRelease',
    UPDATED_JOB_POSTING = 'clientJobPostingUpdated',
    NEW_DOSSIER_ADDED = 'clientNewDossierAdded',
    NEW_DOKUMENT_ADDED = 'clientNewDokumentAdded',
    NEW_LINK_ADDED = 'clientNewLinkAdded',
}

export interface NotificationsDTO {
    id: string;
    user: string;
    type: NotificationType,
    triggerDate: string,
    isRead: boolean,
    notificationObject: Record<string, any>
}

export interface UpdateNotificationDTO {
    id: string,
    isRead?: boolean,
}