import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';

import { loginApiCall, LoginRequestDTO, verifyToken } from '../../api/login';
import { isDevelopment, isLienert, partner } from '../../constants';
import { LoginFormFieldsValues } from '../../models/LoginFormFieldsValues';
import RequestTan from '../../models/RequestTanEnum';
import TEXT from '../../text';
import logger from '../../utils/logger';
import {
  clearStoredToken,
  readToken,
  storeToken,
} from '../../utils/tokenStorage';

interface User {
  role: string;
}

export enum LoginPhase {
  AUTO_LOGIN,
  LOGIN,
  TAN,
  REGISTER_TAN,
  REGISTER,
  FINISHED,
}

export interface LoginState {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  tanType: RequestTan;
  tan: string;
  setTan: React.Dispatch<React.SetStateAction<string>>;
  errorMsg: string;
  loginCall: (values: LoginFormFieldsValues) => Promise<void>;
  logout: () => void;
  user?: User;

  loggedIn?: boolean;
  loginPhase: LoginPhase;
  setLoginPhase: (phase: LoginPhase) => void;
}

const getAuthenticationSolutionByPartner = (user?: User):
  | RequestTan.SMS
  | RequestTan.TAN => {
    if (isLienert && !isDevelopment && user?.role !== "admin")  {
    return RequestTan.SMS;
  }
  return RequestTan.TAN;
};

const useNewLoginState = (): LoginState => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState<string>('');

  const [errorMsg, setErrorMsg] = useState('');
  const [tan, setTan] = useState('');
  const [loginPhase, setLoginPhase] = useState(LoginPhase.AUTO_LOGIN);

  const [user, setUser] = useState<User>();

  const { t } = useTranslation('auth');

  useEffect(() => {
    if (loginPhase !== LoginPhase.AUTO_LOGIN) {
      return;
    }

    const token = readToken();
    if (token) {
      verifyToken()
        .then((r) => {
          if (r.data.user.status === 'active') {
            setLoginPhase(LoginPhase.FINISHED);
            setUser({
              role: r.data.user.roles[0]
            });
            return;
          }
          setLoginPhase(LoginPhase.LOGIN);
        })
        .catch(() => {
          setLoginPhase(LoginPhase.LOGIN);
        });
    } else {
      setLoginPhase(LoginPhase.LOGIN);
    }
  }, [loginPhase]);

  const logout = () => {
    clearStoredToken();
    setUser(undefined);
    setLoginPhase(LoginPhase.LOGIN);
  };

  const loginCall = useCallback(
    async (values: LoginFormFieldsValues) => {
      const requestBody: LoginRequestDTO = {
        email: values.email.toLowerCase(),
        password: values.password,
        company: partner,
      };
      const authenticationSolutionKey = getAuthenticationSolutionByPartner(user);
      requestBody[authenticationSolutionKey] = values.tan;

      loginApiCall(requestBody)
        .then((response) => {
          if (response.status !== 200) {
            logger.error('error', response);
          }
          return response;
        })
        .then((response) => {
          setErrorMsg('');

          const responseBody = response.data;

          if (response.headers['x-auth']) {
            storeToken(response.headers['x-auth']);
          }

          // check for returned status of user
          switch (responseBody.status) {
            case 'invited':
            case 'reinvited':
            case 'initialLogin':
              setLoginPhase(LoginPhase.REGISTER);
              return;
            case 'passwordSet':
              setLoginPhase(LoginPhase.REGISTER_TAN);
              return;
            case 'active':
              if (responseBody.roles && responseBody.roles.length > 0 ) {
                setUser({
                  role: responseBody.roles[0],
                })
              }
              if (response.headers['x-auth']) {
                // regular login of active user with/without tan validation
                logger.debug('active User: Valid login');
                setLoginPhase(LoginPhase.FINISHED);
                setUser({
                  role: responseBody.roles[0]
                });
              } else {
                logger.debug('active User: Tan required');
                setLoginPhase(LoginPhase.TAN);
              }
              return;
            default:
              // else case should not occur as these cases are handled in the error handling below
              logger.debug('WARNING unexpected user.status');
              //   props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
              setErrorMsg(TEXT.loginPage.errorMsg.error400);
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            setErrorMsg(t('loginError.wrongData'));
          } else if (error.response?.status === 401) {
            if (error.response?.statusText === 'startPasswordExpired')
              setErrorMsg(t('loginError.startPasswordExpired'));
            else if (
              error.response?.statusText === 'accessOnlyToDifferentPortal'
            )
              setErrorMsg(t('loginError.accessOnlyToDifferentPortal'));
            else setErrorMsg(t('loginError.accountLocked'));
          } else {
            logger.error('error', error);
          }
        });
    },
    [t, user]
  );

  return {
    email,
    setEmail,
    password,
    setPassword,
    tanType:
      loginPhase === LoginPhase.TAN
        ? getAuthenticationSolutionByPartner(user)
        : RequestTan.None,
    tan,
    setTan,
    errorMsg,
    loginCall,
    logout,
    user,
    loginPhase,
    loggedIn:
      loginPhase === LoginPhase.AUTO_LOGIN
        ? undefined
        : loginPhase === LoginPhase.FINISHED,
    setLoginPhase,
  };
};

export default useNewLoginState;

const AuthContext = createContext<LoginState | undefined>(undefined);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const loginState = useNewLoginState();
  return (
    <AuthContext.Provider value={loginState}>{children}</AuthContext.Provider>
  );
};

export const useLoginContext = (): LoginState => {
  const loginState = useContext(AuthContext);
  if (loginState === undefined) {
    throw new Error('login state not injected');
  }

  return loginState;
};
