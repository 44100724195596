import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppAbility } from '../../../casl';
import { SEE } from '../../../casl/actions';
import {
  DOCUMENTS,
  DOSSIER,
  JOB_AD,
  LATECOMER_LIST,
  LONG_LIST,
  RECEIVED_APPLICATIONS,
  REPORT,
  SHORT_LIST,
  SINGLE_PROJECT_INFORMATION,
} from '../../../casl/subjects';
import { TabConfig } from '../../../components/Tab/hooks';
import TableType from '../../../enums/TableType';
import Candidates from './Candidates';
import DocumentsPage from './Documents/Documents';
// import Dossier from './Dossier';
import JobPostingPage from './JobPostingPage';
import Report from './Report';
import RequirementsProfile from './RequirementsProfile';
import { ProjectsDTO } from '../../../api/projects.dto';

interface UseSingleProjectProps {
  selectedCustomerId: string;
  project?: ProjectsDTO;
}

const useSingleProject = ({ selectedCustomerId, project }: UseSingleProjectProps): { allTabs: TabConfig[] } => {
  const ability = useAppAbility();
  const { t } = useTranslation('singleProject');

  const allTabs = useMemo(() => {
    const tabs = [
      {
        id: 'receivedApplications',
        hidden: ability.cannot(SEE, RECEIVED_APPLICATIONS),
        title: t('receivedApplications'),
        content: <Candidates tableType={TableType.RECEIVED_APPLICATIONS} selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'longList',
        hidden: ability.cannot(SEE, LONG_LIST) || !project?.tabShortcuts?.hasLongList,
        title: t('longList'),
        content: <Candidates tableType={TableType.LONG_LIST} selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'latecomerList',
        hidden: ability.cannot(SEE, LATECOMER_LIST) || !project?.tabShortcuts?.hasLatecomer,
        title: t('latecomer'),
        content: <Candidates tableType={TableType.LATECOMER_LIST} selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'shortList',
        hidden: ability.cannot(SEE, SHORT_LIST) || !project?.tabShortcuts?.hasShortList,
        title: t('shortList'),
        content: <Candidates tableType={TableType.SHORT_LIST} selectedCustomerId={selectedCustomerId} />,
      },
      // {
      //   id: 'dynamicSpecialList',
      //   hidden: ability.cannot(SEE, DYNAMIC_SPECIAL_LIST),
      //   title: t('dynamicSpecialList'),
      //   content: <Candidates tableType={TableType.DYNAMIC_SPECIAL_LIST} />,
      // },
      {
        id: 'requirementsProfile',
        hidden: ability.cannot(SEE, SINGLE_PROJECT_INFORMATION),
        title: t('requirementsProfile'),
        content: <RequirementsProfile selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'jobAd',
        hidden: ability.cannot(SEE, JOB_AD),
        title: t('jobAd'),
        content: <JobPostingPage />,
      },
      {
        id: 'dossier',
        hidden: ability.cannot(SEE, DOSSIER),
        title: t('dossier'),
        content: <Candidates tableType={TableType.CANDIDATE_DOSSIER_LIST} selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'report',
        hidden: ability.cannot(SEE, REPORT),
        title: t('report'),
        content: <Report selectedCustomerId={selectedCustomerId} />,
      },
      {
        id: 'documents',
        hidden: ability.cannot(SEE, DOCUMENTS),
        title: t('documents'),
        content: <DocumentsPage selectedCustomerId={selectedCustomerId}/>,
      },
    ];

    return tabs.filter((tab) => !tab.hidden);
  }, [ability, t, selectedCustomerId, project?.tabShortcuts?.hasLongList, project?.tabShortcuts?.hasLatecomer, project?.tabShortcuts?.hasShortList]);

  return {
    allTabs,
  };
};

export default useSingleProject;
