import {
  Grid,
  ThemeProvider,
  Slider,
  Typography,
} from '@mui/material';

import THEME from '../../theme';

interface Mark {
  value: number;
  label?: React.ReactNode;
}

interface Props {
  min: number;
  max: number;
  step: number;
  label: string;
  value?: number | number[];
  marks: boolean | Mark[] | undefined;
}

const theme = {
  overrides: {
    // to remove the disabled look
    MuiSlider: {
      root: {
        '& *': {
          color: THEME.app.palette.primary.main,
        },
      },
    },
  },
};

const SliderField = ({
  min,
  max,
  step,
  value,
  label,
  marks,
}: Props): JSX.Element => (
  <Grid item xs={12}>
    <ThemeProvider theme={theme}>
      <Typography gutterBottom id={`${value}_${label}`}>
        {label}
      </Typography>
      <Slider
        aria-labelledby={`${value}_${label}`}
        disabled
        marks={marks}
        max={max}
        min={min}
        step={step}
        value={value || []}
      />
    </ThemeProvider>
  </Grid>
);

export default SliderField;
