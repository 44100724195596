import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';

type Params = {
  text: string;
  onSuccess: (candidateId: string) => void;
};

type OpenDialogFn = ({ text, onSuccess }: Params) => void;

const InterviewDialogContext = createContext<OpenDialogFn | undefined>(
  undefined
);

export const useInterviewDialog = (): OpenDialogFn => {
  const openDialog = useContext(InterviewDialogContext);
  if (openDialog === undefined) {
    throw new Error('interview dialog context has to be provided');
  }

  return openDialog;
};

interface Props {
  children: ReactNode;
}

export const InterviewDialogProvider = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation('table');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [handleSuccess, setHandleSuccess] =
    useState<(selectedId: string) => void>();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setHandleSuccess(undefined);
  }, []);

  const handleSave = useCallback(
    async (interviewText: string) => {
      if (handleSuccess) {
        handleSuccess(interviewText);
      }
      handleClose();
    },
    [handleClose, handleSuccess]
  );

  const formik = useFormik({
    initialValues: {
      textField: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSave(values.textField);
    },
  });

  const handleOpenDialog = useCallback(
    ({ text, onSuccess }: Params) => {
      setIsOpen(true);
      setHandleSuccess(() => onSuccess);

      formik.setFieldValue('textField', text);
    },
    [formik]
  );

  return (
    <>
      <Dialog fullWidth open={isOpen}>
        <DialogTitle>
          {t('interviewDialog.header')}
          {/* <Typography variant="subtitle1">{t('interviewDialog.header')}</Typography> */}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              id="textField"
              name="textField"
              fullWidth
              value={formik.values.textField || ''}
              onChange={formik.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('interviewDialog.closeButton')}
            </Button>
            <Button type="submit">{t('interviewDialog.saveButton')}</Button>
          </DialogActions>
        </form>
      </Dialog>
      <InterviewDialogContext.Provider value={handleOpenDialog}>
        {children}
      </InterviewDialogContext.Provider>
    </>
  );
};
