import { List, Typography } from '@mui/material';
import React from 'react';
import { LinkType } from '../../../../api/projects.dto';
import LinkListItem from './LinkListItem';

interface Props {
  links: LinkType[],
  onLinkClick?: (link: LinkType) => void;
  onLinkOpen?: (link: LinkType) => void;
}

const LinkList: React.FC<Props> = ({ links, onLinkClick, onLinkOpen }) => {
  return (
    <List dense>
      {links !== null && links.length > 0 ? (
        links.map(link => (
          <LinkListItem
            linkItem={link}
            onItemOpen={onLinkOpen}
            onItemClick={onLinkClick}
            key={link.id}
          />
        ))
      ) : (
        <Typography>Kein Links</Typography>
      )}
    </List>
  )
}

export default LinkList